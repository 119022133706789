import { useQuery } from "@tanstack/react-query";

import { getThemes } from "@/api";

export const themesQueryKeys = {
  useThemes: () => ["themes", "getThemes"],
};

export const useThemes = () => {
  const useAllThemes = () =>
    useQuery({
      queryFn: () => getThemes(),
      queryKey: themesQueryKeys.useThemes(),
    });

  return {
    useAllThemes,
  };
};
