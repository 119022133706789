import { Lock } from "@phosphor-icons/react";
import { t } from "i18next";
import { useLocation } from "react-router-dom";

import { AddBatchForm } from "@/components";
import type { ModalProps } from "@/shared.types";
import { Circle, Heading, Modal } from "@/ui";

interface LocationState {
  partnerId?: string;
}

export const AddBatchModal = ({ show, onClose }: ModalProps) => {
  const location = useLocation();
  const state = location.state as LocationState;
  const partnerId = state?.partnerId;

  return (
    <Modal
      show={show}
      title={
        <div className="flex flex-col gap-y-2">
          <Heading
            containerClassName="items-center"
            heading={t("batches_and_users.add_new_batch")}
            left={
              <Circle>
                <Lock size={24} className="text-tertiary-800" />
              </Circle>
            }
          />
          <span className="text-base text-neutral-700">
            *{t("error.required_fields")}
          </span>
        </div>
      }
      onClose={onClose}
    >
      <AddBatchForm partnerId={partnerId} onClose={onClose} />
    </Modal>
  );
};
