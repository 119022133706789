import type { CourseSession } from "@/api";
import { Heading, Loader } from "@/ui";
import { EditModule } from "./EditModule";

interface PreCourseSessionProps {
  partnerId: string;
  courseSession: CourseSession;
}

export const PreCourseSession = ({ courseSession }: PreCourseSessionProps) => {
  if (!courseSession) {
    return <Loader />;
  }

  return (
    <>
      <Heading heading={courseSession.name} />
      {courseSession.modules.map((module) => (
        <EditModule key={module.id} module={module} />
      ))}
    </>
  );
};
