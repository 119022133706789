import { t } from "i18next";

import { PasswordForm } from "@/components";
import { CenteredContainerLayout } from "@/layout";

export const PasswordReset = () => {
  return (
    <div>
      <CenteredContainerLayout className="md:w-[683px]">
        <PasswordForm heading={t("auth.reset_password")} />
      </CenteredContainerLayout>
    </div>
  );
};
