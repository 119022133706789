import { getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { MODAL_ROUTES } from "@/router";
import { useNavigateModal } from "@/router/useNavigateModal";
import { useUserStore } from "@/stores";
import type { Collaborator } from "@/types";
import { Table } from "@/ui";
import { getIsDefaultCollaborator } from "@/utils";
import { getTableColumns } from "./CollaboratorsTableColumns";

interface CollaboratorsTableProps {
  collaborators: Collaborator[];
  partnerId: string;
  courseId: string;
}

export const CollaboratorsTable = ({
  collaborators,
  partnerId,
  courseId,
}: CollaboratorsTableProps) => {
  const navigateModal = useNavigateModal();
  const { user } = useUserStore();

  const table = useReactTable({
    data: collaborators ?? [],
    columns: getTableColumns(navigateModal, partnerId, courseId),
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: 1,
  });

  const handleRowClick = (row: Collaborator) => {
    const isDefaultCollaborator = getIsDefaultCollaborator(row.name);

    if (!isDefaultCollaborator || user?.role == "admin") {
      navigateModal(MODAL_ROUTES.editCollaborator, {
        collaborator: row,
        partnerId,
      });
    }
  };

  return (
    <div>
      <Table
        table={table}
        handleRowClick={handleRowClick}
        className="rounded-2xl"
      />
    </div>
  );
};
