import type { CommonTypes } from "@/types";
import { tw } from "@/utils";

export const Circle = ({ children, className }: CommonTypes) => {
  return (
    <div className={tw("size-9 rounded-full bg-tertiary-200 p-1.5", className)}>
      {children}
    </div>
  );
};
