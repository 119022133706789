import { SmileySad } from "@phosphor-icons/react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { LeftContainerLayout } from "@/layout";
import { ROUTES } from "@/router";
import { Button, Circle } from "@/ui";

export const ServerError = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES.home);
  };

  return (
    <LeftContainerLayout className="md:w-[672px]">
      <div className="flex w-[672px] flex-col justify-between gap-y-12 px-6 py-14 md:px-24">
        <div className="flex flex-col justify-center gap-y-6">
          <div className="flex flex-col gap-y-2">
            <Circle>
              <SmileySad size={24} className="text-tertiary-800" />
            </Circle>
            <h1 className="font-display text-4xl font-bold text-primary-950">
              {t("general.cant_find")}
            </h1>
          </div>
          <p className="text-xl font-bold text-primary-800">
            {t("general.sorry_try_again_later")}
          </p>
          <p className="text-xl font-bold text-primary-800">
            {t("general.if_problem_persists_contact_support")}
          </p>
        </div>
        <Button
          size="md"
          variant="filled"
          onClick={handleClick}
          className="md:w-44"
        >
          {t("general.go_to_home")}
        </Button>
      </div>
    </LeftContainerLayout>
  );
};
