import { zodResolver } from "@hookform/resolvers/zod";
import { Calendar, Spinner, User } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import { batchesQueryKeys, useBatches } from "@/hooks";
import type { Batch, BatchFormValues } from "@/types";
import { batchSchema } from "@/types";
import { Button, errorToast, Input } from "@/ui";
import { formatDateForInput } from "@/utils/dates";

interface EditBatchFormProps {
  onClose: () => void;
  batch?: Batch;
  partnerId?: string;
}

export const EditBatchForm = ({
  onClose,
  batch,
  partnerId,
}: EditBatchFormProps) => {
  const { useUpdateBatch } = useBatches();
  const { mutateAsync: updateBatch, isPending: isPendingUpdateBatchMutation } =
    useUpdateBatch();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<BatchFormValues>({
    resolver: zodResolver(batchSchema),
    mode: "all",
    defaultValues: {
      partner_id: partnerId,
      capacity: batch?.capacity,
      from_date: batch?.from_date ? formatDateForInput(batch.from_date) : "",
      to_date: batch?.to_date ? formatDateForInput(batch.to_date) : "",
    },
  });

  const queryClient = useQueryClient();

  const invalidateAndClose = () => {
    void queryClient.invalidateQueries({
      queryKey: batchesQueryKeys.useBatch(batch?.id),
      exact: false,
    });
    onClose();
  };

  const onSubmit: SubmitHandler<BatchFormValues> = async (batchData) => {
    try {
      if (batch?.id) {
        await updateBatch({
          batchId: batch.id,
          batch: batchData,
        });
        invalidateAndClose();
      }
    } catch (error) {
      errorToast(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-[540px] flex-col gap-y-8"
    >
      <div className="flex flex-col gap-y-8">
        <Input
          id="capacity"
          label={t("batches_and_users.users_number")}
          left={<User size={20} />}
          type="number"
          {...register("capacity", { valueAsNumber: true })}
          error={errors.capacity?.message}
          required
          min="0"
        />

        <Input
          id="to_date"
          label={t("batches_and_users.effective_until")}
          type="date"
          left={<Calendar size={20} />}
          {...register("to_date")}
          error={errors.to_date?.message}
          required
        />
      </div>

      <div className="flex justify-center gap-2">
        <Button
          onClick={() => onClose()}
          variant="outlined"
          className="flex-grow"
        >
          {t("general.cancel")}
        </Button>

        <Button type="submit" className="flex-grow" disabled={!isValid}>
          {isPendingUpdateBatchMutation ? (
            <Spinner className="h-5 w-5" />
          ) : (
            t("general.save_changes")
          )}
        </Button>
      </div>
    </form>
  );
};
