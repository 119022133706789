import React, { useState } from "react";
import { CaretDown, CaretUp, Info } from "@phosphor-icons/react";
import { t } from "i18next";

import { Button, Label } from "@/ui";
import { tw } from "@/utils";

interface FormCardProps {
  title: string;
  label: string;
  children: React.ReactNode;
  onSubmit: () => void;
  onClear?: () => void;
  submitButtonText: string;
  containerClassName?: string;
  isDirty?: boolean;
}

export const FormCard = ({
  title,
  label,
  children,
  onSubmit,
  onClear,
  submitButtonText,
  containerClassName,
  isDirty = true,
}: FormCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className={tw("rounded-2xl bg-tertiary-100 p-6", containerClassName)}>
      <div className="mb-6 flex items-center justify-between">
        <div>
          <Label
            className="text-xl font-semibold text-primary-950"
            label={title}
          />
          <div className="flex items-center space-x-1">
            <Info className="text-neutral-800" />
            <Label
              className="text-sm font-semibold text-neutral-900"
              label={label}
            />
          </div>
        </div>
        <div className="flex items-center space-x-2">
          {onClear && (
            <Button onClick={onClear} size="sm" variant="filled">
              {t("general.clear")}
            </Button>
          )}

          <Button
            onClick={onSubmit}
            size="sm"
            variant="outlined"
            disabled={!isDirty}
          >
            {submitButtonText}
          </Button>
          <Button size="sm" variant="transparent" onClick={toggleExpand}>
            {isExpanded ? (
              <CaretUp className="h-4 w-4" />
            ) : (
              <CaretDown className="h-4 w-4" />
            )}
          </Button>
        </div>
      </div>
      <div
        className={tw(
          "grid transition-all duration-300 ease-in-out",
          isExpanded ? "grid-rows-[1fr]" : "grid-rows-[0fr]",
        )}
      >
        <div className="overflow-hidden">{children}</div>
      </div>
    </div>
  );
};
