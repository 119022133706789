import { SelfPacedCourse as SelfPacedCourseComponent } from "@/components";
import { usePartners } from "@/hooks";
import { useUserStore } from "@/stores";

export const SelfPacedCourse = () => {
  const { user } = useUserStore();
  const { useManagedPartner, useSelfPacedCourse } = usePartners();
  const { data: partner, isLoading: isLoadingPartner } = useManagedPartner(
    user?.id,
  );
  const { data: course, isLoading: isLoadingCourse } = useSelfPacedCourse(
    partner?.id,
  );

  return (
    <SelfPacedCourseComponent
      partner={partner}
      course={course}
      isLoading={isLoadingPartner || isLoadingCourse}
    />
  );
};
