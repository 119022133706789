import { Binoculars } from "@phosphor-icons/react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { LeftContainerLayout } from "@/layout";
import { ROUTES } from "@/router";
import { Button, Circle } from "@/ui";

export const NotFound = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES.home);
  };

  return (
    <LeftContainerLayout className="md:w-[672px]">
      <div className="flex w-[672px] flex-col justify-between gap-y-8 px-6 py-14 md:px-24">
        <div className="flex flex-col justify-center gap-y-6">
          <div className="flex flex-col gap-y-2">
            <Circle>
              <Binoculars size={24} className="text-tertiary-800" />
            </Circle>
            <h1 className="font-display text-4xl font-bold text-primary-950">
              {t("general.cant_find")}
            </h1>
          </div>
          <p className="text-xl font-bold text-primary-800">
            {t("general.outdated_link")}
          </p>
          <div className="flex flex-col gap-y-2 rounded-lg bg-tertiary-100 p-4 text-primary-950">
            <span className="font-bold">{t("general.back_on_track")}:</span>
            <ul className="font-regular list-outside list-disc space-y-2.5 pl-4 leading-none text-primary-950">
              <li>{t("general.back_to_previous_page")}</li>
              <li>{t("general.visit_home_screen")}</li>
            </ul>
          </div>
        </div>
        <Button
          size="md"
          variant="filled"
          onClick={handleClick}
          className="md:w-44"
        >
          {t("general.go_to_home")}
        </Button>
      </div>
    </LeftContainerLayout>
  );
};
