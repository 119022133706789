import { zodResolver } from "@hookform/resolvers/zod";
import { Calendar, Spinner, User } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import { partnersQueryKeys, useBatches } from "@/hooks";
import type { BatchFormValues } from "@/types";
import { batchSchema } from "@/types";
import { Button, errorToast, Input } from "@/ui";

interface AddBatchFormProps {
  onClose: () => void;
  partnerId?: string;
}

export const AddBatchForm = ({ onClose, partnerId }: AddBatchFormProps) => {
  const { useAddBatch } = useBatches();
  const { mutateAsync: addBatch, isPending: isPendingAddBatchMutation } =
    useAddBatch();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<BatchFormValues>({
    resolver: zodResolver(batchSchema),
    mode: "all",
    defaultValues: {
      capacity: 0,
      partner_id: partnerId,
    },
  });

  const queryClient = useQueryClient();

  const invalidateAndClose = () => {
    void queryClient.invalidateQueries({
      queryKey: partnersQueryKeys.useBatchesPage(1),
    });
    onClose();
  };

  const onSubmit: SubmitHandler<BatchFormValues> = async (batch) => {
    try {
      await addBatch(batch);
      invalidateAndClose();
    } catch (error) {
      errorToast(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-[540px] flex-col gap-y-8"
    >
      <div className="flex flex-col gap-y-8">
        <Input id="partner_id" type="hidden" {...register("partner_id")} />

        <Input
          id="capacity"
          label={t("batches_and_users.users_number")}
          left={<User size={20} />}
          type="number"
          {...register("capacity", { valueAsNumber: true })}
          error={errors.capacity?.message}
          required
        />

        <Input
          id="from_date"
          label={t("batches_and_users.effective_from")}
          type="date"
          left={<Calendar size={20} />}
          {...register("from_date")}
          error={errors.from_date?.message}
          required
        />

        <Input
          id="to_date"
          label={t("batches_and_users.effective_until")}
          type="date"
          left={<Calendar size={20} />}
          {...register("to_date")}
          error={errors.to_date?.message}
          required
        />
      </div>

      <div className="flex justify-center gap-2">
        <Button
          onClick={() => onClose()}
          variant="outlined"
          className="flex-grow"
        >
          {t("general.cancel")}
        </Button>

        <Button type="submit" className="flex-grow" disabled={!isValid}>
          {isPendingAddBatchMutation ? (
            <Spinner className="h-5 w-5" />
          ) : (
            t("batches_and_users.generate")
          )}
        </Button>
      </div>
    </form>
  );
};
