import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useNavigate, useParams } from "react-router-dom";

import { usePartners } from "@/hooks";
import { usePagination } from "@/hooks/usePagination";
import type { Batch } from "@/types";
import { Pagination, Table } from "@/ui";
import { batchesTableColumns } from "./BatchesTableColumns";

export const BatchesTable = () => {
  const navigate = useNavigate();
  const { partnerId } = useParams();
  const { pagination, setPageIndex, onPaginationChange } = usePagination();
  const { useAllBatches } = usePartners();
  const { data: batchesData, isLoading } = useAllBatches(
    partnerId,
    pagination.pageIndex + 1,
  );

  const table = useReactTable<Batch>({
    data: batchesData?.data ?? [],
    columns: batchesTableColumns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: batchesData?.pagination?.totalPages ?? 1,
    state: {
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: batchesData?.pagination?.perPage ?? 10,
      },
    },
    onPaginationChange,
  });

  const handleRowClick = (row: Batch, index: number) => {
    const pageIndex = pagination.pageIndex;
    const pageSize = pagination.pageSize;
    const batchNumber = pageIndex * pageSize + index + 1;
    navigate(
      `/partners/${partnerId}/batches/${row.id}?batchNumber=${batchNumber}`,
    );
  };

  return (
    <div className="pb-6">
      <Table
        table={table}
        handleRowClick={handleRowClick}
        isLoading={isLoading}
      />
      <div className="flex justify-end rounded-b-2xl border border-neutral-400">
        <Pagination
          currentPage={pagination.pageIndex + 1}
          totalPages={batchesData?.pagination?.totalPages ?? 1}
          onPageChange={(page) => setPageIndex(page - 1)}
        />
      </div>
    </div>
  );
};
