import { Handshake } from "@phosphor-icons/react";
import { t } from "i18next";

import { AddPartnerForm } from "@/components";
import type { ModalProps } from "@/shared.types";
import { Circle, Heading, Modal } from "@/ui";

export const AddPartnerModal = ({ show, onClose }: ModalProps) => {
  return (
    <Modal
      show={show}
      title={
        <div className="flex flex-col gap-y-2">
          <Heading
            containerClassName="items-center"
            heading={t("partner.add_new_partner")}
            left={
              <Circle>
                <Handshake size={24} className="text-tertiary-800" />
              </Circle>
            }
          />
          <span className="text-base text-neutral-700">
            *{t("error.required_fields")}
          </span>
        </div>
      }
      onClose={onClose}
    >
      <AddPartnerForm onClose={onClose} />
    </Modal>
  );
};
