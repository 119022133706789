import type { CommonTypes } from "@/types";
import { tw } from "@/utils";

interface MeditationSectionFormProps extends CommonTypes {
  topLeft?: React.ReactNode;
  topRight?: React.ReactNode;
}

export const MeditationFormSection = ({
  topLeft,
  topRight,
  children,
  className,
}: MeditationSectionFormProps) => {
  return (
    <div
      className={tw(
        "flex flex-col gap-y-8 rounded-2xl bg-tertiary-100 p-6",
        className,
      )}
    >
      {(topLeft ?? topRight) && (
        <div className="flex w-full justify-between gap-y-4">
          {topLeft}
          {topRight}
        </div>
      )}
      {children}
    </div>
  );
};
