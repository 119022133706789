import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { addPracticeType, getPracticeTypes } from "@/api";

export const practiceTypesQueryKeys = {
  usePracticeTypes: () => ["practiceTypes", "getPracticeTypes"],
};

export const usePracticeTypes = () => {
  const queryClient = useQueryClient();
  const useAllPracticeTypes = () =>
    useQuery({
      queryFn: () => getPracticeTypes(),
      queryKey: practiceTypesQueryKeys.usePracticeTypes(),
    });

  const useAddPracticeType = () =>
    useMutation({
      mutationFn: addPracticeType,
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: [practiceTypesQueryKeys.usePracticeTypes()],
        });
      },
    });

  return {
    useAllPracticeTypes,
    useAddPracticeType,
  };
};
