import type { ForwardedRef, ReactNode } from "react";
import { Check } from "@phosphor-icons/react";

import { forwardRef, tw } from "@/utils";

interface CheckboxProps {
  id: string;
  label: string | ReactNode;
  value: string | number;
  checked: boolean;
  onChange: () => void;
  error?: string;
  className?: string;
}

interface CheckboxProps {
  id: string;
  label: string | ReactNode;
  value: string | number;
  checked: boolean;
  onChange: () => void;
  error?: string;
  className?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { id, label, value, checked, onChange, className, ...rest },
    ref: ForwardedRef<HTMLInputElement>,
  ) => (
    <div className="py-2">
      <label
        htmlFor={id}
        className={tw(
          "py-y flex cursor-pointer items-start gap-x-3 px-2",
          checked && "bg-blue-200",
        )}
      >
        <input
          ref={ref}
          type="checkbox"
          id={id}
          value={value.toString()}
          checked={checked}
          onChange={onChange}
          className="absolute h-0 w-0 opacity-0"
          {...rest}
        />
        <div className={tw("flex pt-1", className)}>
          <span
            className={tw(
              "h-4 w-4 rounded border border-primary-800 text-white",
              checked ? "bg-primary-800" : "bg-white",
            )}
          >
            <Check size={14} weight="bold" />
          </span>
        </div>

        <div className="w-[436px]">{label}</div>
      </label>
    </div>
  ),
);
