import { Lock } from "@phosphor-icons/react";
import { t } from "i18next";
import { useLocation } from "react-router-dom";

import { EditBatchForm } from "@/components";
import type { ModalProps } from "@/shared.types";
import type { Batch } from "@/types";
import { Circle, Heading, Modal } from "@/ui";

interface LocationState {
  partnerId?: string;
  batch?: Batch;
  batchNumber?: number;
}

export const EditBatchModal = ({ show, onClose }: ModalProps) => {
  const location = useLocation();
  const state = location.state as LocationState;
  const partnerId = state?.partnerId;
  const batch = state?.batch;
  const batchNumber = state?.batchNumber;

  return (
    <Modal
      show={show}
      title={
        <div className="flex flex-col gap-y-2">
          <Heading
            containerClassName="items-center"
            heading={`${t("batches_and_users.edit_batch")} ${batchNumber}`}
            left={
              <Circle>
                <Lock size={24} className="text-tertiary-800" />
              </Circle>
            }
          />
          <span className="text-base text-neutral-700">
            *{t("error.required_fields")}
          </span>
        </div>
      }
      onClose={onClose}
    >
      <EditBatchForm partnerId={partnerId} batch={batch} onClose={onClose} />
    </Modal>
  );
};
