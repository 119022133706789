import React from "react";
import { t } from "i18next";

import { Button, Label } from "@/ui";
import { tw } from "@/utils";

interface VideoFormCardProps {
  title: string;
  children: React.ReactNode;
  onClear: () => void;
  onSubmit: () => void;
  submitButtonText: string;
  containerClassName?: string;
  isDirty?: boolean;
  disableSubmit?: boolean;
}

export const VideoFormCard = ({
  title,
  children,
  onSubmit,
  submitButtonText,
  containerClassName,
  onClear,
  isDirty = true,
  disableSubmit = false,
}: VideoFormCardProps) => {
  return (
    <div
      className={tw(
        "rounded-2xl border border-neutral-200 p-6",
        containerClassName,
      )}
    >
      <div className="mb-6 flex items-center justify-between">
        <div>
          <Label
            className="text-xl font-semibold text-primary-950"
            label={title}
          />
        </div>
        <div className="flex items-center space-x-2">
          <Button
            onClick={onClear}
            size="sm"
            variant="filled"
            disabled={disableSubmit}
          >
            {t("general.clear")}
          </Button>
          <Button
            onClick={onSubmit}
            size="sm"
            variant="outlined"
            disabled={!isDirty || disableSubmit}
          >
            {submitButtonText}
          </Button>
        </div>
      </div>
      <div className={tw("grid transition-all duration-300 ease-in-out")}>
        <div className="overflow-hidden">{children}</div>
      </div>
    </div>
  );
};
