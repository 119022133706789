import { useMutation } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/router";
import { useUserStore } from "@/stores";
import type { User } from "@/types/models";
import type { ErrorResponse, ServiceResponse } from "./api.types";
import { privateAPI, publicAPI } from "./axios";

interface LoginResponse {
  user: User;
  accessToken: string;
}

interface LoginRequest {
  email: string;
  password: string;
}

export function useLogin() {
  const navigate = useNavigate();
  const setToken = useUserStore((state) => state.setToken);
  const setUser = useUserStore((state) => state.setUser);

  return useMutation<LoginResponse, ErrorResponse, LoginRequest>({
    mutationFn: async (loginData: LoginRequest): Promise<LoginResponse> => {
      const response = await publicAPI.post<ServiceResponse<LoginResponse>>(
        "/auth/login",
        loginData,
      );
      return response.data.data;
    },
    onSuccess: (data) => {
      setToken(data.accessToken);
      setUser(data.user);
      navigate(ROUTES.home);
    },
  });
}

export function useLogout() {
  const navigate = useNavigate();
  const clearUser = useUserStore((state) => state.clearUser);

  return useMutation<void, AxiosError>({
    mutationFn: async (): Promise<void> => {
      await privateAPI.post("/auth/logout");
    },
    onSettled: () => {
      clearUser();
      navigate(ROUTES.login);
    },
  });
}
