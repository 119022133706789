import { LockSimple, LockSimpleOpen, Spinner } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useLocation } from "react-router-dom";

import { usersQueryKeys, useUsers } from "@/hooks";
import i18n from "@/i18n";
import type { ModalProps } from "@/shared.types";
import type { User } from "@/types";
import { Button, Circle, errorToast, Heading, Modal } from "@/ui";

interface LocationState {
  user: User;
}

export const LockUserModal = ({ show, onClose }: ModalProps) => {
  const location = useLocation();
  const state = location.state as LocationState;
  const queryClient = useQueryClient();
  const { usePatchUser } = useUsers();
  const { mutate: patchUser, isPending } = usePatchUser();
  const user = state.user;
  const isUserLocked = state.user.locked;
  const lockLabel = `${isUserLocked ? t("users.unlock_user") : t("users.lock_user")}`;

  if (!state?.user) {
    errorToast(i18n.t("error.general"));
  }

  const handleLockButton = () => {
    const userId = user.id;

    patchUser(
      { userId, locked: !isUserLocked },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: usersQueryKeys.useUsers(),
            exact: false,
          });
          onClose();
        },
        onError: (err) => {
          errorToast(err);
        },
      },
    );
  };

  return (
    <Modal
      show={show}
      title={
        <div className="flex w-[492px] flex-col gap-y-7">
          <div className="flex flex-col gap-y-2">
            <Heading
              containerClassName="items-center"
              heading={lockLabel}
              left={
                <Circle>
                  {isUserLocked ? (
                    <LockSimpleOpen size={24} className="text-tertiary-800" />
                  ) : (
                    <LockSimple size={24} className="text-tertiary-800" />
                  )}
                </Circle>
              }
            />
            <p className="text-base text-neutral-700">
              {`${t("users.are_you_sure_you_want_to")}
              ${isUserLocked ? t("users.unlock") : t("users.lock")}
              ${user.email}`}
            </p>
          </div>
        </div>
      }
      onClose={onClose}
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-8"></div>

        <div className="flex flex-row justify-center gap-x-2">
          <Button
            onClick={() => onClose()}
            className="flex-grow"
            variant="outlined"
          >
            {t("general.cancel")}
          </Button>
          <Button
            onClick={() => handleLockButton()}
            className="flex-grow"
            variant="filled"
          >
            {isPending ? <Spinner className="h-5 w-5" /> : lockLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
