import { RESOURCE_TYPES } from "@/types";
import type { AdditionalResource, ResourceType } from "@/types";
import { capitalizeFirstLetter } from "./string";

export function getHeaderInformation(
  additionalResources: AdditionalResource[],
): AdditionalResource | undefined {
  return additionalResources.find(
    (resource) => resource.type === RESOURCE_TYPES.HEADER_INFORMATION,
  );
}

export function getAboutPartner(
  additionalResources: AdditionalResource[],
): AdditionalResource | undefined {
  return additionalResources.find(
    (resource) => resource.type === RESOURCE_TYPES.ABOUT,
  );
}

export function getNonAdditionalInformation(
  additionalResources: AdditionalResource[],
): AdditionalResource[] {
  const allowedTypes: (ResourceType | null)[] = [
    RESOURCE_TYPES.ABOUT,
    RESOURCE_TYPES.HEADER_INFORMATION,
  ];
  return additionalResources.filter((resource) =>
    allowedTypes.includes(resource.type),
  );
}

export function getAdditionalInformation(
  additionalResources: AdditionalResource[],
): AdditionalResource[] {
  const allowedTypes: (ResourceType | null)[] = [
    null,
    RESOURCE_TYPES.CONNECT,
    RESOURCE_TYPES.RESEARCH,
  ];

  const filteredResources = additionalResources.filter((resource) =>
    allowedTypes.includes(resource.type),
  );

  return convertResourceTypeIntoNull(filteredResources);
}

export function convertResourceTypeIntoNull(
  additionalResources: AdditionalResource[],
): AdditionalResource[] {
  return additionalResources.map((resource) => {
    return {
      ...resource,
      type: !resource.type ? RESOURCE_TYPES.NO_TAG : resource.type,
    };
  });
}

export function convertResourceTypeFromNull(
  additionalResources: AdditionalResource[],
): AdditionalResource[] {
  return additionalResources.map((resource) => {
    return {
      ...resource,
      type: resource.type === RESOURCE_TYPES.NO_TAG ? null : resource.type,
    };
  });
}

export function getAdditionalInformationTagOptions(): {
  value: ResourceType;
  label: string;
}[] {
  const allowedTypes: ResourceType[] = [
    RESOURCE_TYPES.NO_TAG,
    RESOURCE_TYPES.CONNECT,
    RESOURCE_TYPES.RESEARCH,
  ];

  return allowedTypes.map((type) => ({
    value: type,
    label: capitalizeFirstLetter(type),
  }));
}

export const COURSE_TYPE = {
  COURSE: "course",
  TRAINING: "training",
} as const;

export function mapCourseTypeLabel(type: string): string {
  if (type === COURSE_TYPE.COURSE) {
    return "Personal training";
  } else if (type === COURSE_TYPE.TRAINING) {
    return "Teacher training";
  } else {
    return "Teacher training";
  }
}
