import LoginForm from "@/components/LoginForm";
import { LeftContainerLayout } from "@/layout";

export const Login = () => {
  return (
    <LeftContainerLayout>
      <LoginForm />
    </LeftContainerLayout>
  );
};
