import { useMutation, useQuery } from "@tanstack/react-query";

import {
  addCollaboratorToCourse,
  getCourse,
  getCourseModules,
  publishCourse,
  removeCollaboratorFromCourse,
  updateCourse,
} from "@/api";
import type { Course } from "@/types";

export const coursesQueryKeys = {
  useCourse: (courseId?: Course["id"]) => ["courses", "getCourse", courseId],
  useCourseModules: (courseId?: Course["id"]) => [
    "courses",
    "getCourseModules",
    courseId,
  ],
};

export const useCourses = () => {
  const useCourse = (courseId?: Course["id"]) =>
    useQuery({
      queryFn: () => getCourse(courseId),
      queryKey: coursesQueryKeys.useCourse(courseId),
      enabled: !!courseId,
    });
  const useUpdateCourse = () => useMutation({ mutationFn: updateCourse });
  const useAddCollaboratorToCourse = () =>
    useMutation({ mutationFn: addCollaboratorToCourse });
  const useRemoveCollaboratorFromCourse = () =>
    useMutation({ mutationFn: removeCollaboratorFromCourse });

  const useCourseModules = (courseId?: Course["id"]) =>
    useQuery({
      queryFn: () => getCourseModules(courseId),
      queryKey: coursesQueryKeys.useCourseModules(courseId),
      enabled: !!courseId,
    });

  const usePublishCourse = () => useMutation({ mutationFn: publishCourse });

  return {
    useCourse,
    useUpdateCourse,
    useAddCollaboratorToCourse,
    useRemoveCollaboratorFromCourse,
    useCourseModules,
    usePublishCourse,
  };
};
