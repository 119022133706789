import { zodResolver } from "@hookform/resolvers/zod";
import { Spinner, User } from "@phosphor-icons/react";
import { t } from "i18next";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import { usePracticeTypes } from "@/hooks";
import { practiceTypeSchema } from "@/types";
import type { AddEditPracticeType } from "@/types";
import { Button, errorToast, Input } from "@/ui";

interface AddPracticeTypeFormProps {
  onClose: () => void;
}

export const AddPracticeTypeForm = ({ onClose }: AddPracticeTypeFormProps) => {
  const { useAddPracticeType } = usePracticeTypes();
  const {
    mutateAsync: addPracticeType,
    isPending: isPendingAddPracticeTypeMutation,
  } = useAddPracticeType();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<AddEditPracticeType>({
    resolver: zodResolver(practiceTypeSchema),
    mode: "all",
  });

  const onSubmit: SubmitHandler<AddEditPracticeType> = async (practiceType) => {
    try {
      await addPracticeType(practiceType);

      onClose();
    } catch (error) {
      errorToast(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-[540px] flex-col gap-y-8"
    >
      <div className="flex flex-col gap-y-8">
        <Input
          id="name"
          label={t("meditations.practice_type")}
          placeholder={t("meditations.practice_type_name")}
          left={<User size={20} />}
          {...register("name")}
          error={errors.name?.message}
          required
        />
      </div>
      <div className="flex justify-center gap-2">
        <Button
          onClick={() => onClose()}
          variant="outlined"
          className="flex-grow"
        >
          {t("general.cancel")}
        </Button>

        <Button type="submit" className="flex-grow" disabled={!isValid}>
          {isPendingAddPracticeTypeMutation ? (
            <Spinner className="h-5 w-5" />
          ) : (
            t("general.save")
          )}
        </Button>
      </div>
    </form>
  );
};
