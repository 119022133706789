import { useState } from "react";
import { BookBookmark, PencilLine, UsersThree } from "@phosphor-icons/react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import {
  AccessRequests,
  ApprovedUsers,
  Materials,
} from "@/components/Materials";
import { useBundles } from "@/hooks/useBundles";
import { ContentLayout } from "@/layout";
import { ROUTES } from "@/router";
import { useUserStore } from "@/stores";
import type { Bundle } from "@/types";
import type { Tab } from "@/ui";
import { Breadcrumb, Heading, MultiSelect, SectionHeader, Tabs } from "@/ui";
import { convertToOptions } from "@/utils";

export const CoursesAndMaterials = () => {
  const navigate = useNavigate();
  const { user } = useUserStore();
  const [selectedBundles, setSelectedBundles] = useState<string[]>([]);

  const { useAllBundles } = useBundles();
  const { data: bundlesData } = useAllBundles();
  const bundlesOptions = convertToOptions<Bundle>(bundlesData?.data);

  const tabs: [Tab, ...Tab[]] = [
    {
      component: <ApprovedUsers selectedBundles={selectedBundles} />,
      label: t("materials.approved_users"),
      roles: ["admin"],
      icon: <UsersThree />,
    },
    {
      component: <AccessRequests selectedBundles={selectedBundles} />,
      label: t("materials.access_requests"),
      roles: ["admin", "partner"],
      icon: <PencilLine />,
    },
    {
      component: <Materials />,
      label: t("materials.materials"),
      roles: ["admin", "partner"],
      icon: <PencilLine />,
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const breadcrumbItems = [
    {
      label: t("general.courses_and_materials"),
      href: ROUTES.coursesAndMaterials,
      icon: <BookBookmark />,
    },
  ];

  return (
    <ContentLayout className="flex flex-col gap-y-6">
      <Breadcrumb
        items={breadcrumbItems}
        current={t("general.courses_and_materials")}
        onItemClick={(item) => {
          if (item.href) {
            navigate(item.href);
          }
        }}
      />
      <SectionHeader
        left={
          <Heading
            level={2}
            heading={t("general.courses_and_materials")}
            className="text-3xl"
          />
        }
        right={
          <MultiSelect
            placeholder={t("materials.select_course")}
            options={bundlesOptions}
            selectedOptions={selectedBundles}
            onChange={(newSelectedBundles) => {
              setSelectedBundles(newSelectedBundles);
            }}
          />
        }
      />
      <Tabs
        tabs={tabs}
        userRole={user?.role ?? "loggedOut"}
        currentLabel={currentTab?.label ?? ""}
        setCurrentTab={setCurrentTab}
      />
    </ContentLayout>
  );
};
