import { Headphones } from "@phosphor-icons/react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";

import { MeditationForm } from "@/components/Meditations";
import { useMeditations } from "@/hooks";
import { ContentLayout } from "@/layout";
import { ROUTES } from "@/router";
import { Breadcrumb, Loader } from "@/ui";

export const EditMeditation = () => {
  const navigate = useNavigate();
  const { meditationId } = useParams();
  const { useMeditation } = useMeditations();
  const { data: meditation, isLoading } = useMeditation(meditationId);

  const breadcrumbItems = [
    {
      label: t("general.meditations"),
      href: ROUTES.meditations,
      icon: <Headphones />,
    },
    {
      label: t("meditations.edit_meditation"),
      href: ROUTES.editMeditation.replace(":meditationId", meditationId ?? ""),
    },
  ];

  return (
    <ContentLayout className="flex flex-col gap-y-6">
      <Breadcrumb
        items={breadcrumbItems}
        current={t("meditations.edit_meditation")}
        onItemClick={(item) => {
          if (item.href) {
            navigate(item.href);
          }
        }}
      />
      {isLoading ? (
        <Loader />
      ) : (
        meditation && <MeditationForm initialData={meditation} />
      )}
    </ContentLayout>
  );
};
