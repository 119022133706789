import type { ReactNode } from "react";
import React from "react";

import { tw } from "@/utils";

interface BreadcrumbItem {
  label: string;
  href?: string;
  icon?: ReactNode;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
  current: string;
  onItemClick: (item: BreadcrumbItem) => void;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  items,
  current,
  onItemClick,
}) => {
  return (
    <nav>
      <ol className="flex text-sm font-semibold text-neutral-600">
        {items.map((item, index) => (
          <li
            key={item.label}
            className={tw(
              "flex items-center",
              item.label === current && "font-bold text-primary-700",
            )}
          >
            {item.icon && <span className="pr-0.5">{item.icon}</span>}
            {item.href ? (
              <a
                href={item.href}
                onClick={(e) => {
                  e.preventDefault();
                  onItemClick(item);
                }}
              >
                {item.label}
              </a>
            ) : (
              <span>{item.label}</span>
            )}
            {index < items.length - 1 && <span className="px-2">{">"}</span>}
          </li>
        ))}
      </ol>
    </nav>
  );
};
