import { PencilSimple } from "@phosphor-icons/react";
import { t } from "i18next";

import { MODAL_ROUTES } from "@/router";
import { useNavigateModal } from "@/router/useNavigateModal";
import type { Partner } from "@/types";
import { Button, Heading, SectionHeader } from "@/ui";
import { tw } from "@/utils";

export const PartnerInfo = ({ partner }: { partner: Partner | undefined }) => {
  const navigateModal = useNavigateModal();

  const partnerFields = [
    { label: t("partner.point_of_contact"), value: partner?.contact },
    { label: t("general.country"), value: partner?.country },
    { label: t("general.description"), value: partner?.description },
  ];

  return (
    <div className="flex flex-col gap-y-4 rounded-2xl bg-tertiary-100 p-4">
      <SectionHeader
        left={<Heading level={3} heading={t("partner.general_info")} />}
        right={
          <Button
            size="md"
            onClick={() => navigateModal(MODAL_ROUTES.editPartner, { partner })}
          >
            <PencilSimple />
            {t("partner.edit_general_info")}
          </Button>
        }
      />
      <div className="grid grid-cols-2 gap-5">
        {partnerFields.map((field, index) => (
          <div
            key={index}
            className={tw(
              "flex flex-col items-start justify-center gap-y-2 rounded-2xl bg-tertiary-50 p-4 text-neutral-900",
              index === partnerFields.length - 1 && "col-span-2",
            )}
          >
            <span className="font-normal text-neutral-700">{field.label}</span>
            <span className="text-lg font-bold text-primary-900">
              {field.value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
