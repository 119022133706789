import { MagnifyingGlass } from "@phosphor-icons/react";
import { t } from "i18next";
import { useForm } from "react-hook-form";

import { useSearch } from "@/hooks";
import { ContentLayout } from "@/layout";
import { tw } from "@/utils";
import { MeditationsTable } from "./MeditationsTable";

export const Meditations = () => {
  const { searchQuery, handleSearchChange } = useSearch("");
  const { watch } = useForm<{
    radioOption: string[];
  }>({
    defaultValues: {
      radioOption: ["paid", "pending payment"],
    },
  });
  const selectedRadioValue = watch("radioOption");

  return (
    <ContentLayout className="flex flex-col gap-y-6">
      <div className="flex items-center justify-between gap-x-2">
        <div className="relative flex items-center justify-between">
          <div className="flex flex-row items-center">
            <div className="pointer-events-none absolute pl-3">
              <MagnifyingGlass
                size={20}
                className={tw(
                  "text-neutral-950",
                  !searchQuery && "text-neutral-500",
                )}
              />
            </div>

            <input
              type="text"
              id="search"
              placeholder={t("meditations.search_placeholder")}
              value={searchQuery}
              onChange={handleSearchChange}
              className="block w-[456px] rounded-xl border border-neutral-400 p-4 pl-10 text-sm text-neutral-950 placeholder:text-neutral-400 hover:ring-1 hover:ring-tertiary-600 focus:shadow-input focus:outline-none focus:ring-1 focus:ring-tertiary-600"
            />
          </div>
        </div>
      </div>
      <MeditationsTable
        selectedBundles={[]}
        searchQuery={searchQuery}
        approvedTab
        selectedRadioValue={selectedRadioValue}
      />
    </ContentLayout>
  );
};
