import { ForgotPasswordForm } from "@/components";
import { CenteredContainerLayout } from "@/layout";

export const PasswordForgot = () => {
  return (
    <div>
      <CenteredContainerLayout className="md:w-[550px]">
        <ForgotPasswordForm />
      </CenteredContainerLayout>
    </div>
  );
};
