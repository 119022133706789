import React from "react";
import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import { tw } from "@/utils";

interface SwitchProps<T extends FieldValues> extends UseControllerProps<T> {
  control: Control<T>;
}

export const Switch = <T extends FieldValues>({
  control,
  name,
}: SwitchProps<T>) => {
  const { field } = useController({ control, name });

  const handleToggle = () => {
    field.onChange(!field.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      handleToggle();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={tw(
        "relative flex h-6 w-12 min-w-12 cursor-pointer items-center rounded-2xl p-1 transition-colors",
        field.value ? "bg-primary-900" : "bg-primary-600",
      )}
      onClick={handleToggle}
      onKeyDown={handleKeyDown}
    >
      <div
        className={tw(
          "h-4 w-4 transform rounded-full bg-white shadow-md transition-transform",
          field.value ? "translate-x-6" : "translate-x-0",
        )}
      ></div>
    </div>
  );
};
