import type { Batch, BatchFormValues, BatchUser } from "@/types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

export const getBatch = async (
  batchId: Batch["id"] | undefined,
): Promise<Batch> => {
  const response = await privateAPI.get<ServiceResponse<Batch>>(
    `/batches/${batchId}`,
  );

  return response.data.data;
};

export const getBatchUsers = async (
  batchId: Batch["id"] | undefined,
  page: number,
  searchQuery = "",
): Promise<ServiceResponse<BatchUser[]>> => {
  const params: Record<string, string | number> = {
    include: "tokens",
    page,
  };

  if (searchQuery) {
    params["filter[email]"] = searchQuery;
  }

  const response = await privateAPI.get<ServiceResponse<BatchUser[]>>(
    `/batches/${batchId}/users`,
    {
      params,
    },
  );

  return response.data;
};

export const addBatch = async (batch: BatchFormValues): Promise<Batch> => {
  const response = await privateAPI.post<ServiceResponse<Batch>>(`/batches`, {
    ...batch,
  });

  return response.data.data;
};

export const updateBatch = async ({
  batchId,
  batch,
}: {
  batchId: Batch["id"];
  batch: BatchFormValues;
}): Promise<Batch> => {
  const response = await privateAPI.put<ServiceResponse<Batch>>(
    `/batches/${batchId}`,
    {
      ...batch,
    },
  );
  return response.data.data;
};
