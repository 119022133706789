import { Outlet } from "react-router-dom";

import { Sidebar } from "@/components";

export const SidebarLayout = () => {
  return (
    <div className="flex h-screen flex-row overflow-hidden bg-white">
      <div className="h-screen md:block">
        <Sidebar />
      </div>
      <main className="h-full grow overflow-y-auto bg-neutral-50">
        <Outlet />
      </main>
    </div>
  );
};
