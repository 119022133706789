import { useMutation, useQuery } from "@tanstack/react-query";

import { addBatch, getBatch, getBatchUsers, updateBatch } from "@/api";
import type { Batch } from "@/types/models";
import { useDebouncedQuery } from "./useDebouncedQuery";

export const batchesQueryKeys = {
  useBatch: (batchId: Batch["id"] | undefined) => [
    "batches",
    "getBatch",
    batchId,
  ],
  useBatchUsersPage: (page: number, searchQuery: string) => [
    "batchUsers",
    "getBatchUser",
    page,
    searchQuery,
  ],
  useBatches: () => ["batches", "getBatches"],
};

export const useBatches = () => {
  const useBatch = (batchId: Batch["id"] | undefined) =>
    useQuery({
      queryFn: () => getBatch(batchId),
      queryKey: batchesQueryKeys.useBatch(batchId),
      enabled: !!batchId,
    });

  const useBatchUsers = (batchId: string, page: number, searchQuery: string) =>
    useDebouncedQuery({
      queryFn: () => getBatchUsers(batchId, page, searchQuery),
      queryKey: batchesQueryKeys.useBatchUsersPage(page, searchQuery),
    });

  const useAddBatch = () =>
    useMutation({
      mutationFn: addBatch,
    });

  const useUpdateBatch = () => useMutation({ mutationFn: updateBatch });

  return {
    useBatch,
    useBatchUsers,
    useAddBatch,
    useUpdateBatch,
  };
};
