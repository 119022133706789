import { createColumnHelper } from "@tanstack/react-table";

import i18n from "@/i18n";
import type { BatchUser } from "@/types";
import { formatDateTimeToDDMMYYYYHHMMSS } from "@/utils/dates";
import { tw } from "../../utils/tw";

const columnHelper = createColumnHelper<BatchUser>();

export function getTableColumns() {
  const columns = [
    columnHelper.accessor("email", {
      header: i18n.t("batches_and_users.unique_identifier"),
      cell: (info) => (
        <span className={tw(info.row.original.locked && "opacity-50")}>
          {info.getValue()}{" "}
        </span>
      ),
    }),
    columnHelper.accessor("terms_and_conditions_accepted_at", {
      header: i18n.t("batches_and_users.date_joined"),
      cell: (info) => (
        <span className={tw(info.row.original.locked && "opacity-50")}>
          {info.getValue()
            ? formatDateTimeToDDMMYYYYHHMMSS(info.getValue())
            : "-"}{" "}
        </span>
      ),
    }),
    columnHelper.accessor("tokens.last_login", {
      header: i18n.t("batches_and_users.last_login"),
      cell: (info) => (
        <span className={tw(info.row.original.locked && "opacity-50")}>
          {info.getValue()
            ? formatDateTimeToDDMMYYYYHHMMSS(info.getValue())
            : "-"}{" "}
        </span>
      ),
    }),
  ];

  return columns;
}
