import {
  HandsClapping,
  Handshake,
  Info,
  MagnifyingGlass,
  Plus,
} from "@phosphor-icons/react";
import { t } from "i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { BatchUsersTable, InfoCard } from "@/components";
import { useBatches, usePartners, useSearch } from "@/hooks";
import { ContentLayout } from "@/layout";
import { MODAL_ROUTES, ROUTES } from "@/router";
import { useNavigateModal } from "@/router/useNavigateModal";
import { Breadcrumb, Button, Heading, SectionHeader } from "@/ui";
import { tw } from "@/utils";
import { formatDateToDDMMYYYY } from "@/utils/dates";

export const BatchDetail = () => {
  const navigate = useNavigate();
  const navigateModal = useNavigateModal();
  const { partnerId, batchId } = useParams();
  const { usePartner } = usePartners();
  const { useBatch } = useBatches();
  const { data: partner } = usePartner(partnerId);
  const { data: batch } = useBatch(batchId);
  const [searchParams] = useSearchParams();
  const { searchQuery, handleSearchChange } = useSearch("");
  const batchNumber = searchParams.get("batchNumber");
  const batchTitle = "Batch " + batchNumber;
  const partnerUrl = "/partners/" + partnerId;

  const breadcrumbItems = [
    { label: "Partner", href: ROUTES.partners, icon: <Handshake /> },
    {
      label: partner?.name ?? "",
      href: partnerUrl,
      icon: <HandsClapping />,
    },
    { label: batchTitle },
  ];

  return (
    <ContentLayout className="flex flex-col gap-y-6">
      <Breadcrumb
        items={breadcrumbItems}
        current={batchTitle}
        onItemClick={(item) => {
          if (item.href) {
            navigate(item.href);
          }
        }}
      />
      <div className="space-y-2">
        <button
          className="text-start text-sm text-tertiary-600"
          onClick={() => {
            navigate(partnerUrl);
          }}
        >
          {`< ${t("batches_and_users.back_to")} ${partner?.name}`}
        </button>
        <SectionHeader
          left={<Heading level={2} heading={batchTitle} className="text-3xl" />}
          right={
            <Button
              size="md"
              className="h-12"
              variant="outlined"
              onClick={() =>
                navigateModal(MODAL_ROUTES.editBatch, {
                  partnerId,
                  batch,
                  batchNumber,
                })
              }
            >
              <Plus size={20} />
              {t("batches_and_users.edit_batch")}
            </Button>
          }
        />
      </div>
      <div className="flex flex-col space-y-4 rounded-2xl bg-tertiary-100 p-4">
        <div className="flex justify-between space-x-4">
          <InfoCard
            label={t("batches_and_users.joined_users")}
            value={`${batch?.users_count} / ${batch?.capacity} `}
            className="w-1/3"
          />
          <InfoCard
            label={t("batches_and_users.effective_from")}
            value={batch ? formatDateToDDMMYYYY(batch.from_date) : ""}
            className="w-1/3"
          />
          <InfoCard
            label={t("batches_and_users.expiration_date")}
            value={batch ? formatDateToDDMMYYYY(batch.to_date) : ""}
            className="w-1/3"
          />
        </div>
        <div className="flex items-center space-x-1">
          <Info size={18} className="text-neutral-800" />
          <p className="text-neutral-800">
            {t("batches_and_users.batches_activate_and_expire")}
          </p>
        </div>
      </div>
      <div className="space-y-5">
        <div className="flex flex-col gap-y-2">
          <Heading heading={t("general.users")} />
          <p>{t("batches_and_users.users_have_access")}</p>
        </div>
        <div className="relative flex flex-row items-center">
          <div className="pointer-events-none absolute pl-3">
            {
              <MagnifyingGlass
                size={20}
                className={tw(
                  "text-neutral-950",
                  !searchQuery && "text-neutral-500",
                )}
              />
            }
          </div>
          <input
            type="text"
            id="search"
            placeholder={t("batches_and_users.search_placeholder")}
            value={searchQuery}
            onChange={handleSearchChange}
            className="block w-[456px] rounded-xl border border-neutral-400 p-4 pl-10 text-sm text-neutral-950 placeholder:text-neutral-400 hover:ring-1 hover:ring-tertiary-600 focus:shadow-input focus:outline-none focus:ring-1 focus:ring-tertiary-600"
          />
        </div>
        {batchId && (
          <BatchUsersTable batchId={batchId} searchQuery={searchQuery} />
        )}
      </div>
    </ContentLayout>
  );
};
