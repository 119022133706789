import type { Table as ReactTable, Row } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";

import { tw } from "@/utils";
import { TableLoader } from "./TableLoader";

interface TableProps<T> {
  table: ReactTable<T>;
  handleRowClick?: (row: T, index: number) => void;
  isLoading?: boolean;
  className?: string;
}

export const Table = <T,>({
  table,
  handleRowClick,
  isLoading,
  className,
}: TableProps<T>) => {
  if (isLoading) {
    return <TableLoader />;
  }

  return (
    <div
      className={tw(
        "overflow-x-auto rounded-t-2xl border border-neutral-400",
        className,
      )}
    >
      <table className="w-full">
        <thead className="w-full bg-primary-900 text-sm font-bold text-primary-50">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="w-full">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="sticky top-0 flex-grow bg-primary-900 px-8 py-4 text-left"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row: Row<T>, index: number) => (
            <tr
              key={row.id}
              className={tw(
                "w-full",
                index % 2 === 1 ? "bg-neutral-100" : "bg-white",
                handleRowClick && "cursor-pointer",
              )}
              onClick={
                handleRowClick
                  ? () => handleRowClick(row.original, row.index)
                  : undefined
              }
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="flex-grow px-8 py-4">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
