import { Plus } from "@phosphor-icons/react";
import { t } from "i18next";
import { useParams } from "react-router-dom";

import { MODAL_ROUTES } from "@/router";
import { useNavigateModal } from "@/router/useNavigateModal";
import { Button, Heading } from "@/ui";
import { BatchesTable } from "./BatchesTable";

export const BatchesAndUsers = () => {
  const navigateModal = useNavigateModal();
  const { partnerId } = useParams();

  return (
    <div className="px-6 py-4">
      <div className="flex justify-between">
        <div className="flex flex-col gap-y-2">
          <Heading heading={t("batches_and_users.batches")} />
          <p className="pb-6">{t("batches_and_users.users_have_access")}</p>
        </div>
        <Button
          size="md"
          className="h-12"
          variant="outlined"
          onClick={() => navigateModal(MODAL_ROUTES.addBatch, { partnerId })}
        >
          <Plus size={20} />
          {t("batches_and_users.create_new")}
        </Button>
      </div>
      <BatchesTable />
    </div>
  );
};
