import { format } from "date-fns";

export function formatDateToDDMMYYYY(isoDate: Date | null): string {
  if (!isoDate) return "-";
  return format(new Date(isoDate), "dd/MM/yyyy");
}

export function getRoundedMinutes(time: string): string {
  const [hours = 0, minutes = 0, seconds = 0] = time.split(":").map(Number);
  let totalMinutes = hours * 60 + minutes;
  if (seconds > 0) {
    totalMinutes++;
  }

  return `${totalMinutes} min`;
}

export function formatDateTimeToDDMMYYYYHHMMSS(isoDate: Date | null): string {
  if (!isoDate) return "-";
  return format(isoDate, "dd/MM/yyyy, h:mm:ss a");
}

export function formatDateForInput(date?: Date): string {
  if (!date) return "";
  return format(date, "yyyy-MM-dd");
}

export function convertMinutesToTimeFormat(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const date = new Date(0, 0, 0, hours, remainingMinutes, 0);
  return format(date, "HH:mm:ss");
}

export function convertTimeToMinutesFormat(time: string): number {
  const [hoursStr, minutesStr, secondsStr] = time.split(":");

  const hours = Number(hoursStr) || 0;
  const minutes = Number(minutesStr) || 0;
  const seconds = Number(secondsStr) || 0;

  return hours * 60 + minutes + seconds / 60;
}
