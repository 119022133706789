import type { CommonTypes } from "@/types";
import { tw } from "../utils/tw";

interface ContainerProps extends CommonTypes {
  containerClassName?: string;
}

export const CenteredContainerLayout = ({
  children,
  className,
  containerClassName,
}: ContainerProps) => {
  return (
    <div
      className={tw(
        "flex min-h-screen items-start justify-center bg-primary-100 md:items-center",
        containerClassName,
      )}
    >
      <div
        className={tw(
          "h-full min-h-screen w-full bg-neutral-50 px-6 md:min-h-0 md:w-auto md:rounded-2xl md:bg-white md:px-0 md:shadow-md",
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};
