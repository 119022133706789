import { Skeleton } from "@/ui";

export const TableLoader = () => {
  return (
    <div className="overflow-x-auto rounded-t-2xl border border-neutral-400">
      <table className="w-full">
        <thead className="w-full bg-primary-900 text-sm font-bold text-primary-50">
          <tr className="w-full">
            {Array.from({ length: 5 }).map((_, index) => (
              <th
                key={index}
                className="sticky top-0 bg-primary-900 px-8 py-4 text-left"
              >
                <Skeleton />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 3 }).map((_, index) => (
            <tr
              key={index}
              className="w-full animate-pulse"
              style={{
                background:
                  index % 2 === 0
                    ? "linear-gradient(to left, #E6DFF2 25%, #FFFFFF 50%, #E6DFF2 75%)"
                    : "linear-gradient(to right, #FFFFFF 25%, #E6DFF2 50%, #FFFFFF 75%)",
                backgroundSize: "200% 100%",
                animation:
                  index % 2 === 0
                    ? "loading-left 1.5s ease-in-out infinite"
                    : "loading-right 1.5s ease-in-out infinite",
              }}
            >
              {Array.from({ length: 5 }).map((_, cellIndex) => (
                <td key={cellIndex} className="flex-grow px-8 py-4">
                  <Skeleton />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <style>{`
          @keyframes loading-left {
            0% {
              background-position: 0% 0%;
            }
            100% {
              background-position: 100% 0%;
            }
          }

          @keyframes loading-right {
            0% {
              background-position: 100% 0%;
            }
            100% {
              background-position: 0% 0%;
            }
          }
        `}</style>
    </div>
  );
};
