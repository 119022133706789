import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import type { Path } from "react-hook-form";
import { useForm } from "react-hook-form";

import { partnersQueryKeys, useCourses } from "@/hooks";
import { additionalResourceSchema, RESOURCE_TYPES } from "@/types";
import type { AddEditAdditionalResource, AddEditCourse, Course } from "@/types";
import {
  errorToast,
  Input,
  Label,
  Loader,
  TextArea,
  useToastStore,
} from "@/ui";
import { getHeaderInformation } from "@/utils/courseUtils";
import { FormCard } from "./FormCard";

interface MindfulnessHelpProps {
  partnerId: string;
  initialCourse: Course;
}

export const MindfulnessHelp = ({
  initialCourse,
  partnerId,
}: MindfulnessHelpProps) => {
  const { useUpdateCourse } = useCourses();
  const { mutateAsync: updateCourse } = useUpdateCourse();
  const pushToast = useToastStore((state) => state.pushToast);

  const headerInformation = getHeaderInformation(
    initialCourse.additionalResources,
  );

  const queryClient = useQueryClient();

  const invalidate = () => {
    void queryClient.invalidateQueries({
      queryKey: partnersQueryKeys.useSelfPacedCourse(partnerId),
      exact: false,
    });
    void pushToast({
      type: "success",
      title: t("course.updated_successfully_title"),
      message: t("course.updated_successfully_message"),
    });
  };

  const defaultInitialValues = {
    description: headerInformation?.description ?? "",
    action: headerInformation?.action ?? "",
    url: headerInformation?.url ?? "",
    title: headerInformation?.title ?? "-",
  };

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
  } = useForm<AddEditAdditionalResource>({
    resolver: zodResolver(additionalResourceSchema),
    mode: "all",
    defaultValues: defaultInitialValues,
  });

  const onClear = async () => {
    try {
      reset({
        description: "",
        action: "",
        url: "",
        title: "-",
      });

      const updatedAdditionalResources =
        initialCourse.additionalResources.filter(
          (resource) => resource.type !== RESOURCE_TYPES.HEADER_INFORMATION,
        );

      const updatedCourse: AddEditCourse = {
        ...initialCourse,
        additional_resources: updatedAdditionalResources,
        related_resources: initialCourse.relatedResources,
      };

      await updateCourse({
        courseId: initialCourse.id,
        course: updatedCourse,
      });

      invalidate();
    } catch (error) {
      errorToast(error);
    }
  };

  const onSubmit = async (additionalResource: AddEditAdditionalResource) => {
    try {
      const updatedAdditionalResources = initialCourse.additionalResources.map(
        (resource) =>
          resource.type === RESOURCE_TYPES.HEADER_INFORMATION
            ? {
                ...resource,
                ...additionalResource,
                type: resource.type,
              }
            : resource,
      );

      if (
        !updatedAdditionalResources.some(
          (resource) => resource.type === RESOURCE_TYPES.HEADER_INFORMATION,
        )
      ) {
        updatedAdditionalResources.push({
          ...additionalResource,
          type: RESOURCE_TYPES.HEADER_INFORMATION,
          title: "How can mindfulness help?",
        });
      }

      const updatedCourse: AddEditCourse = {
        ...initialCourse,
        additional_resources: updatedAdditionalResources,
        related_resources: initialCourse.relatedResources,
      };

      await updateCourse({
        courseId: initialCourse.id,
        course: updatedCourse,
      });

      invalidate();
    } catch (error) {
      errorToast(error);
    }
  };

  useEffect(() => {
    const userChanges = watch() as Partial<AddEditAdditionalResource>;

    Object.entries(userChanges).forEach(([key, value]) => {
      setValue(key as Path<AddEditAdditionalResource>, value);
    });
  }, [initialCourse, setValue, watch]);

  if (!initialCourse) {
    return <Loader />;
  }

  return (
    <FormCard
      title={t("course.how_can_mindfulness_help")}
      label={t("course.section_optional")}
      onClear={onClear}
      onSubmit={handleSubmit(onSubmit)}
      submitButtonText={t("course.save_this_block")}
      isDirty={isDirty}
      containerClassName="bg-white border border-neutral-400"
    >
      <form className="flex flex-col gap-y-4">
        <TextArea
          id="description"
          label={t("course.description")}
          placeholder={t("course.add_a_description")}
          {...register("description")}
          error={errors.description?.message}
          required
        />
        <div className="flex flex-col gap-y-4 rounded-2xl bg-neutral-50 p-4">
          <Label
            className="text-lg font-semibold text-primary-950"
            label={t("course.button")}
          />
          <Input
            id="action"
            label={t("course.text")}
            placeholder={t("course.button_text")}
            {...register("action")}
            error={errors.action?.message}
            required
          />
          <Input
            id="url"
            label={t("course.link")}
            placeholder={t("course.button_link")}
            {...register("url")}
            error={errors.url?.message}
            required
          />
        </div>
      </form>
    </FormCard>
  );
};
