import { z } from "zod";

export interface ServiceResponse<T> {
  status: number;
  success: boolean;
  data: T;
  pagination?: {
    count: number;
    total: number;
    perPage: number;
    currentPage: number;
    totalPages: number;
    links: {
      previous: string;
      next: string;
    };
  };
}

export const errorResponseSchema = z.object({
  status: z.number(),
  success: z.boolean(),
  error: z.object({
    code: z.string(),
    message: z.string(),
    fields: z.record(z.array(z.string())).optional(),
  }),
});

export type ErrorResponse = z.infer<typeof errorResponseSchema>;

declare module "@tanstack/react-query" {
  interface Register {
    defaultError: ErrorResponse;
  }
}
