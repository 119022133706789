import type { AddEditCollaborator, Collaborator } from "@/types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

export const getCollaborators = async (): Promise<
  ServiceResponse<Collaborator[]>
> => {
  const response =
    await privateAPI.get<ServiceResponse<Collaborator[]>>("/collaborators");
  return response.data;
};

export const addCollaborator = async (
  collaborator: AddEditCollaborator,
): Promise<Collaborator> => {
  const response = await privateAPI.post<ServiceResponse<Collaborator>>(
    `/collaborators`,
    {
      ...collaborator,
    },
  );

  return response.data.data;
};

export const updateCollaborator = async ({
  collaboratorId,
  collaborator,
}: {
  collaboratorId: Collaborator["id"];
  collaborator: AddEditCollaborator;
}): Promise<Collaborator> => {
  const response = await privateAPI.put<ServiceResponse<Collaborator>>(
    `/collaborators/${collaboratorId}`,
    {
      ...collaborator,
    },
  );
  return response.data.data;
};
