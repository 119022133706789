import { zodResolver } from "@hookform/resolvers/zod";
import { EnvelopeSimpleOpen, Lock } from "@phosphor-icons/react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { useForgotPasswordMail } from "@/api";
import i18n from "@/i18n";
import { ROUTES } from "@/router";
import { Button, Circle, Heading, Input } from "@/ui";

const emailFormSchema = z.object({
  email: z
    .string()
    .min(1, { message: i18n.t("auth.email_is_required") })
    .email({ message: i18n.t("auth.email_is_not_valid") }),
});

type EmailForm = z.infer<typeof emailFormSchema>;

export const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: sendForgotPasswordMail } = useForgotPasswordMail();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<EmailForm>({
    resolver: zodResolver(emailFormSchema),
  });

  const onSubmit: SubmitHandler<EmailForm> = (data) => {
    sendForgotPasswordMail(data);
    navigate(ROUTES.passwordResetEmailSent, { state: { email: data.email } });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex min-h-screen flex-col justify-between gap-y-14 py-9 md:min-h-0 md:px-24 md:py-14"
    >
      <div className="flex flex-col gap-y-2.5">
        <div className="flex flex-col gap-y-2">
          <Heading
            heading={t("auth.reset_password")}
            left={
              <Circle>
                <Lock size={24} className="text-tertiary-800" />
              </Circle>
            }
          />
          <p className="font-semibold text-primary-950">
            {t("auth.enter_your_email_address_we_will_send_lik_to_reset")}
          </p>
        </div>
        <Input
          left={<EnvelopeSimpleOpen size={20} />}
          placeholder={t("auth.email_example")}
          id="email"
          label={t("auth.email")}
          required
          error={errors.email?.message}
          {...register("email")}
        />
      </div>
      <Button size="md" variant="filled" type="submit" className="md:w-44">
        {t("general.continue")}
      </Button>
    </form>
  );
};
