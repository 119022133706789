import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import type { QueryFunction, QueryKey } from "@tanstack/react-query";

export interface UseDebouncedQueryOptions<T> {
  queryFn: QueryFunction<T, QueryKey>;
  queryKey: QueryKey;
  onError?: (error: unknown) => void;
  select?: (data: T) => unknown;
}

export const useDebouncedQuery = <T>(
  params: UseDebouncedQueryOptions<T>,
  debounce = 300,
) => {
  const [debouncedParams, setDebouncedParams] = useState(params);
  const [loading, setLoading] = useState(false);
  const stringify = (obj: unknown) => JSON.stringify(obj);

  const query = useQuery({
    ...debouncedParams,
  });

  useEffect(() => {
    setLoading(true);
    if (stringify(params) !== stringify(debouncedParams)) {
      const timerId = setTimeout(() => {
        setDebouncedParams(params);
        setLoading(false);
      }, debounce);
      return () => clearTimeout(timerId);
    } else {
      setLoading(false);
    }
  }, [params, debouncedParams, debounce]);

  return { ...query, isLoading: loading || query.isLoading } as {
    data: T | undefined;
    isLoading: boolean;
  };
};
