import { tw } from "@/utils";
import toastIcons from "./toastIcons";
import type { Toast } from "./toastStore";

export interface ToastMessageProps {
  toast: Toast;
  onClose: () => void;
}

export const ToastMessage = ({ toast, onClose }: ToastMessageProps) => {
  return (
    <div
      onClick={onClose}
      onKeyUp={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onClose();
        }
      }}
      role="button"
      tabIndex={0}
      className={tw(
        "ring-black pointer-events-auto z-50 w-full max-w-sm overflow-hidden rounded-lg border bg-white shadow-lg ring-1 ring-opacity-5",
        toast.type === "error" &&
          "border-error-600 bg-error-100 ring-error-600",
        toast.type === "success" &&
          "border-success-600 bg-success-100 ring-success-600",
        toast.type === "warning" &&
          "border-warning-600 bg-warning-100 ring-warning-600",
        toast.state === "open" && "animate-in slide-in-from-right duration-300",
        toast.state === "isClosing" && "animate-out fade-out-0 duration-500",
      )}
    >
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {toast.icon ? toast.icon : toastIcons[toast.type]}
          </div>

          <div className="ml-3 flex w-0 flex-1 flex-col gap-3 pt-0.5">
            {toast.title && (
              <p
                className={tw(
                  "text-sm font-bold text-primary-950",
                  toast.type === "error" && "text-error-900",
                )}
              >
                {toast.title}
              </p>
            )}
            {toast.message && (
              <p className="text-sm text-primary-950">{toast.message}</p>
            )}
          </div>

          <div className="ml-4 flex flex-shrink-0">
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500 focus:ring-indigo-500 inline-flex rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-offset-2"
              onClick={onClose}
            >
              <span className="sr-only">Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
