import axios from "axios";

import type { UploadParams, UploadUrl } from "../types/url";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

export const getUploadUrl = async (endpoint: string): Promise<UploadUrl> => {
  const response = await privateAPI.get<ServiceResponse<UploadUrl>>(endpoint);

  return response.data.data;
};

export const uploadFile = async ({ file, uploadUrl }: UploadParams) => {
  const response = await axios.put<ServiceResponse<null>>(uploadUrl, file);
  return response.data.success;
};
