import { CenteredContainerLayout } from "@/layout";
import { Heading, StickyHeader } from "@/ui";
import OMFLogo from "../../assets/images/OMFLogo.svg";

export const TermsOfUse = () => {
  return (
    <div className="">
      <StickyHeader className="bg-primary-600">
        <div className="flex justify-center px-28 py-6 md:justify-start">
          <img src={OMFLogo} alt="OMF Logo" />
        </div>
      </StickyHeader>
      <CenteredContainerLayout containerClassName="md:items-start md:py-10">
        <div className="flex w-full flex-col gap-y-4 px-6 py-7 md:w-[672px] md:px-12 md:py-9">
          <div>
            <Heading
              className="text-2xl"
              level={1}
              heading="Oxford Mindfulness App Terms of Use"
            />
            <Heading
              className="font-sans text-xl font-medium"
              heading="Last Revised 5th August 2024"
            />
          </div>

          <div className="flex flex-col gap-y-2">
            <br />
            <Heading
              className="text-lg font-medium text-primary-600"
              heading="1. Age restrictions"
            />
            <p>
              Our app is not intended for individuals under the age of 18. If
              you are under the age of 18 please seek support from our team via{" "}
              <b>app@oxfordmindfulness.org</b> and do no proceed with the app.
            </p>
            <br />
            <Heading
              className="text-lg font-medium text-primary-600"
              heading="2. Non-Discrimination"
            />
            <p>
              We will not discriminate against you for exercising any of your
              rights. Unless there is a legal reason or safeguarding concern, we
              will not: Deny you services. We will not charge different prices
              or rates for services, provide a different level or quality of
              goods or services for any reason which may be discriminatory.
            </p>
            <br />
            <Heading
              className="text-lg font-medium text-primary-600"
              heading="3. Changes to Our Terms and Conditions"
            />
            <p>
              We will update any changes to our Terms and Conditions in our app.
              If we make material changes to our Terms and Conditions, we may
              notify you of such changes through your contact information and
              invite you to review (and accept, if necessary) the changes. We
              may change these Terms and Conditions at any time. It is our
              policy to post any changes we make to our Terms and Conditions on
              this page with a notice that the Terms and Conditions have been
              updated on the app home page. The date these Terms and Conditions
              were last revised is identified at the top of the page. You are
              responsible for ensuring we have an up-to-date active and
              deliverable email address for you, and for periodically accessing
              the app and reviewing these Terms and Conditions to check for any
              changes.
            </p>
            <br />
            <Heading
              className="text-lg font-medium text-primary-600"
              heading="4. Contact Information"
            />
            <p>
              If you have any questions, concerns, complaints or suggestions
              regarding our Terms and Conditions or otherwise need to contact
              us, you may contact us by emailing us at{" "}
              <b>app@oxfordmindfulness.org</b>
            </p>
            <br />
            <Heading
              className="text-lg font-medium text-primary-600"
              heading="5. Cookies"
            />
            <p>
              We and our app service providers may use cookies, pixel tags, web
              beacons, and other technologies to receive and store certain types
              of information whenever you interact with our app through your
              mobile device. A “cookie” is a small piece of data sent and stored
              on the user’s equipment while the user is using the services. On a
              computer, you may refuse to accept browser cookies by activating
              the appropriate setting on your browser, and you may have similar
              capabilities on your mobile device in the preferences for your
              operating system or browser. However, if you select this setting,
              you may be unable to access certain parts of our app and Services.
              Unless you have adjusted your browser or operating system setting
              so that it will refuse cookies, our system will issue cookies when
              you use our app or Services.
            </p>
            <br />
            <Heading
              className="text-lg font-medium text-primary-600"
              heading="6. Flash Cookies"
            />
            <p>
              Certain features of our app and Services may use local stored
              objects (or Flash cookies) to collect and store information about
              your preferences and navigation to, from, and on our app or
              Services. Flash cookies are not managed by the same browser
              settings as are used for browser cookies. To learn how you can
              manage your Flash cookie settings, visit the Flash player settings
              page on Adobe’s website.
            </p>
            <br />
          </div>
        </div>
      </CenteredContainerLayout>
    </div>
  );
};
