import React, { useEffect, useState } from "react";
import type { ForwardedRef } from "react";
import { Check, CircleNotch, Plus, Trash } from "@phosphor-icons/react";
import { t } from "i18next";
import type { DropzoneOptions, FileWithPath } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import type { Control, FieldPath, FieldValues } from "react-hook-form";
import { Controller } from "react-hook-form";

import { useNavigateModal } from "@/router/useNavigateModal";
import { forwardRef, tw } from "@/utils";
import { MODAL_ROUTES } from "../../router/routes";
import { Circle } from "../Circle";
import { Button, IconWrapper } from "../common";
import { icons } from "../common/Icons";
import { Label } from "./Label";
import { Message } from "./Message";

const DROPZONE_STATE = {
  INITIAL: "initial",
  LOADING: "loading",
  FILLED: "filled",
} as const;
type DropZoneState = (typeof DROPZONE_STATE)[keyof typeof DROPZONE_STATE];

const SIZE = {
  sm: "sm",
  rounded: "rounded",
} as const;
type Size = (typeof SIZE)[keyof typeof SIZE];

type DropzoneValue = FileWithPath | FileWithPath[] | null | string;

interface DropzoneProps extends DropzoneOptions {
  id: string;
  compact?: boolean;
  error?: string | boolean;
  label?: string;
  message?: string;
  placeholder?: string;
  value: DropzoneValue;
  fileUrl?: string;
  state?: DropZoneState;
  size?: Size;
  className?: string;
  containerClassName?: string;
  loadedLabel?: string;
  showAddButton?: boolean;
}

const isFileWithPath = (value: unknown): value is FileWithPath => {
  return value instanceof File && "path" in value;
};

const Dropzone = forwardRef(
  (props: DropzoneProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      value,
      id,
      compact,
      error,
      label,
      message,
      multiple = false,
      placeholder,
      fileUrl,
      state,
      size,
      loadedLabel,
      className,
      containerClassName,
      showAddButton = true,
    } = props;

    const [preview, setPreview] = useState<string | null>(null);
    const navigateModal = useNavigateModal();

    const { getRootProps, getInputProps } = useDropzone({
      ...props,
      multiple,
      onDrop: (acceptedFiles, fileRejections, event) => {
        props.onDrop?.(acceptedFiles, fileRejections, event);
      },
    });

    const selectedFiles: FileWithPath[] = Array.isArray(value)
      ? value.filter(isFileWithPath)
      : isFileWithPath(value)
        ? [value]
        : [];

    const fileName =
      !multiple && selectedFiles[0]?.path
        ? selectedFiles.map((file) => file.path).join(", ")
        : "";

    const handleClearFiles = (event: React.MouseEvent) => {
      event.stopPropagation();
      props.onDrop?.([], [], event.nativeEvent);
      setPreview(null);
    };

    const renderIcon = () => {
      if (size === SIZE.sm || size === SIZE.rounded) {
        return null;
      }

      if (state === DROPZONE_STATE.LOADING) {
        return (
          <CircleNotch className="animate-spin text-tertiary-700" size={44} />
        );
      }

      if ((selectedFiles.length || preview) && !error) {
        return (
          <Circle className="flex size-16 items-center justify-center bg-tertiary-100">
            <Circle className="size-11 border-2 border-tertiary-600 bg-tertiary-300 text-tertiary-600">
              <Check size={26} className="text-tertiary-800" />
            </Circle>
          </Circle>
        );
      }

      return (
        <IconWrapper size="lg" className="h-11 w-16 text-tertiary-600">
          <icons.MultimediaIcon className="h-11 w-16" />
        </IconWrapper>
      );
    };

    let labelText = "";

    if (state === DROPZONE_STATE.LOADING) {
      labelText = t("general.uploading");
    } else if (fileName) {
      labelText = fileName;
    } else if (!preview) {
      labelText = placeholder ?? "";
    }

    useEffect(() => {
      if (fileUrl) {
        setPreview(fileUrl);
      } else {
        setPreview(null);
      }
    }, [fileUrl]);

    const showClearButton = selectedFiles.length > 0 || preview !== null;

    const handlePreviewClick = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (preview) {
        navigateModal(MODAL_ROUTES.previewFile, { preview });
      }
    };

    const isRounded = size === SIZE.rounded;

    const showAddDeleteButton = () => {
      if (showClearButton) {
        return (
          <Button
            variant="transparent"
            disabled={state === DROPZONE_STATE.LOADING}
            size="sm"
            onClick={handleClearFiles}
          >
            <Circle className="bg-currentColor border border-tertiary-800">
              <Trash size={22} className="text-tertiary-800" />
            </Circle>
          </Button>
        );
      }
      if (showAddButton) {
        return (
          <Button
            variant="transparent"
            disabled={state === DROPZONE_STATE.LOADING}
            size="sm"
          >
            <Circle
              className={`bg-currentColor border ${
                state === DROPZONE_STATE.LOADING
                  ? "text-neutral-300"
                  : "border-tertiary-800"
              }`}
            >
              <Plus
                size={22}
                className={
                  state === DROPZONE_STATE.LOADING
                    ? "text-neutral-300"
                    : "text-tertiary-800"
                }
              />
            </Circle>
          </Button>
        );
      }
      return <></>;
    };

    return (
      <div className={tw("flex flex-col gap-2 p-0.5", containerClassName)}>
        <div
          {...getRootProps({ ref })}
          className={tw(
            "flex grow flex-col items-center gap-y-5 rounded-2xl border border-tertiary-900 bg-tertiary-200 p-6 focus:border-solid focus:outline-none focus:ring-2 focus:ring-tertiary-900",
            [
              state === DROPZONE_STATE.INITIAL && "border-dashed",
              state === DROPZONE_STATE.LOADING && "",
              (state === DROPZONE_STATE.FILLED ||
                selectedFiles.length ||
                preview) &&
                "border-solid",
            ],
            size === SIZE.sm && "text-sm",
            isRounded &&
              "h-44 w-44 justify-center rounded-full p-4 text-center",
            className,
          )}
        >
          {renderIcon()}
          <div
            className={tw(
              "flex flex-col items-center gap-y-4",
              size === SIZE.sm && "flex-row-reverse",
              size === SIZE.rounded && "flex-col-reverse gap-y-0",
            )}
          >
            <div className="flex flex-col items-center">
              <Label
                className="font-medium text-tertiary-800"
                label={fileName || preview ? loadedLabel : label}
                htmlFor={id}
              />

              <input {...getInputProps({ id })} />

              <p className="text-sm font-medium text-tertiary-600">
                {labelText}
              </p>

              {preview && !selectedFiles.length && (
                <Button
                  variant="transparent"
                  size="sm"
                  onClick={handlePreviewClick}
                >
                  <span className="ml-2 text-tertiary-600 underline">
                    {t("general.open_preview")}
                  </span>
                </Button>
              )}
            </div>
            {showAddDeleteButton()}
          </div>
        </div>
        {(!compact || !!message || !!error) && (
          <Message message={message} error={error} />
        )}
      </div>
    );
  },
);

interface ControlledDropzoneProps<TFieldValues extends FieldValues>
  extends Omit<DropzoneProps, "id" | "value"> {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
}

export const ControlledDropzone = <TFieldValues extends FieldValues>({
  name,
  control,
  state = DROPZONE_STATE.INITIAL,
  size,
  multiple,
  ...props
}: ControlledDropzoneProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { onChange, value, ref } = field;
        const { error: fieldError } = fieldState;

        return (
          <Dropzone
            {...props}
            id={name}
            value={value as DropzoneValue}
            onDrop={(acceptedFiles) => {
              const newValue = multiple
                ? acceptedFiles
                : (acceptedFiles[0] ?? null);
              onChange(newValue);
            }}
            error={fieldError?.message}
            ref={ref}
            state={state}
            size={size}
          />
        );
      }}
    />
  );
};
