import { createColumnHelper } from "@tanstack/react-table";

import i18n from "@/i18n";
import { MODAL_ROUTES } from "@/router";
import type { useNavigateModal } from "@/router/useNavigateModal";
import {
  mapBackendStatusToDisplayStatus,
  mapDisplayStatusToColor,
} from "@/types";
import type { UserBundleAccess } from "@/types";
import { Button } from "@/ui";
import Badge from "@/ui/Badge";
import { mapCourseTypeLabel } from "@/utils/courseUtils";
import { formatDateToDDMMYYYY } from "@/utils/dates";

const columnHelper = createColumnHelper<UserBundleAccess>();

export function getTableColumns(
  approvedTab: boolean,
  navigateModal: ReturnType<typeof useNavigateModal>,
) {
  const columns = [
    columnHelper.display({
      id: "userName",
      header: i18n.t("materials.user_name"),
      cell: (info) => {
        const { first_name, last_name } = info.row.original.user;
        return (
          <div className="flex items-center gap-x-2">
            <span>{`${first_name} ${last_name}`}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("bundle.name", {
      header: i18n.t("materials.course_name"),
      cell: (info) => <span>{info.getValue()} </span>,
    }),
    columnHelper.accessor("bundle.type", {
      header: i18n.t("materials.course_type"),
      cell: (info) => <span>{mapCourseTypeLabel(info.getValue())} </span>,
    }),
    columnHelper.accessor("user.email", {
      header: i18n.t("materials.user_email"),
      cell: (info) => <span>{info.getValue()} </span>,
    }),
    columnHelper.accessor(!approvedTab ? "created_at" : "approved_on", {
      header: i18n.t(
        !approvedTab ? "materials.date_requested" : "materials.date_approved",
      ),
      cell: (info) => <span>{formatDateToDDMMYYYY(info.getValue())} </span>,
    }),
    columnHelper.accessor("status", {
      header: i18n.t("materials.status"),
      cell: (info) => (
        <Badge
          text={mapBackendStatusToDisplayStatus(info.getValue())}
          color={mapDisplayStatusToColor(info.getValue())}
        />
      ),
    }),
  ];

  if (!approvedTab) {
    columns.push(
      columnHelper.display({
        id: "manageAccess",
        header: "",
        cell: (info) => {
          const userBundleAccess = info.row.original;
          return (
            <Button
              onClick={() =>
                navigateModal(MODAL_ROUTES.manageUserBundleAccess, {
                  userBundleAccess,
                })
              }
              variant="outlined"
            >
              {i18n.t("materials.manage_access")}
            </Button>
          );
        },
      }),
    );
  }

  return columns;
}
