import type { ComponentPropsWithRef } from "react";

import type { CommonTypes } from "@/types";
import { tw } from "@/utils";

interface SectionHeaderProps
  extends ComponentPropsWithRef<"header">,
    CommonTypes {
  left?: React.ReactNode;
  right?: React.ReactNode;
  className?: string;
}

export const SectionHeader = ({
  left,
  right,
  className,
  ...props
}: SectionHeaderProps) => {
  return (
    <header
      className={tw("flex flex-row items-center justify-between", className)}
      {...props}
    >
      {!!left && <div>{left}</div>}
      {!!right && <div>{right}</div>}
    </header>
  );
};
