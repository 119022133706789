import { t } from "i18next";

import type { Course } from "@/types";
import { Heading } from "@/ui";
import { AboutPartner } from "./AboutPartner";
import { AdditionalInformation } from "./AdditionalInformation";
import { BasicInfo } from "./BasicInfo";
import { MindfulnessHelp } from "./MindfulnessHelp";
import { RelatedResources } from "./RelatedResources";

interface SelfPacedCourseLandingPageProps {
  partnerId?: string;
  course?: Course;
}

export const SelfPacedCourseLandingPage = ({
  partnerId,
  course,
}: SelfPacedCourseLandingPageProps) => {
  return (
    <div className="h-fit rounded-2xl">
      <div className="px-6 py-4">
        <div className="flex flex-col gap-y-2">
          <Heading heading={t("course.landing_page_content")} />
          <p className="pb-6">
            {t("course.make_sure_is_final_content_before")}
          </p>
        </div>
        {course && partnerId && (
          <div className="flex flex-col gap-5">
            <BasicInfo initialCourse={course} partnerId={partnerId} />
            <MindfulnessHelp initialCourse={course} partnerId={partnerId} />
            <AdditionalInformation
              initialCourse={course}
              partnerId={partnerId}
            />
            <RelatedResources initialCourse={course} partnerId={partnerId} />
            <AboutPartner initialCourse={course} partnerId={partnerId} />
          </div>
        )}
      </div>
    </div>
  );
};
