import { useTranslation } from "react-i18next";

import type { CommonTypes } from "@/types";
import OMFLogo from "../../assets/images/OMFLogo.svg";

export const WelcomeTitle = () => {
  const { t } = useTranslation();
  return (
    <h1 className="font-display text-3xl font-medium leading-none text-primary-950">
      {t("general.welcome")}
    </h1>
  );
};

interface TitleBlockProps extends CommonTypes {
  showWelcome?: boolean;
  logoClassName?: string;
  byClassName?: string;
  omfLogoClassName?: string;
}

export const TitleBlock = ({
  showWelcome = false,
  className,
}: TitleBlockProps) => {
  return (
    <div className="mb-3 flex flex-row items-end justify-between leading-none md:mb-0">
      {showWelcome && <WelcomeTitle />}
      <img src={OMFLogo} alt="OMF Logo" className={className} />
    </div>
  );
};
