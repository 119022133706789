import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getUser,
  getUsers,
  patchUser,
  updateUser,
  updateUserAccessLevels,
} from "@/api";
import type { User } from "@/types/models";
import { useDebouncedQuery } from "./useDebouncedQuery";

export const usersQueryKeys = {
  useUser: (userId: User["id"] | undefined) => ["users", "getUser", userId],
  useUsers: () => ["users", "getUser"],
  useUsersPage: (
    page: number,
    searchQuery: string,
    locked: number | string,
  ) => ["users", "getUser", page, searchQuery, locked],
};

export const useUsers = () => {
  const useUser = (userId: User["id"] | undefined) =>
    useQuery({
      queryFn: () => getUser(userId),
      queryKey: usersQueryKeys.useUser(userId),
      enabled: !!userId,
    });

  const useAllUsers = (
    page: number,
    searchQuery: string,
    locked: number | string,
  ) =>
    useDebouncedQuery({
      queryFn: () => getUsers(page, searchQuery, locked),
      queryKey: usersQueryKeys.useUsersPage(page, searchQuery, locked),
    });

  const useUpdateUser = () => useMutation({ mutationFn: updateUser });

  const usePatchUser = () => useMutation({ mutationFn: patchUser });

  const useUpdateUserAccessLevels = () =>
    useMutation({ mutationFn: updateUserAccessLevels });

  return {
    useUser,
    useUpdateUser,
    usePatchUser,
    useAllUsers,
    useUpdateUserAccessLevels,
  };
};
