import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useForm } from "react-hook-form";

import type { Module } from "@/api";
import { partnersQueryKeys, useMedia, usePartners } from "@/hooks";
import { courseModuleSchema } from "@/types";
import type { AddEditCourseModule } from "@/types";
import {
  ControlledDropzone,
  errorToast,
  Input,
  Loader,
  useToastStore,
} from "@/ui";
import {
  convertMinutesToTimeFormat,
  convertTimeToMinutesFormat,
} from "@/utils/dates";
import { VideoFormCard } from "./VideoFormCard";

interface EditModuleProps {
  module: Module;
}

export const EditModule = ({ module }: EditModuleProps) => {
  const { useUpdateCourseModule, useDeleteCourseModuleVideo } = usePartners();
  const { mutateAsync: updateModule } = useUpdateCourseModule();
  const { mutateAsync: deleteModuleVideo } = useDeleteCourseModuleVideo();
  const { useUpload } = useMedia();
  const {
    upload: uploadVideo,
    isUploading: isUploadingVideo,
    fileHasUploaded: videoHasUploaded,
  } = useUpload();
  const pushToast = useToastStore((state) => state.pushToast);

  const defaultInitialValues = {
    title: module.title,
    description: module.description,
    duration: convertTimeToMinutesFormat(module.duration)?.toString() ?? "0",
    video: module.video ?? undefined,
    extension: "mp4",
  };

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    control,
    reset,
  } = useForm<AddEditCourseModule>({
    resolver: zodResolver(courseModuleSchema),
    mode: "all",
    defaultValues: defaultInitialValues,
    shouldFocusError: false,
  });

  const queryClient = useQueryClient();

  const invalidate = () => {
    void queryClient.invalidateQueries({
      queryKey: partnersQueryKeys.useSelfPacedCourseModules(module.partner_id),
      exact: false,
    });
    void pushToast({
      type: "success",
      title: t("course.pre_course.updated_successfully_title"),
      message: t("course.pre_course.updated_successfully_message"),
    });
  };

  useEffect(() => {
    reset(defaultInitialValues);
  }, [module.id]);

  const onClear = async () => {
    try {
      reset({
        title: module.title,
        description: "-",
        duration: "0",
        video: "",
        extension: "mp4",
      });

      await updateModule({
        moduleId: module.id,
        module: {
          title: module.title,
          description: "-",
          duration: convertMinutesToTimeFormat(0),
          video: "",
          extension: "mp4",
        },
      });

      await deleteModuleVideo(module.id);

      invalidate();
    } catch (error) {
      errorToast(error);
    }
  };

  const onSubmit = async (courseModule: AddEditCourseModule) => {
    const { video, ...courseModuleData } = courseModule;

    const videoFile = video instanceof File ? video : null;

    const body = {
      moduleId: module.id,
      module: {
        description: courseModuleData.description,
        title: module.title,
        duration:
          convertMinutesToTimeFormat(
            Number(courseModule.duration),
          ).toString() ?? "0",
        extension: videoFile ? courseModuleData.extension : null,
      },
    };

    try {
      await updateModule(body);

      if (videoFile) {
        await uploadVideo(videoFile, `/modules/${module.id}/video/upload-url`);
      }

      reset({
        title: module.title,
        description: courseModuleData.description,
        duration: courseModule.duration,
        video: videoFile ? videoFile : video,
        extension: defaultInitialValues.extension,
      });

      invalidate();
    } catch (error) {
      errorToast(error);
    }
  };

  const getDropzoneState = () => {
    if (videoHasUploaded) return "filled";
    if (isUploadingVideo) return "loading";
    return "initial";
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (getDropzoneState() === "loading") {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isUploadingVideo]);

  if (!module) {
    return <Loader />;
  }

  return (
    <VideoFormCard
      title={module.title}
      onClear={onClear}
      onSubmit={handleSubmit(onSubmit)}
      submitButtonText={t("course.pre_course.save_this_video")}
      isDirty={isDirty}
      disableSubmit={isUploadingVideo}
    >
      <form className="flex flex-col gap-y-6">
        <ControlledDropzone
          className="bg-tertiary-50"
          name="video"
          control={control}
          loadedLabel={t("general.video_uploaded")}
          label={t("course.pre_course.drop_your_video_here")}
          error={errors.video?.message}
          state={getDropzoneState()}
          compact={true}
          fileUrl={defaultInitialValues.video}
          showAddButton={false}
          disabled={isUploadingVideo || getDropzoneState() === "loading"}
        />
        <Input
          id="description"
          labelClassName="font-normal"
          label={t("course.pre_course.video_description")}
          placeholder={t("course.pre_course.add_module_description")}
          {...register("description")}
          error={errors.description?.message}
          required
          disabled={isUploadingVideo || getDropzoneState() === "loading"}
        />
        <Input
          id="duration"
          type="number"
          label={t("course.pre_course.watching_time")}
          placeholder={t("course.pre_course.in_minutes")}
          {...register("duration")}
          error={errors.duration?.message}
          required
          min="0"
          disabled={isUploadingVideo || getDropzoneState() === "loading"}
        />
      </form>
    </VideoFormCard>
  );
};
