import { useMutation } from "@tanstack/react-query";

import { getUserBundleAccesses, updateUserBundleAccess } from "@/api";
import type { Status } from "@/types";
import { useDebouncedQuery } from "./useDebouncedQuery";

export const userBundleAccessQueryKeys = {
  useUserBundleAccess: () => ["userBundleAccess", "getUserBundleAccess"],
  useUserBundleAccessPage: (
    page: number,
    searchQuery: string,
    bundleIds: string[],
    statuses: string[],
  ) => [
    "userBundleAccess",
    "getUserBundleAccess",
    page,
    searchQuery,
    bundleIds,
    statuses,
  ],
};

export const useUserBundleAccess = () => {
  const useAllUserBundleAccess = (
    page: number,
    searchQuery: string,
    bundleIds: string[],
    statuses: Status[],
  ) =>
    useDebouncedQuery({
      queryFn: () =>
        getUserBundleAccesses(page, searchQuery, bundleIds, statuses),
      queryKey: userBundleAccessQueryKeys.useUserBundleAccessPage(
        page,
        searchQuery,
        bundleIds,
        statuses,
      ),
    });

  const useUpdateUserBundleAccess = () =>
    useMutation({ mutationFn: updateUserBundleAccess });

  return {
    useAllUserBundleAccess,
    useUpdateUserBundleAccess,
  };
};
