import type { CommonTypes } from "@/types";
import { tw } from "../utils/tw";

export const LeftContainerLayout = ({ children, className }: CommonTypes) => {
  return (
    <div className="md:py-30 flex min-h-screen flex-col items-start justify-center bg-primary-100 bg-cover bg-center md:bg-admin-login-image md:px-24">
      <div
        className={tw(
          "min-h-screen w-full bg-neutral-50 px-6 md:min-h-0 md:w-[550px] md:rounded-2xl md:bg-white md:px-0 md:shadow-md",
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};
