import { MagnifyingGlass } from "@phosphor-icons/react";
import { t } from "i18next";
import { useForm } from "react-hook-form";

import { useSearch } from "@/hooks";
import { ContentLayout } from "@/layout";
import { RadioButtonGroup } from "@/ui";
import { tw } from "@/utils";
import { UserBundleAccessTable } from "./UserBundleAccessTable";

interface AccessUsersProps {
  selectedBundles: string[];
}

export const AccessRequests = ({ selectedBundles }: AccessUsersProps) => {
  const { searchQuery, handleSearchChange } = useSearch("");

  const { control, watch } = useForm<{
    radioOption: string[];
  }>({
    defaultValues: {
      radioOption: ["access requested"],
    },
  });
  const selectedRadioValue = watch("radioOption");

  const radioOptions = [
    { label: "Requested", value: ["access requested"] },
    { label: "All", value: ["declined", "access requested"] },
    { label: "Declined", value: ["declined"] },
  ];

  return (
    <ContentLayout className="flex flex-col gap-y-6">
      <div className="flex items-center gap-x-2">
        <div className="relative flex items-center justify-between">
          <div className="flex flex-row items-center">
            <div className="pointer-events-none absolute pl-3">
              <MagnifyingGlass
                size={20}
                className={tw(
                  "text-neutral-950",
                  !searchQuery && "text-neutral-500",
                )}
              />
            </div>

            <input
              type="text"
              id="search"
              placeholder={t("profile.search_placeholder")}
              value={searchQuery}
              onChange={handleSearchChange}
              className="block w-[456px] rounded-xl border border-neutral-400 p-4 pl-10 text-sm text-neutral-950 placeholder:text-neutral-400 hover:ring-1 hover:ring-tertiary-600 focus:shadow-input focus:outline-none focus:ring-1 focus:ring-tertiary-600"
            />
          </div>
        </div>
        <RadioButtonGroup
          name="radioOption"
          control={control}
          items={radioOptions}
          label={
            <span className="text-sm text-tertiary-600">
              {t("materials.filter_by_payment_status")}
            </span>
          }
          containerClassName="flex-row bg-tertiary-100 gap-x-3 border-none"
          className="flex-row gap-x-4"
        />
      </div>
      <UserBundleAccessTable
        selectedBundles={selectedBundles}
        searchQuery={searchQuery}
        selectedRadioValue={selectedRadioValue}
      />
    </ContentLayout>
  );
};
