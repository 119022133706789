import type { ReactNode } from "react";
import React from "react";

import type { Role } from "@/types";
import { tw } from "@/utils";

export interface Tab {
  component: ReactNode;
  label: string;
  roles: string[];
  icon?: ReactNode;
}

interface TabsProps {
  tabs: Tab[];
  userRole: Role;
  currentLabel: string;
  setCurrentTab: (tab: Tab) => void;
  containerClassName?: string;
}

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  userRole,
  currentLabel,
  setCurrentTab,
  containerClassName,
}) => {
  const currentComponent = tabs.find(
    (tab) => tab.label === currentLabel,
  )?.component;

  return (
    <div className={tw("flex flex-col", containerClassName)}>
      <nav className="border-b-2 border-primary-200">
        <ul className="flex flex-row items-start">
          {tabs
            .filter((tab) => tab.roles.includes(userRole))
            .map((tab) => (
              <li key={tab.label}>
                <button
                  onClick={() => setCurrentTab(tab)}
                  className={tw(
                    "flex gap-x-2 px-4 py-1 font-semibold leading-6",
                    tab.label === currentLabel
                      ? "border-b-[3px] border-b-primary-800 text-primary-900"
                      : "text-primary-600 hover:bg-neutral-100",
                  )}
                >
                  {tab.label}
                </button>
              </li>
            ))}
        </ul>
      </nav>
      <div>{currentComponent}</div>
    </div>
  );
};
