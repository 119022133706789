import { useState } from "react";
import { CaretDown, X } from "@phosphor-icons/react";

import { tw } from "@/utils";
import Badge from "./Badge";
import { Dropdown } from "./Dropdown";
import { Label, Message } from "./form";
import { Checkbox } from "./form/CheckBox";

interface Option {
  value: string;
  label: string;
}

export interface MultiSelectProps {
  placeholder: string;
  options: Option[];
  selectedOptions: string[];
  onChange: (selectedOptions: string[]) => void;
  label?: string;
  required?: boolean;
  containerClassName?: string;
  error?: string | boolean;
  message?: string;
}

export const MultiSelect = ({
  placeholder,
  options,
  selectedOptions,
  onChange,
  label,
  required,
  containerClassName,
  error,
  message,
}: MultiSelectProps) => {
  const [open, setOpen] = useState(false);

  const handleOptionChange = (option: Option, isChecked: boolean) => {
    const newSelectedOptions = isChecked
      ? [...selectedOptions, option.value]
      : selectedOptions.filter((item) => item !== option.value);
    onChange(newSelectedOptions);
  };

  const removeOption = (optionToRemove: string) => {
    const newSelectedOptions = selectedOptions.filter(
      (selectedOption) => selectedOption !== optionToRemove,
    );
    onChange(newSelectedOptions);
  };

  return (
    <div
      className={tw(
        "relative flex w-[456px] flex-col gap-y-1",
        containerClassName,
      )}
    >
      {!!label && (
        <div className="flex flex-row gap-x-1">
          <Label
            label={label}
            className="text-lg font-semibold text-neutral-900"
          />
          {!!required && (
            <span className="text-lg font-semibold text-error-500">*</span>
          )}
        </div>
      )}
      <label
        className={tw(
          "relative z-10 block w-full rounded-md border border-neutral-400 bg-white p-4 text-sm text-neutral-400 hover:ring-1 hover:ring-tertiary-600 focus:shadow-input focus:outline-none focus:ring-1 focus:ring-tertiary-600",
          !!error &&
            "border-error-400 text-error-400 focus:border-error-400 focus:ring-error-50", // Apply error styles
        )}
      >
        <input
          type="checkbox"
          className="peer hidden"
          checked={open}
          onChange={() => setOpen(!open)}
        />
        <div className="flex items-center justify-between">
          {placeholder}
          <CaretDown size={16} />
        </div>
        <Dropdown isOpen={open} onClose={() => setOpen(false)}>
          <ul>
            {options.map((option) => (
              <li key={option.value}>
                <Checkbox
                  id={option.value}
                  label={option.label}
                  value={option.value}
                  checked={selectedOptions.includes(option.value)}
                  onChange={() =>
                    handleOptionChange(
                      option,
                      !selectedOptions.includes(option.value),
                    )
                  }
                  className="pt-0.5"
                />
              </li>
            ))}
          </ul>
        </Dropdown>
      </label>
      <div className="mt-2 flex w-full flex-wrap gap-1">
        {selectedOptions.map((selectedOption) => (
          <Badge
            key={selectedOption}
            className="bg-primary-600 px-3 py-1 text-white"
            text={
              options.find((option) => option.value === selectedOption)
                ?.label ?? ""
            }
            right={
              <button
                onClick={() => removeOption(selectedOption)}
                className="bg-transparent flex items-center pl-2 text-white"
              >
                <X weight="bold" />
              </button>
            }
          />
        ))}
      </div>
      {(!!message || !!error) && <Message message={message} error={error} />}
    </div>
  );
};
