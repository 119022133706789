import type { CommonTypes } from "@/types";
import { tw } from "@/utils";

const ALERT_VARIANT = {
  ERROR: "error",
  SUCCESS: "success",
} as const;
type AlertVariant = (typeof ALERT_VARIANT)[keyof typeof ALERT_VARIANT];

export interface AlertProps extends CommonTypes {
  variant: AlertVariant;
  hidden?: boolean;
}

export const Alert = ({
  children,
  className,
  variant = "error",
  hidden = true,
}: AlertProps) => {
  return (
    <div
      className={tw(
        "h-11 rounded-lg px-4 py-3 text-sm",
        variant === ALERT_VARIANT.ERROR && "bg-error-100 text-error-900",
        variant === ALERT_VARIANT.SUCCESS && "bg-success-100 text-success-900",
        hidden && "hidden",
        className,
      )}
    >
      {children}
    </div>
  );
};
