import { useQuery } from "@tanstack/react-query";

import { getBundles } from "@/api";

export const bundlesQueryKeys = {
  useBundles: () => ["bundles", "getBundles"],
};

export const useBundles = () => {
  const useAllBundles = () =>
    useQuery({
      queryFn: () => getBundles(),
      queryKey: bundlesQueryKeys.useBundles(),
    });

  return {
    useAllBundles,
  };
};
