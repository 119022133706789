interface ToggleProps {
  options: [string, string];
  selectedOption: string;
  onToggle: (option: string) => void;
}

export const Toggle = ({ options, selectedOption, onToggle }: ToggleProps) => {
  return (
    <div className="flex rounded-full border border-neutral-400 bg-white p-1">
      {options.map((option) => (
        <button
          key={option}
          className={`rounded-full px-4 py-2 transition-colors duration-200 ${
            selectedOption === option
              ? "bg-primary-500 text-white"
              : "text-primary-500 hover:bg-primary-200"
          }`}
          onClick={() => onToggle(option)}
        >
          {option}
        </button>
      ))}
    </div>
  );
};
