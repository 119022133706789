import { z } from "zod";

import i18n from "@/i18n";
import {
  ACCEPTED_AUDIO_TYPES,
  ACCEPTED_IMAGE_TYPES,
  MAX_UPLOAD_SIZE_FOR,
} from "@/utils";
import { fileValidationRequired } from "@/utils/validations";
import type { Bundle } from "./bundles";
import type { Collaborator } from "./collaborator";
import type { InterventionType } from "./interventionType";
import type { PracticeType } from "./practiceType";
import type { Theme } from "./themes";

export interface Meditation {
  id: string;
  name: string;
  description: string;
  free: boolean;
  complexity: "beginner" | "intermediate" | "advanced";
  duration: string;
  language: string;
  published: boolean;
  image: string;
  audio: string;
  teacher: Collaborator;
  interventionType: InterventionType;
  practiceType: PracticeType;
  themes: Theme[];
  bundles: Bundle[];
  featured: boolean;
}

export const meditationSchema = z
  .object({
    name: z.string().min(1, i18n.t("meditations.meditation_title_required")),
    description: z
      .string()
      .min(1, i18n.t("meditations.meditation_description_required")),
    featured: z.boolean().default(false),
    free: z.boolean().default(false),
    complexity: z
      .string()
      .min(1, i18n.t("meditations.meditation_level_required")),
    intervention_type_id: z
      .string()
      .min(1, i18n.t("meditations.intervention_type_required")),
    practice_type_id: z
      .string()
      .min(1, i18n.t("meditations.practice_type_required")),
    teacher_id: z
      .string()
      .min(1, i18n.t("meditations.meditation_teacher_required")),
    duration: z.string().min(1, i18n.t("meditations.duration_required")),
    language: z.string().min(1, i18n.t("meditations.language_required")),
    audio: z.union([
      z.string(),
      fileValidationRequired(
        MAX_UPLOAD_SIZE_FOR.AUDIO,
        ACCEPTED_AUDIO_TYPES,
        i18n.t("meditations.audio_required"),
      ),
    ]),
    image: z.union([
      z.string(),
      fileValidationRequired(
        MAX_UPLOAD_SIZE_FOR.LOGO,
        ACCEPTED_IMAGE_TYPES,
        i18n.t("meditations.image_required"),
      ),
    ]),
    published: z.boolean().default(false),
    image_extension: z.string().nullable().optional(),
    audio_extension: z.string().nullable().optional(),
    bundles: z.array(z.string()).optional().default([]),
    themes: z.array(z.string()).optional().default([]),
  })
  .refine((data) => data.bundles.length > 0 || data.themes.length > 0, {
    message: i18n.t("meditations.course_required"),
    path: ["bundles"],
  })
  .refine((data) => data.bundles.length > 0 || data.themes.length > 0, {
    message: i18n.t("meditations.themes_required"),
    path: ["themes"],
  });

export type AddEditMeditation = z.infer<typeof meditationSchema>;
