import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getUploadUrl, uploadFile } from "@/api";

const usePutFile = () => {
  return useMutation({
    mutationFn: uploadFile,
  });
};

export const useMedia = () => {
  const useUpload = () => {
    const queryClient = useQueryClient();
    const {
      mutateAsync: putFile,
      status,
      isSuccess: fileHasUploaded,
    } = usePutFile();

    const upload = async (file: File, url: string) => {
      try {
        const { url: uploadUrl } = await getUploadUrl(url);

        await putFile({ file, uploadUrl });

        await queryClient.invalidateQueries({
          queryKey: ["getMedia", url],
        });

        return { success: true };
      } catch (error) {
        console.error("Upload failed", error);
        return { success: false, error };
      }
    };

    const isUploading = status === "pending";

    return {
      upload,
      isUploading,
      fileHasUploaded,
    };
  };

  return {
    useUpload,
  };
};
