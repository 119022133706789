import { useEffect } from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { useUserBundleAccess } from "@/hooks";
import { usePagination } from "@/hooks/usePagination";
import { useNavigateModal } from "@/router/useNavigateModal";
import type { Status } from "@/types";
import { Pagination, Table } from "@/ui";
import { getTableColumns } from "./UserBundleAccessTableColumns";

interface UserBundleAccessTableProps {
  searchQuery: string;
  selectedBundles: string[];
  approvedTab?: boolean;
  selectedRadioValue: string[];
}

export const UserBundleAccessTable = ({
  searchQuery,
  selectedBundles,
  approvedTab = false,
  selectedRadioValue,
}: UserBundleAccessTableProps) => {
  const navigateModal = useNavigateModal();
  const { pagination, setPageIndex, onPaginationChange } = usePagination();
  const { useAllUserBundleAccess } = useUserBundleAccess();
  const { data: userBundleAccessData, isLoading } = useAllUserBundleAccess(
    pagination.pageIndex + 1,
    searchQuery,
    selectedBundles,
    selectedRadioValue as Status[],
  );

  useEffect(() => {
    setPageIndex(0);
  }, [searchQuery, selectedBundles, selectedRadioValue]);

  const table = useReactTable({
    data: userBundleAccessData?.data ?? [],
    columns: getTableColumns(approvedTab, navigateModal),
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: userBundleAccessData?.pagination?.totalPages ?? 1,
    state: {
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: userBundleAccessData?.pagination?.perPage ?? 10,
      },
    },
    onPaginationChange,
  });

  return (
    <div>
      <Table table={table} isLoading={isLoading} />
      <div className="flex justify-end rounded-b-2xl border border-neutral-400">
        <Pagination
          currentPage={pagination.pageIndex + 1}
          totalPages={userBundleAccessData?.pagination?.totalPages ?? 1}
          onPageChange={(page) => setPageIndex(page - 1)}
        />
      </div>
    </div>
  );
};
