import {
  BookBookmark,
  Handshake,
  Headphones,
  Lightbulb,
  PencilLine,
  SignOut,
  User,
  UsersThree,
} from "@phosphor-icons/react";
import { t } from "i18next";
import { Link, useLocation } from "react-router-dom";

import { useLogout } from "@/api";
import i18n from "@/i18n";
import { ROUTES } from "@/router";
import { useUserStore } from "@/stores";
import type { Role } from "@/types/models";
import { TitleBlock } from "@/ui";
import { tw } from "@/utils";

const navigation: {
  path: string;
  label: string;
  icon: JSX.Element;
  role: Role[];
}[] = [
  {
    path: ROUTES.partners,
    label: i18n.t("general.partners"),
    icon: <Handshake size={24} />,
    role: ["admin"],
  },
  {
    path: ROUTES.users,
    label: i18n.t("general.users"),
    icon: <UsersThree size={24} />,
    role: ["admin", "partner"],
  },
  {
    path: ROUTES.coursesAndMaterials,
    label: i18n.t("general.courses_and_materials"),
    icon: <BookBookmark size={24} />,
    role: ["admin"],
  },
  {
    path: ROUTES.meditations,
    label: i18n.t("general.meditations"),
    icon: <Headphones size={24} />,
    role: ["admin"],
  },
  {
    path: ROUTES.selfPacedCourse,
    label: i18n.t("general.self_paced_course"),
    icon: <PencilLine size={24} />,
    role: ["partner"],
  },
  {
    path: ROUTES.partnerInfo,
    label: i18n.t("partner.info"),
    icon: <Lightbulb size={24} />,
    role: ["partner"],
  },
] as const;

export const Sidebar = () => {
  const { pathname: currentPath } = useLocation();
  const { user } = useUserStore();
  const { mutate: logout } = useLogout();

  return (
    <div className="flex h-screen grow flex-col gap-y-5 overflow-y-auto ring-1 ring-white/5">
      <div className="flex w-screen shrink-0 pt-5 md:w-64">
        <TitleBlock
          logoClassName="text-xl"
          omfLogoClassName="w-5 h-2"
          byClassName="text-xs"
          className="gap-y-0 pl-4"
        />
      </div>
      {user && (
        <nav className="flex flex-1 flex-col justify-between">
          <ul>
            {navigation
              .filter((item) => item.role.includes(user.role))
              .map((item) => (
                <li key={item.label} className="w-64">
                  <Link
                    to={item.path}
                    className={tw(
                      currentPath.includes(item.path)
                        ? "text-primary border-r-[3px] border-r-primary-700 bg-primary-100 text-primary-950"
                        : "text-primary-950 hover:bg-neutral-100",
                      "group flex gap-x-2 p-4 font-semibold leading-6",
                    )}
                  >
                    {item.icon}
                    {item.label}
                  </Link>
                </li>
              ))}
          </ul>

          <ul className="pb-10">
            <li className="w-64">
              <Link
                to={ROUTES.myProfile}
                className={tw(
                  ROUTES.myProfile == currentPath
                    ? "text-primary border-r-[3px] border-r-primary-700 bg-primary-100 text-primary-950"
                    : "text-primary-950 hover:bg-neutral-100",
                  "group flex gap-x-2 p-4 text-sm font-semibold leading-6",
                )}
              >
                {<User size={24} />}
                {t("profile.my_profile")}
              </Link>
            </li>
            <li className="w-64">
              <button
                onClick={() => logout()}
                className="group flex w-full gap-x-2 p-4 text-sm font-semibold leading-6 text-primary-950 hover:bg-neutral-100"
              >
                <SignOut size={24} />
                {t("auth.log_out")}
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};
