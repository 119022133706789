import { zodResolver } from "@hookform/resolvers/zod";
import { Spinner } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { usersQueryKeys, useUsers } from "@/hooks";
import i18n from "@/i18n";
import { useUserStore } from "@/stores";
import { Button, errorToast, Input } from "@/ui";

const userSchema = z.object({
  first_name: z.string().min(1, i18n.t("profile.first_name_is_required")),
  last_name: z.string().min(1, i18n.t("profile.last_name_is_required")),
  email: z
    .string()
    .min(1, { message: i18n.t("auth.email_is_required") })
    .email({ message: i18n.t("auth.email_is_not_valid") }),
  country: z.string().min(1, i18n.t("profile.location_is_required")),
});

type ProfileFormValues = z.infer<typeof userSchema>;

export const EditProfileForm = ({ onClose }: { onClose: () => void }) => {
  const { useUser, useUpdateUser } = useUsers();
  const loggedUser = useUserStore((state) => state.user);
  const { data: user } = useUser(loggedUser?.id);
  const { mutate: updateUser, isPending: isPendingCreateUserMutation } =
    useUpdateUser();
  const queryClient = useQueryClient();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ProfileFormValues>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      country: user?.country,
    },
  });

  const onSubmit: SubmitHandler<ProfileFormValues> = (profile) => {
    updateUser(
      { profile, userId: loggedUser?.id },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: usersQueryKeys.useUser(loggedUser?.id),
          });
          onClose();
        },
        onError: (err) => {
          errorToast(err);
        },
      },
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-[610px] flex-col gap-y-14"
    >
      <div className="flex flex-col gap-y-8">
        <Input
          id="firstName"
          label={t("profile.first_name")}
          {...register("first_name")}
          error={errors.first_name?.message}
          required
        />

        <Input
          id="lastName"
          label={t("profile.last_name")}
          {...register("last_name")}
          error={errors.last_name?.message}
          required
        />

        <Input
          type="email"
          id="email"
          label="Email"
          {...register("email")}
          error={errors.email?.message}
          disabled
        />

        <Input
          id="country"
          label={t("profile.location")}
          {...register("country")}
          error={errors.country?.message}
          required
        />
      </div>

      <div className="flex justify-center gap-2">
        <Button onClick={onClose} variant="outlined" className="flex-grow">
          {t("general.cancel")}
        </Button>

        <Button type="submit" className="flex-grow">
          {isPendingCreateUserMutation ? (
            <Spinner className="h-5 w-5" />
          ) : (
            t("general.save_changes")
          )}
        </Button>
      </div>
    </form>
  );
};
