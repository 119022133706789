import type { ComponentPropsWithoutRef, ForwardedRef, ReactNode } from "react";
import { CaretDown } from "@phosphor-icons/react";

import { forwardRef, tw } from "@/utils";
import { IconWrapper } from "../common";
import { Label } from "./Label";
import { Message } from "./Message";

export interface SelectOption {
  value?: string;
  label: string;
}

export interface SelectProps
  extends Omit<ComponentPropsWithoutRef<"select">, "value"> {
  compact?: boolean;
  containerClassName?: string;
  error?: string | boolean;
  iconClassName?: string;
  id: string;
  label?: ReactNode;
  left?: ReactNode;
  message?: string;
  options: SelectOption[];
  value?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const Select = forwardRef(
  (
    {
      className,
      compact,
      containerClassName,
      error,
      id,
      label,
      left,
      message,
      required,
      value,
      options,
      placeholder,
      ...rest
    }: SelectProps,
    ref: ForwardedRef<HTMLSelectElement>,
  ) => {
    return (
      <div className={tw("relative flex flex-col gap-y-1", containerClassName)}>
        {!!label && (
          <div className="flex flex-row gap-x-1">
            <Label
              htmlFor={id}
              label={label}
              className="text-lg font-semibold text-neutral-900"
            />
            {!!required && (
              <span className="text-lg font-semibold text-error-500">*</span>
            )}
          </div>
        )}
        <div
          className={tw(
            "flex flex-row items-center",
            !!rest.disabled && "opacity-30",
          )}
        >
          {!!left && (
            <div className="pointer-events-none absolute pl-3">
              <IconWrapper
                size="sm"
                className={tw(
                  value ? "text-neutral-950" : "text-neutral-500",
                  !!error && "text-error-400",
                )}
              >
                {left}
              </IconWrapper>
            </div>
          )}
          <select
            ref={ref}
            id={id}
            value={value}
            {...rest}
            className={tw(
              "block w-full appearance-none rounded-md border border-neutral-400 p-4 text-sm text-neutral-950 hover:ring-1 hover:ring-tertiary-600 focus:shadow-input focus:outline-none focus:ring-1 focus:ring-tertiary-600",
              value === "" && "text-neutral-400",
              !!error &&
                "border-error-400 text-error-400 focus:border-error-400 focus:ring-error-50",
              !!left && "pl-10",
              !!rest.disabled && "border-neutral-500 bg-neutral-100",
              className,
            )}
          >
            {placeholder && (
              <option value="" disabled selected hidden>
                {placeholder}
              </option>
            )}
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute right-0 flex items-center px-2 text-neutral-700">
            <CaretDown size={16} />
          </div>
        </div>
        {(!!compact || !!message || !!error) && (
          <Message message={message} error={error} />
        )}
      </div>
    );
  },
);
