import type { ReactNode } from "react";
import { useEffect, useRef } from "react";

interface DropdownProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const Dropdown = ({ isOpen, onClose, children }: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    isOpen && (
      <div
        ref={dropdownRef}
        className="absolute left-0 top-full mt-2 block w-full rounded-md border border-neutral-400 bg-white p-4 text-sm text-neutral-950"
      >
        {children}
      </div>
    )
  );
};
