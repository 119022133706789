import { useEffect, useState } from "react";
import { Envelope, SealWarning } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useForgotPasswordMail } from "@/api";
import { useCountdown } from "@/hooks";
import { ROUTES } from "@/router";
import { Button, Circle, Heading } from "@/ui";

interface LocationState {
  email?: string;
}

export const ResetEmailSent = () => {
  const { t } = useTranslation();
  const location = useLocation() as { state: LocationState };
  const navigate = useNavigate();
  const email = location.state?.email;
  const { mutate: sendForgotPasswordMail } = useForgotPasswordMail();

  const { countdown, resetCountdown } = useCountdown({
    initialTime: 30,
    onCountdownEnd: () => setEmailBeingSent(false),
  });
  const [isEmailBeingSent, setEmailBeingSent] = useState(true);

  const checkEmailParam = () => {
    if (!email) {
      navigate(ROUTES.passwordForgot);
    }
  };

  useEffect(checkEmailParam, [email, navigate]);

  const handleClick = () => {
    if (email) {
      sendForgotPasswordMail({ email });
    }
    setEmailBeingSent(true);
    resetCountdown(30);
  };

  return (
    <div className="flex min-h-screen flex-col justify-between gap-y-10 py-9 md:min-h-0 md:px-10 md:py-14">
      <div className="flex flex-col gap-y-2.5">
        <Heading
          heading={
            <div className="leading-none">
              {t("auth.we_sent_an_email_to")}
              <br /> {t("auth.validate_your_account")}
            </div>
          }
          left={
            <Circle>
              <Envelope size={24} className="text-tertiary-800" />
            </Circle>
          }
        />
        <p className="font-regular text-primary-950">
          {t("auth.we_sent_an_email_to")}{" "}
          <span className="font-bold">{email}</span>{" "}
          {t("auth.with_instructions")}
        </p>
        <div className="flex flex-col gap-y-2 rounded-2xl bg-white p-3.5">
          <p className="font-bold text-primary-950">
            {t("auth.haven_t_received_the_email")}
          </p>
          <ul className="font-regular list-outside list-disc space-y-2.5 pl-4 text-primary-950">
            <li>{t("auth.check_your_spam_folder")}</li>
            <li>
              {t(
                "auth.if_you_still_cant_find_it_tap_the_resend_email_button_below",
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col gap-y-2.5">
        {!!isEmailBeingSent && (
          <div className="flex flex-col gap-y-2 rounded-2xl bg-primary-200 p-3.5 md:bg-neutral-50">
            <Heading
              heading={t("auth.we_are_sending_you_the_email_now")}
              level={4}
              left={<SealWarning className="text-primary-600" size={20} />}
              className="font-sans text-base"
            />
            <p className="font-regular font-primary-950">
              {t("auth.hang_tight_for_the_next_thirty_seconds")}
            </p>
          </div>
        )}
        <Button
          size="md"
          variant="filled"
          onClick={handleClick}
          className="md:w-44"
          disabled={isEmailBeingSent}
        >
          {t("auth.resend_email")} {isEmailBeingSent && `(${countdown}s)`}
        </Button>
      </div>
    </div>
  );
};
