import { QuestionMark, Trash } from "@phosphor-icons/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";

import i18n from "@/i18n";
import { MODAL_ROUTES } from "@/router";
import type { useNavigateModal } from "@/router/useNavigateModal";
import type { Collaborator } from "@/types";
import { getIsDefaultCollaborator } from "@/utils";

const columnHelper = createColumnHelper<Collaborator>();

export function getTableColumns(
  navigateModal: ReturnType<typeof useNavigateModal>,
  partnerId: string,
  courseId: string,
) {
  const columns = [
    columnHelper.display({
      id: "collaboratorName",
      header: i18n.t("collaborators.collaborator_name"),
      cell: (info) => {
        const collaborator = info.row.original;
        return (
          <div className="flex items-center space-x-2">
            {collaborator?.avatar ? (
              <img
                className="size-9 rounded-full bg-contain"
                src={collaborator.avatar}
                alt={t("collaborators.collaborator_name")}
              />
            ) : (
              <div className="flex h-9 w-9 items-center justify-center rounded-full border border-neutral-50 bg-white">
                <QuestionMark size={24} className="text-primary-600" />
              </div>
            )}
            <span>{collaborator.name} </span>
          </div>
        );
      },
    }),
    columnHelper.accessor("title", {
      header: i18n.t("collaborators.organization"),
      cell: (info) => <span>{info.getValue()} </span>,
    }),
    columnHelper.display({
      id: "removeCollaborator",
      header: "",
      cell: (info) => {
        const collaborator = info.row.original;
        const isDefaultCollaborator = getIsDefaultCollaborator(
          collaborator.name,
        );

        if (isDefaultCollaborator) return;

        return (
          <div className="flex justify-end">
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigateModal(MODAL_ROUTES.removeCollaborator, {
                  collaborator,
                  partnerId,
                  courseId,
                });
              }}
            >
              <Trash size={20} className="text-error-600" />
            </button>
          </div>
        );
      },
    }),
  ];

  return columns;
}
