import React from "react";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

import { tw } from "@/utils";
import { Button } from "./common";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 3;
    const halfPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(currentPage - halfPagesToShow, 1);
    let endPage = Math.min(currentPage + halfPagesToShow, totalPages);

    if (currentPage - halfPagesToShow <= 0) {
      endPage = Math.min(maxPagesToShow, totalPages);
    }

    if (currentPage + halfPagesToShow > totalPages) {
      startPage = Math.max(totalPages - maxPagesToShow + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (startPage > 1) {
      pageNumbers.unshift(1);
      if (startPage > 2) {
        pageNumbers.splice(1, 0, -1);
      }
    }

    if (endPage < totalPages) {
      pageNumbers.push(totalPages);
      if (endPage < totalPages - 1) {
        pageNumbers.splice(pageNumbers.length - 1, 0, -1);
      }
    }

    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="flex items-center py-4">
      <Button
        onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
        disabled={currentPage === 1}
        variant="transparent"
        className="px-4 py-2"
      >
        <CaretLeft size={22} />
      </Button>
      {pageNumbers.map((page, index) =>
        page === -1 ? (
          <span className="px-4 py-2 text-tertiary-800" key={index}>
            ...
          </span>
        ) : (
          <Button
            key={index}
            onClick={() => onPageChange(page)}
            className={tw(
              "px-3.5 py-1.5",
              page === currentPage
                ? "rounded-md border border-solid border-tertiary-800 text-primary-900"
                : "text-tertiary-800",
            )}
            variant="transparent"
          >
            {page}
          </Button>
        ),
      )}
      <Button
        onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
        disabled={currentPage === totalPages}
        variant="transparent"
        className="px-4 py-2"
      >
        <CaretRight size={22} />
      </Button>
    </div>
  );
};
