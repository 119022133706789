import { createColumnHelper } from "@tanstack/react-table";

import i18n from "@/i18n";
import type { Batch } from "@/types";
import Badge from "@/ui/Badge";
import {
  mapBatchStatusToColor,
  mapBatchStatusToDisplayStatus,
} from "@/utils/batch";

const columnHelper = createColumnHelper<Batch>();

export const batchesTableColumns = [
  columnHelper.display({
    id: "batchNumber",
    header: i18n.t("batches_and_users.batch_number"),
    cell: (info) => {
      const pageIndex = info.table.getState().pagination.pageIndex;
      const pageSize = info.table.getState().pagination.pageSize;
      const batchNumber = pageIndex * pageSize + info.row.index + 1;
      return <span>{batchNumber}</span>;
    },
  }),
  columnHelper.accessor("createdBy", {
    header: i18n.t("batches_and_users.created_by"),
    cell: (info) => (
      <span>
        {info.getValue().first_name} {info.getValue().last_name}
      </span>
    ),
  }),
  columnHelper.accessor("status", {
    header: i18n.t("batches_and_users.status"),
    cell: (info) => (
      <Badge
        text={mapBatchStatusToDisplayStatus(info.getValue())}
        color={mapBatchStatusToColor(info.getValue())}
      />
    ),
  }),
  columnHelper.accessor(
    (row) => ({ capacity: row.capacity, usersCount: row.users_count }),
    {
      id: "capacityAndUsers",
      header: i18n.t("batches_and_users.users"),
      cell: (info) => (
        <span>
          {info.getValue().usersCount} / {info.getValue().capacity}
        </span>
      ),
    },
  ),
];
