export const ROUTES = {
  base: "/",
  login: "/login",
  passwordForgot: "/password/forgot",
  passwordResetEmailSent: "/password/email-sent",
  passwordReset: "/password/reset",
  passwordCreate: "/password/create",
  passwordResetSuccess: "/password/reset/success",
  passwordCreatedSuccess: "/password/created/success",
  verificationSuccess: "/verification/success",
  home: "/home",
  partners: "/partners",
  partnerDetail: "/partners/:partnerId",
  partnerInfo: "/partner/info",
  users: "/users",
  coursesAndMaterials: "/courses-and-materials",
  meditations: "/meditations",
  addMeditation: "/meditations/new",
  editMeditation: "/meditations/:meditationId/edit",
  selfPacedCourse: "/self-paced-course",
  codes: "/codes",
  myProfile: "/my-profile",
  notFound: "/not-found",
  serverError: "/unavailable",
  privacyPolicy: "/privacy-policy",
  termsOfUse: "/terms-of-use",
  batchDetail: "/partners/:partnerId/batches/:batchId",
} as const;

export const MODAL_ROUTES = {
  exampleModal: "/example-modal",
  editProfile: "/edit-profile",
  addPartner: "/add-partner",
  editPartner: "/edit-partner",
  addBatch: "/add-batch",
  editBatch: "/edit-batch",
  manageUserBundleAccess: "/manage-user-bundle-access",
  courseSectionExamples: "/course-section-example",
  manageUserAccessLevel: "/manage-user-access-level",
  lockUser: "/lock-user",
  previewFile: "/preview-file",
  addCollaborator: "/add-collaborator",
  editCollaborator: "/edit-collaborator",
  removeCollaborator: "/remove-collaborator",
  addTeacher: "/add-teacher",
  addPracticeType: "/add-practice-type",
} as const;
