import { forwardRef, useState } from "react";
import { Eye, EyeClosed, Lock } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { tw } from "@/utils";
import { Button } from "../common";
import type { InputProps } from "./Input";
import { Input } from "./Input";

export interface PasswordInputProps extends InputProps {}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ error, ...rest }, ref) => {
    const [hidden, setHidden] = useState(true);
    const { t } = useTranslation();

    return (
      <div className="relative">
        <Input
          {...rest}
          type={hidden ? "password" : "text"}
          id={rest.id}
          placeholder={t("auth.password_example")}
          aria-hidden="true"
          left={<Lock size={20} />}
          ref={ref}
          error={error}
        />
        <Button
          className={tw(
            "absolute right-2 top-[38px] px-1",
            error ? "text-error-500 hover:text-error-500" : "text-primary-950",
          )}
          onClick={() => setHidden((prev) => !prev)}
          type="button"
          variant="transparent"
        >
          {hidden ? <EyeClosed size={20} /> : <Eye size={20} />}
        </Button>
      </div>
    );
  },
);

PasswordInput.displayName = "PasswordInput";
