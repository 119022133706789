import { zodResolver } from "@hookform/resolvers/zod";
import { EnvelopeSimpleOpen, HandWaving } from "@phosphor-icons/react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { z } from "zod";

import { useLogin } from "@/api";
import i18n from "@/i18n";
import { ROUTES } from "@/router";
import type { errorMessageKeys } from "@/types";
import { Alert, Button, Input, TitleBlock } from "@/ui";
import { PasswordInput } from "@/ui/form/PasswordInput";
import { Circle } from "../ui/Circle";
import { Heading } from "../ui/Heading";

const loginFormSchema = z.object({
  email: z
    .string()
    .min(1, { message: i18n.t("auth.email_is_required") })
    .email({ message: i18n.t("auth.email_is_not_valid") }),
  password: z.string().min(1, { message: i18n.t("auth.password_is_required") }),
});

type LoginForm = z.infer<typeof loginFormSchema>;

const LoginForm = () => {
  const { t } = useTranslation();
  const { mutate: login, error: loginError } = useLogin();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginForm>({
    resolver: zodResolver(loginFormSchema),
  });

  const errorMessageKey = (
    loginError?.error.code ? `auth.${loginError?.error.code}` : `error.general`
  ) as errorMessageKeys;

  const onSubmit: SubmitHandler<LoginForm> = (data) => {
    login(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col justify-between gap-y-20 px-6 py-14 md:px-24"
    >
      <TitleBlock showWelcome={true} />
      <div className="flex flex-col gap-y-2.5">
        <div className="flex h-16 flex-row gap-x-2">
          <Heading
            heading={t("auth.log_in")}
            left={
              <Circle>
                <HandWaving size={24} className="text-tertiary-800" />
              </Circle>
            }
          ></Heading>
        </div>
        <div className="flex flex-col gap-y-8">
          <Input
            error={errors.email?.message}
            left={<EnvelopeSimpleOpen size={20} />}
            placeholder={t("auth.email_example")}
            id="email"
            label={t("auth.email")}
            required
            {...register("email")}
          />
          <PasswordInput
            type="password"
            id="password"
            label={t("auth.password")}
            required
            error={errors.password?.message}
            {...register("password")}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-2.5">
        <Alert variant="error" hidden={!loginError}>
          {t(errorMessageKey)}
        </Alert>
        <Button size="lg" variant="filled" type="submit">
          {t("auth.log_in")}
        </Button>
        <Button size="lg" variant="transparent" className="bg-white">
          <Link to={ROUTES.passwordForgot}>
            {t("auth.forgot_your_password_click_here")}
          </Link>
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
