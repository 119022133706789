import {
  CheckCircle,
  Info,
  WarningCircle,
  XCircle,
} from "@phosphor-icons/react";

const toastIcons = {
  info: <Info className="text-blue-400 h-5 w-5" />,
  success: <CheckCircle className="text-green-400 h-5 w-5" />,
  warning: <WarningCircle className="text-yellow-400 h-5 w-5" />,
  error: <XCircle className="text-red-400 h-5 w-5" />,
};

export default toastIcons;
