import { useEffect } from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";

import { usePartners } from "@/hooks";
import { usePagination } from "@/hooks/usePagination";
import type { Partner } from "@/types";
import { Pagination, Table } from "@/ui";
import { partnersTableColumns } from "./PartnersTableColumns";

export const PartnersTable = ({ searchQuery }: { searchQuery: string }) => {
  const navigate = useNavigate();
  const { pagination, setPageIndex, onPaginationChange } = usePagination();
  const { useAllPartners } = usePartners();
  const { data: partnersData, isLoading } = useAllPartners(
    pagination.pageIndex + 1,
    searchQuery,
  );

  useEffect(() => {
    setPageIndex(0);
  }, [searchQuery]);

  const table = useReactTable({
    data: partnersData?.data ?? [],
    columns: partnersTableColumns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: partnersData?.pagination?.totalPages ?? 1,
    state: {
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: partnersData?.pagination?.perPage ?? 10,
      },
    },
    onPaginationChange,
  });

  const handleRowClick = (row: Partner) => {
    navigate(`/partners/${row.id}`);
  };

  return (
    <div>
      <Table
        table={table}
        handleRowClick={handleRowClick}
        isLoading={isLoading}
      />
      <div className="flex justify-end rounded-b-2xl border border-neutral-400">
        <Pagination
          currentPage={pagination.pageIndex + 1}
          totalPages={partnersData?.pagination?.totalPages ?? 1}
          onPageChange={(page) => setPageIndex(page - 1)}
        />
      </div>
    </div>
  );
};
