import { ResetEmailSent as ResetEmailSentComponent } from "@/components";
import { CenteredContainerLayout } from "@/layout";

export const ResetEmailSent = () => {
  return (
    <div>
      <CenteredContainerLayout className="bg-primary-100 md:w-[550px]">
        <ResetEmailSentComponent />
      </CenteredContainerLayout>
    </div>
  );
};
