import type { PracticeType } from "@/types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

export const getPracticeTypes = async (): Promise<
  ServiceResponse<PracticeType[]>
> => {
  const response =
    await privateAPI.get<ServiceResponse<PracticeType[]>>("/practice-types");
  return response.data;
};

export const addPracticeType = async (practiceType: {
  name: string;
}): Promise<PracticeType> => {
  const response = await privateAPI.post<ServiceResponse<PracticeType>>(
    `/practice-types`,
    practiceType,
  );

  return response.data.data;
};
