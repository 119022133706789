import type { CommonTypes } from "@/types";
import { tw } from "@/utils";

interface HeadingProps extends CommonTypes {
  heading?: React.ReactNode;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  left?: React.ReactNode;
  containerClassName?: string;
  className?: string;
}

export const Heading = ({
  level = 3,
  heading,
  left,
  containerClassName,
  className,
}: HeadingProps) => {
  const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;

  return (
    <div className={tw("flex flex-row gap-x-2", containerClassName)}>
      {!!left && <div className="pointer-events-none">{left}</div>}
      <HeadingTag
        className={tw("font-display text-2xl text-primary-950", className)}
      >
        {heading}
      </HeadingTag>
    </div>
  );
};
