import { useQuery } from "@tanstack/react-query";

import { getInterventionTypes } from "@/api";

export const interventionTypesQueryKeys = {
  useInterventionTypes: () => ["interventionTypes", "getInterventionTypes"],
};

export const useInterventionTypes = () => {
  const useAllInterventionTypes = () =>
    useQuery({
      queryFn: () => getInterventionTypes(),
      queryKey: interventionTypesQueryKeys.useInterventionTypes(),
    });

  return {
    useAllInterventionTypes,
  };
};
