import { t } from "i18next";

import { PasswordForm } from "@/components";
import { CenteredContainerLayout } from "@/layout";

export const PasswordCreate = () => {
  return (
    <div>
      <CenteredContainerLayout className="md:w-[683px]">
        <PasswordForm heading={t("auth.create_password")} isReset={false} />
      </CenteredContainerLayout>
    </div>
  );
};
