import { zodResolver } from "@hookform/resolvers/zod";
import {
  At,
  EnvelopeSimpleOpen,
  Lightbulb,
  MapPin,
  Spinner,
  User,
} from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import type { z } from "zod";

import { partnersQueryKeys, useMedia, usePartners } from "@/hooks";
import i18n from "@/i18n";
import { partnerSchema } from "@/types";
import {
  Button,
  ControlledDropzone,
  errorToast,
  Input,
  RadioButtonGroup,
  TextArea,
} from "@/ui";
import {
  bytesToMegabytes,
  getFileExtension,
  MAX_UPLOAD_SIZE_FOR,
} from "@/utils";

export type PartnerFormValues = z.infer<typeof partnerSchema>;

export const AddPartnerForm = ({ onClose }: { onClose: () => void }) => {
  const { useAddPartner } = usePartners();
  const { useUpload } = useMedia();
  const { mutateAsync: addPartner, isPending: isPendingAddPartnerMutation } =
    useAddPartner();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    control,
    watch,
  } = useForm<PartnerFormValues>({
    resolver: zodResolver(partnerSchema),
    mode: "all",
    defaultValues: {
      managed_by_partner: false,
    },
  });

  const queryClient = useQueryClient();

  const invalidateAndClose = () => {
    void queryClient.invalidateQueries({
      queryKey: partnersQueryKeys.usePartners(),
    });
    onClose();
  };

  const { upload, isUploading, fileHasUploaded } = useUpload();

  const onSubmit: SubmitHandler<PartnerFormValues> = async (partner) => {
    const { logo, ...partnerData } = partner;
    if (logo) {
      partnerData.extension = getFileExtension(logo.name);
    }
    try {
      const newPartner = await addPartner(partnerData);
      if (logo) {
        void upload(logo, `/partners/${newPartner.id}/logo/upload-url`);
      }
      invalidateAndClose();
    } catch (error) {
      errorToast(error);
    }
  };

  const radioItems = [
    { label: i18n.t("partner.partner_will_manage"), value: true },
    { label: i18n.t("partner.omf_will_manage"), value: false },
  ];

  const watchManagedByPartner = watch("managed_by_partner");

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-[540px] flex-col gap-y-8"
    >
      <div className="flex flex-col gap-y-8">
        <RadioButtonGroup
          name="managed_by_partner"
          control={control}
          items={radioItems}
          error={errors.managed_by_partner?.message}
          required
        />
        <Input
          id="name"
          label={t("partner.company_name")}
          placeholder={t("partner.name")}
          left={<User size={20} />}
          {...register("name")}
          error={errors.name?.message}
          required
        />

        <Input
          id="contact"
          label={t("partner.main_contact_email")}
          placeholder={t("partner.email")}
          left={<EnvelopeSimpleOpen size={20} />}
          {...register("contact")}
          error={errors.contact?.message}
          required
        />

        <TextArea
          id="description"
          label={t("partner.description")}
          placeholder={t("partner.description")}
          left={<Lightbulb size={20} />}
          {...register("description")}
          error={errors.description?.message}
          required
        />
        <div className="flex flex-col gap-y-2">
          <Input
            id="domain"
            label={t("partner.domain")}
            placeholder={t("partner.omf_domain")}
            left={<At size={20} />}
            {...register("domain")}
            error={errors.domain?.message}
            required
          />
          <div className="rounded-2xl border border-tertiary-400 bg-tertiary-50 p-3.5">
            <p className="text-primary-950">
              {t("partner.email_domain_connected_to_OMF")}
            </p>
          </div>
        </div>

        <Input
          id="country"
          label={t("partner.country")}
          placeholder={t("partner.country")}
          left={<MapPin size={20} />}
          {...register("country")}
          error={watchManagedByPartner ? errors.country?.message : ""}
          required={watchManagedByPartner}
        />
        <ControlledDropzone
          name="logo"
          control={control}
          loadedLabel={t("general.logo_uploaded")}
          label={t("partner.logo_here")}
          placeholder={`${t("general.maximum_size")}: ${bytesToMegabytes(
            MAX_UPLOAD_SIZE_FOR.LOGO,
          )} ${t("general.mb")}`}
          error={errors.logo?.message}
          state={
            fileHasUploaded ? "filled" : isUploading ? "loading" : "initial"
          }
          size="sm"
        />
      </div>

      <div className="flex justify-center gap-2">
        <Button
          onClick={() => onClose()}
          variant="outlined"
          className="flex-grow"
        >
          {t("general.cancel")}
        </Button>

        <Button type="submit" className="flex-grow" disabled={!isValid}>
          {isPendingAddPartnerMutation ? (
            <Spinner className="h-5 w-5" />
          ) : (
            t("general.save_changes")
          )}
        </Button>
      </div>
    </form>
  );
};
