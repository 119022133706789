import { useState } from "react";
import { MagnifyingGlass, UsersThree } from "@phosphor-icons/react";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { UsersTable } from "@/components";
import { useSearch } from "@/hooks";
import { ContentLayout } from "@/layout";
import { ROUTES } from "@/router";
import { Breadcrumb, Heading, SectionHeader, Switch } from "@/ui";
import { tw } from "@/utils";

export const Users = () => {
  const [usersCount, setUsersCount] = useState(0);
  const navigate = useNavigate();
  const { searchQuery, handleSearchChange } = useSearch("");

  const { control, watch } = useForm({
    defaultValues: {
      lockedQuery: false,
    },
  });

  const lockedQuery = watch("lockedQuery");

  const breadcrumbItems = [
    {
      label: t("general.users"),
      href: ROUTES.partners,
      icon: <UsersThree />,
    },
  ];

  return (
    <ContentLayout className="flex flex-col gap-y-6">
      <Breadcrumb
        items={breadcrumbItems}
        current={t("general.users")}
        onItemClick={(item) => {
          if (item.href) {
            navigate(item.href);
          }
        }}
      />
      <SectionHeader
        left={
          <Heading
            level={3}
            heading={`${t("general.users")} (${usersCount})`}
          />
        }
      />
      <div className="flex items-center gap-x-2">
        <div className="relative flex flex-row items-center">
          <div className="pointer-events-none absolute pl-3">
            {
              <MagnifyingGlass
                size={20}
                className={tw(
                  "text-neutral-950",
                  !searchQuery && "text-neutral-500",
                )}
              />
            }
          </div>

          <input
            type="text"
            id="search"
            placeholder={t("users.search_placeholder")}
            value={searchQuery}
            onChange={handleSearchChange}
            className="block w-[456px] rounded-xl border border-neutral-400 p-4 pl-10 text-sm text-neutral-950 placeholder:text-neutral-400 hover:ring-1 hover:ring-tertiary-600 focus:shadow-input focus:outline-none focus:ring-1 focus:ring-tertiary-600"
          />
        </div>

        <Switch name="lockedQuery" control={control} />
        <span className="text-lg font-semibold text-neutral-800">
          {t("users.show_locked_accounts")}
        </span>
      </div>
      <UsersTable
        searchQuery={searchQuery}
        lockedQuery={lockedQuery ? "" : 0}
        setUsersCount={setUsersCount}
      />
    </ContentLayout>
  );
};
