import type { CommonTypes } from "@/types";
import { Heading, SuccessIcon } from "@/ui";
import { tw } from "../utils/tw";

interface SuccessProps extends CommonTypes {
  heading: string;
}

export const Success = ({ children, className, heading }: SuccessProps) => {
  return (
    <div className="flex min-h-screen justify-center bg-primary-600 md:items-center">
      <div
        className={tw(
          "min-w-screen min-h-screen bg-primary-600 md:min-h-0 md:rounded-2xl md:bg-primary-950 md:px-0 md:shadow-md",
          className,
        )}
      >
        <div className="flex flex-col items-center justify-center py-52 md:px-11 md:py-20">
          <div className="flex flex-col items-center gap-y-14">
            <SuccessIcon />
            <div className="flex flex-col gap-y-4 px-5 md:w-[430px] md:px-11">
              <Heading
                level={2}
                className="text-center text-3xl text-primary-50"
                heading={heading}
              />
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
