import { Plus } from "@phosphor-icons/react";
import { t } from "i18next";

import { MODAL_ROUTES } from "@/router";
import { useNavigateModal } from "@/router/useNavigateModal";
import type { Course } from "@/types";
import { Button, Heading } from "@/ui";
import { CollaboratorsTable } from "./CollaboratorsTable";

interface CollaboratorsProps {
  partnerId?: string;
  course?: Course;
}

export const Collaborators = ({ partnerId, course }: CollaboratorsProps) => {
  const navigateModal = useNavigateModal();

  if (!course?.collaborators || !partnerId) return;

  return (
    <div className="px-6 py-4">
      <div className="flex items-center justify-between pb-4">
        <div className="flex flex-col gap-y-2">
          <Heading heading={t("course.collaborators")} />
        </div>
        <Button
          size="md"
          className="h-12"
          onClick={() =>
            navigateModal(MODAL_ROUTES.addCollaborator, {
              courseId: course.id,
              partnerId: partnerId,
            })
          }
        >
          <Plus size={20} />
          {t("course.new_collaborator")}
        </Button>
      </div>
      <CollaboratorsTable
        collaborators={course.collaborators}
        partnerId={partnerId}
        courseId={course.id}
      />
    </div>
  );
};
