import { z } from "zod";

import i18n from "@/i18n";
import type { User } from "./user";

export interface Batch {
  id: string;
  capacity: number;
  from_date: Date;
  to_date: Date;
  status: string;
  users_count: number;
  createdBy: User;
}

export interface BatchUser extends User {
  tokens: {
    last_login: Date;
  };
}

export const batchSchema = z.object({
  partner_id: z.string().min(1, i18n.t("batches_and_users.partner_required")),
  capacity: z
    .number({
      required_error: i18n.t("batches_and_users.capacity_required"),
      invalid_type_error: i18n.t("batches_and_users.capacity_required"),
    })
    .int()
    .positive(),
  from_date: z.string().date(),
  to_date: z.string().date(),
});

export type BatchFormValues = z.infer<typeof batchSchema>;
