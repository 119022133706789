import { Headphones } from "@phosphor-icons/react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { MeditationForm } from "@/components/Meditations";
import { ContentLayout } from "@/layout";
import { ROUTES } from "@/router";
import { Breadcrumb } from "@/ui";

export const AddMeditation = () => {
  const navigate = useNavigate();

  const breadcrumbItems = [
    {
      label: t("general.meditations"),
      href: ROUTES.meditations,
      icon: <Headphones />,
    },
    {
      label: t("meditations.add_new_meditation"),
      href: ROUTES.addMeditation,
    },
  ];

  return (
    <ContentLayout className="flex flex-col gap-y-6">
      <Breadcrumb
        items={breadcrumbItems}
        current={t("meditations.add_new_meditation")}
        onItemClick={(item) => {
          if (item.href) {
            navigate(item.href);
          }
        }}
      />
      <MeditationForm />
    </ContentLayout>
  );
};
