export function convertToOptions<T extends { id: string; name: string }>(
  data: T[] | undefined,
) {
  return data
    ? data.map((item: T) => ({
        value: item.id,
        label: item.name,
      }))
    : [];
}
