import { t } from "i18next";

import { errorResponseSchema } from "@/api/api.types";
import type { errorMessageKeys } from "@/types";
import { useToastStore } from "./toastStore";

export const validateError = (data: unknown) => {
  const parsedError = errorResponseSchema.safeParse(data);

  if (parsedError.success) return parsedError.data;

  return undefined;
};

export const errorToast = (error: unknown): void => {
  const { pushToast } = useToastStore.getState();

  const validatedError = validateError(error);

  if (validatedError) {
    const { fields, code } = validatedError.error;
    const errorMessageKey = (
      validatedError.error.code
        ? `error.${validatedError.error.code}`
        : `error.general`
    ) as errorMessageKeys;

    let errorMessage: errorMessageKeys = "error.general";

    if (fields) {
      const fieldKeys = Object.keys(fields);
      if (fieldKeys.length > 0) {
        const firstFieldKey = fieldKeys[0] ?? "";
        const firstFieldErrors = fields[firstFieldKey];
        if (firstFieldErrors && firstFieldErrors.length > 0) {
          errorMessage = firstFieldErrors[0] as errorMessageKeys;
        }
      }
    } else {
      errorMessage = `error.detail.${code ?? "general"}` as errorMessageKeys;
    }

    void pushToast({
      type: "error",
      title: t(errorMessageKey),
      message: t(errorMessage),
    });
  } else {
    console.error(error);
    void pushToast({
      type: "error",
      title: "Error",
      message: "Unknown error",
    });
  }
};
