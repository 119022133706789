import type { ComponentPropsWithoutRef, ForwardedRef } from "react";

import { forwardRef, tw } from "@/utils";

const BUTTON_VARIANT = {
  FILLED: "filled",
  OUTLINED: "outlined",
  TRANSPARENT: "transparent",
} as const;
type ButtonVariant = (typeof BUTTON_VARIANT)[keyof typeof BUTTON_VARIANT];

const SIZE = {
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
} as const;
type Size = (typeof SIZE)[keyof typeof SIZE];

export interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  variant?: ButtonVariant;
  size?: Size;
}

export const Button = forwardRef(
  (
    {
      type = "button",
      className,
      variant = "filled",
      size = "md",
      disabled = false,
      children,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <button
      ref={ref}
      type={type}
      className={tw(
        "border-transparent flex items-center justify-center gap-2.5 rounded-full border font-medium text-primary-50 focus:outline-none",

        !disabled && [
          variant === BUTTON_VARIANT.FILLED &&
            "bg-primary-950 text-primary-50 hover:bg-primary-700 focus:shadow-button focus:ring-primary-700",
          variant === BUTTON_VARIANT.OUTLINED &&
            "bg-neutral-50 text-primary-950 hover:bg-primary-100 focus:shadow-button focus:ring-primary-600",
          variant === BUTTON_VARIANT.TRANSPARENT &&
            "border-none font-semibold text-primary-950 hover:text-primary-900",
        ],

        disabled && [
          variant === BUTTON_VARIANT.FILLED && "bg-neutral-500",
          variant === BUTTON_VARIANT.OUTLINED && "bg-neutral-300",
          variant === BUTTON_VARIANT.TRANSPARENT &&
            "border-none text-neutral-300",
        ],

        size === SIZE.SMALL &&
          "px-4 py-2 text-sm font-normal leading-none tracking-wider",
        size === SIZE.MEDIUM && "px-5 py-3 text-sm font-normal",
        size === SIZE.LARGE && "px-6 py-4 font-semibold leading-6",

        className,
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  ),
);
