import { t } from "i18next";
import { z } from "zod";

import type {
  Collaborator,
  LearningSession,
  Material,
  Partner,
  Plan,
} from "@/types";
import {
  ACCEPTED_IMAGE_TYPES,
  ACCEPTED_VIDEO_TYPES,
  MAX_UPLOAD_SIZE_FOR,
} from "@/utils";
import { fileValidationRequired } from "@/utils/validations";

export const RESOURCE_TYPES = {
  ABOUT: "about",
  CONNECT: "connect",
  HEADER_INFORMATION: "header information",
  RESEARCH: "research",
  NO_TAG: "no tag",
} as const;

export type ResourceType = (typeof RESOURCE_TYPES)[keyof typeof RESOURCE_TYPES];

export interface AdditionalResource {
  title: string;
  description: string;
  url: string;
  action: string;
  type: ResourceType | null;
}

export const additionalResourceSchema = z.object({
  description: z
    .string()
    .min(1, t("course.additional_resources.description_required")),
  action: z
    .string()
    .min(1, t("course.additional_resources.button_text_required")),
  url: z.string().url(t("course.additional_resources.valid_url_required")),
  type: z
    .enum(Object.values(RESOURCE_TYPES) as [ResourceType, ...ResourceType[]])
    .nullable()
    .default(null),
  title: z.string().min(1, t("course.additional_resources.title_required")),
});

export type AddEditAdditionalResource = z.infer<
  typeof additionalResourceSchema
>;

export const additionalResourcesSchema = z.object({
  additionalResources: z.array(additionalResourceSchema),
});

export type AddEditAdditionalResources = z.infer<
  typeof additionalResourcesSchema
>;

export interface RelatedResource {
  name: string;
  duration: string;
  url: string;
}

export const relatedResourceSchema = z.object({
  name: z.string().min(1, t("course.related_resources.title_required")),
  duration: z.string().min(1, t("course.related_resources.duration_required")),
  url: z.string().url(t("course.related_resources.valid_url_required")),
});

export type AddEditRelatedResource = z.infer<typeof relatedResourceSchema>;

export const relatedResourcesSchema = z.object({
  relatedResources: z.array(relatedResourceSchema),
});

export type AddEditRelatedResources = z.infer<typeof relatedResourcesSchema>;

export interface Course {
  id: string;
  name: string;
  description: string;
  image: string | null;
  banner: string | null;
  relatedResources: RelatedResource[];
  additionalResources: AdditionalResource[];
  plans: Plan[];
  partner: Partner;
  collaborators: Collaborator[];
  sessions: LearningSession[];
  materials: Material[];
  published: boolean;
}

export const courseSchema = z.object({
  name: z.string().min(1, t("course.title_required")),
  description: z.string().min(1, t("course.description_required")),
  image: z
    .union([
      z.string(),
      fileValidationRequired(
        MAX_UPLOAD_SIZE_FOR.LOGO,
        ACCEPTED_IMAGE_TYPES,
        t("course.image_required"),
      ),
    ])
    .nullable(),
  image_extension: z.string().nullable().optional(),
  additional_resources: z.array(additionalResourceSchema).default([]),
  related_resources: z
    .array(
      z.object({
        name: z.string(),
        duration: z.string(),
        url: z.string().url(),
      }),
    )
    .default([]),
});

export type AddEditCourse = z.infer<typeof courseSchema>;

export const courseModuleSchema = z.object({
  title: z.string().min(1, "Titulo requerido"),
  description: z
    .string()
    .min(1, t("course.pre_course.video_description_required")),
  duration: z.string().min(1, t("course.pre_course.duration_required")),
  video: z
    .union([
      z.string(),
      fileValidationRequired(
        MAX_UPLOAD_SIZE_FOR.VIDEO,
        ACCEPTED_VIDEO_TYPES,
        t("course.pre_course.video_required"),
      ),
    ])
    .nullable()
    .optional(),
  extension: z.string().nullable().optional(),
});

export type AddEditCourseModule = z.infer<typeof courseModuleSchema>;
