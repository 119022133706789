import { useTranslation } from "react-i18next";

import { Success } from "@/components/Success";

export const PasswordCreatedSuccess = () => {
  const { t } = useTranslation();
  return (
    <Success heading={t("auth.password_successfully_created")}>
      <div className="text-center text-xl text-primary-50 md:text-primary-300">
        <p>{t("auth.you_can_now_log_with_your_new_password_and_email")}</p>
        <br />
        <p>{t("auth.keeping_account_secure")}</p>
      </div>
    </Success>
  );
};
