export const IMAGE_FILE_TYPES = {
  PNG: "image/png" as Blob["type"],
  JPEG: "image/jpeg" as Blob["type"],
  JPG: "image/jpg" as Blob["type"],
} as const;

export const ACCEPTED_IMAGE_TYPES: Blob["type"][] = [
  IMAGE_FILE_TYPES.PNG,
  IMAGE_FILE_TYPES.JPG,
  IMAGE_FILE_TYPES.JPEG,
];

export const VIDEO_FILE_TYPES = {
  MP4: "video/mp4" as Blob["type"],
} as const;

export const AUDIO_FILE_TYPES = {
  MP4: "video/mp4" as Blob["type"],
  MP3: "audio/mpeg" as Blob["type"],
} as const;

export const ACCEPTED_AUDIO_TYPES: Blob["type"][] = [
  AUDIO_FILE_TYPES.MP4,
  AUDIO_FILE_TYPES.MP3,
];

export const ACCEPTED_VIDEO_TYPES: Blob["type"][] = [VIDEO_FILE_TYPES.MP4];

export const MAX_UPLOAD_SIZE_FOR = {
  LOGO: 1024 * 1024 * 5, //5MB
  AUDIO: 1024 * 1024 * 150, //150MB
  VIDEO: 1024 * 1024 * 500, //500MB
};

export const ACCEPTED_IMAGE_TYPES_FORMAT = ["png", "jpg", "jpeg"];
export const ACCEPTED_AUDIO_TYPES_FORMAT = ["mp4", "mp3"];
export const ACCEPTED_VIDEO_TYPES_FORMAT = ["mp4"];

// cSpell:disable
export const SELF_PACED_COLLABORATORS = {
  WILLEM_KUYKEN: "Dr. Willem Kuyken",
  SHARON_HADLEY: "Dr. Sharon Grace Hadley",
  MAKEDA_MCKENZIE: "Makeda McKenzie",
} as const;
// cSpell:enable
