import { useState } from "react";

export const useSearch = (initialValue = "") => {
  const [searchQuery, setSearchQuery] = useState(initialValue);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return {
    searchQuery,
    setSearchQuery,
    handleSearchChange,
  };
};
