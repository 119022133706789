import { useState } from "react";
import type { ComponentPropsWithoutRef, ForwardedRef, ReactNode } from "react";

import { forwardRef, tw } from "@/utils";
import { IconWrapper } from "../common";
import { Label } from "./Label";
import { Message } from "./Message";

export interface TextAreaProps extends ComponentPropsWithoutRef<"textarea"> {
  compact?: boolean;
  containerClassName?: string;
  error?: string | boolean;
  iconClassName?: string;
  id: string;
  label?: ReactNode;
  left?: ReactNode;
  message?: string;
  value?: string;
  characterLimit?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea = forwardRef(
  (
    {
      className,
      compact,
      containerClassName,
      error,
      id,
      label,
      left,
      message,
      required,
      value,
      characterLimit,
      onChange,
      ...rest
    }: TextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const initialCharCount = typeof value === "string" ? value.length : 0;
    const [charCount, setCharCount] = useState(initialCharCount);

    const handleInputChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
      const inputValue = event.target.value;
      if (characterLimit && inputValue.length > characterLimit) {
        setCharCount(characterLimit);
        event.target.value = inputValue.substring(0, characterLimit);
      } else {
        setCharCount(inputValue.length);
      }

      if (onChange) {
        onChange(event);
      }
    };

    return (
      <div
        className={tw(
          "relative flex flex-col gap-y-1 p-0.5",
          containerClassName,
        )}
      >
        {!!label && (
          <div className="flex flex-row gap-x-1">
            <Label
              htmlFor={id}
              label={label}
              className="text-lg font-semibold text-neutral-900"
            />
            {!!required && (
              <span className="text-lg font-semibold text-error-500">*</span>
            )}
          </div>
        )}
        <div
          className={tw(
            "flex flex-row items-center",
            !!rest.disabled && "opacity-30",
          )}
        >
          {!!left && (
            <div className="pointer-events-none absolute pl-3">
              <IconWrapper
                size="sm"
                className={tw(
                  value ? "text-neutral-950" : "text-neutral-500",
                  !!error && "text-error-400",
                )}
              >
                {left}
              </IconWrapper>
            </div>
          )}
          <textarea
            ref={ref}
            id={id}
            {...rest}
            className={tw(
              "block w-full rounded-md border border-neutral-400 p-4 text-sm text-neutral-950 placeholder:text-neutral-400 hover:ring-1 hover:ring-tertiary-600 focus:shadow-input focus:outline-none focus:ring-1 focus:ring-tertiary-600",
              !!error &&
                "border-error-400 text-error-400 focus:border-error-400 focus:ring-error-50",
              !!left && "pl-10",
              !!rest.disabled && "border-neutral-500 bg-neutral-100",
              className,
            )}
            onChange={handleInputChange}
          />
        </div>
        <div className="mt-1 flex w-full justify-between">
          <div className="flex-grow">
            {(!!compact || !!message || !!error) && (
              <Message message={message} error={error} />
            )}
          </div>
          {characterLimit && (
            <div className="flex-shrink-0 text-sm text-neutral-500">
              {charCount} / {characterLimit}
            </div>
          )}
        </div>
      </div>
    );
  },
);
