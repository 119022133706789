import type { ApiPlansPayload, Plan, PlanName, PlanPayload } from "@/types";

export function mapPlansToAvailablePlanNames(plans: Plan[]): PlanName[] {
  const planNames: Set<PlanName> = new Set<PlanName>();

  for (const plan of plans) {
    if (plan.name === "practices_monthly") {
      planNames.add("practices_yearly");
    } else if (
      plan.name === "self_paced" ||
      plan.name === "courses" ||
      plan.name === "trainings" ||
      plan.name === "practices_yearly" ||
      plan.name === ""
    ) {
      planNames.add(plan.name);
    }
  }
  return Array.from(planNames);
}

export function transformPlanNamesToApiFormat(
  planNames: PlanName[],
): ApiPlansPayload {
  const plans: PlanPayload[] = planNames.map((name) => ({
    name,
  }));
  return { plans };
}
