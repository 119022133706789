import { useParams } from "react-router-dom";

import { SelfPacedCourse } from "@/components";
import { usePartners } from "@/hooks";

export const PartnerDetail = () => {
  const { partnerId } = useParams();
  const { usePartner, useSelfPacedCourse } = usePartners();
  const { data: partner, isLoading: isLoadingPartner } = usePartner(partnerId);
  const { data: course, isLoading: isLoadingCourse } = useSelfPacedCourse(
    partner?.id,
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <SelfPacedCourse
      partner={partner}
      course={course}
      isLoading={isLoadingPartner || isLoadingCourse}
    />
  );
};
