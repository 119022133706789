import type { QueryClient } from "@tanstack/react-query";

import type { ApiPlansPayload, ProfileInformation, User } from "@/types/models";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

const DOMAIN = "user";
const ALL = "all";

export const getUser = async (
  userId: User["id"] | undefined,
): Promise<User> => {
  const response = await privateAPI.get<ServiceResponse<User>>(
    `/users/${userId}`,
  );

  return response.data.data;
};

export const getUsers = async (
  page: number,
  searchQuery = "",
  locked: string | number = "",
): Promise<ServiceResponse<User[]>> => {
  const params: Record<string, string | number> = {
    page,
  };

  if (locked !== "") {
    params["filter[locked]"] = locked;
  }

  if (searchQuery) {
    params["filter[email]"] = searchQuery;
  }

  const response = await privateAPI.get<ServiceResponse<User[]>>(`/users`, {
    params,
  });

  return response.data;
};

interface CreateUserParams {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

interface ProfileParams {
  profile: ProfileInformation;
  userId: User["id"] | undefined;
}

interface LockUserParams {
  userId?: User["id"];
  locked?: boolean;
}

export const createUser = {
  mutation: async (params: CreateUserParams) => {
    const { passwordConfirmation, ...rest } = params;
    const response = await privateAPI.post<ServiceResponse<User>>("/users", {
      ...rest,
      password_confirmation: passwordConfirmation,
    });

    return response.data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN, "getUserQuery"] });
  },
};

export const updateUser = async ({
  profile,
  userId,
}: ProfileParams): Promise<User> => {
  if (!userId) return Promise.reject("No User found");
  const response = await privateAPI.put<ServiceResponse<User>>(
    `/users/${userId}`,
    {
      ...profile,
    },
  );

  return response.data.data;
};

export const patchUser = async ({ userId, locked }: LockUserParams) => {
  if (!userId) return Promise.reject("No User found");
  const response = await privateAPI.patch<ServiceResponse<User>>(
    `/users/${userId}`,
    {
      locked,
    },
  );

  return response.data.data;
};

interface AccessLevelParams {
  userId: User["id"] | undefined;
  accessLevels: ApiPlansPayload;
}

export const updateUserAccessLevels = async ({
  userId,
  accessLevels,
}: AccessLevelParams): Promise<User> => {
  if (!userId) return Promise.reject("No User found");
  const response = await privateAPI.put<ServiceResponse<User>>(
    `/users/${userId}/free-plans`,
    {
      ...accessLevels,
    },
  );

  return response.data.data;
};

export const deleteUser = {
  mutation: async (userId: User["id"]) => {
    await privateAPI.delete(`/users/${userId}`);
  },
  invalidates: (
    queryClient: QueryClient,
    { userId }: { userId: User["id"] },
  ) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN, ALL] });
    void queryClient.invalidateQueries({ queryKey: [DOMAIN, userId] });
  },
};
