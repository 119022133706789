import { useMutation, useQuery } from "@tanstack/react-query";

import { addCollaborator, getCollaborators, updateCollaborator } from "@/api";

export const collaboratorsQueryKeys = {
  useCollaborators: () => ["collaborators", "getCollaborators"],
};

export const useCollaborators = () => {
  const useAllCollaborators = () =>
    useQuery({
      queryFn: () => getCollaborators(),
      queryKey: collaboratorsQueryKeys.useCollaborators(),
    });

  const useAddCollaborator = () =>
    useMutation({
      mutationFn: addCollaborator,
    });

  const useUpdateCollaborator = () =>
    useMutation({ mutationFn: updateCollaborator });

  return {
    useAllCollaborators,
    useAddCollaborator,
    useUpdateCollaborator,
  };
};
