import { useTranslation } from "react-i18next";

import { Success } from "@/components/Success";

export const VerificationSuccess = () => {
  const { t } = useTranslation();
  return (
    <Success heading={t("profile.verification_success")}>
      <div className="text-center text-xl text-primary-50 md:text-primary-300">
        <p>{t("profile.you_can_now_log_with_your_credentials")}</p>
        <br />
        <p>{t("profile.keeping_account_secure")}</p>
      </div>
    </Success>
  );
};
