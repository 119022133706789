import { z } from "zod";

import i18n from "@/i18n";

export interface PracticeType {
  id: string;
  name: string;
}

export const practiceTypeSchema = z.object({
  name: z.string().min(1, i18n.t("meditations.practice_type_name_required")),
});

export type AddEditPracticeType = z.infer<typeof practiceTypeSchema>;
