import { z } from "zod";

import i18n from "@/i18n";
import { ACCEPTED_IMAGE_TYPES, MAX_UPLOAD_SIZE_FOR } from "@/utils";
import { domainValidation, fileValidation } from "@/utils/validations";

export interface Partner {
  id?: string;
  name: string;
  description: string;
  extension?: string | null;
  logo?: string | null;
  contact: string;
  generatedCodes?: number;
  connectedUsers?: number;
  domain: string;
  country: string | null;
}

export const partnerSchema = z
  .object({
    name: z.string().min(1, i18n.t("partner.company_name_required")),
    contact: z
      .string()
      .min(1, { message: i18n.t("partner.main_contact_email_required") })
      .email({ message: i18n.t("auth.email_is_not_valid") }),
    description: z.string().min(1, i18n.t("partner.description_required")),
    domain: domainValidation,
    country: z.string().nullable().optional().default(null),
    managed_by_partner: z.boolean().default(false),
    extension: z.string().nullable().optional(),
    logo: fileValidation(MAX_UPLOAD_SIZE_FOR.LOGO, ACCEPTED_IMAGE_TYPES),
  })
  .refine((data) => (data.managed_by_partner && !data.country ? false : true), {
    message: i18n.t("partner.country_required"),
    path: ["country"],
  });

export const editPartnerSchema = z.object({
  name: z.string().min(1, i18n.t("partner.company_name_required")),
  contact: z
    .string()
    .min(1, { message: i18n.t("partner.main_contact_email_required") })
    .email({ message: i18n.t("auth.email_is_not_valid") }),
  description: z.string().min(1, i18n.t("partner.description_required")),
  domain: domainValidation,
  country: z.string().min(1, i18n.t("partner.country_required")),
  extension: z.string().nullable().optional(),
  logo: fileValidation(MAX_UPLOAD_SIZE_FOR.LOGO, ACCEPTED_IMAGE_TYPES),
});

export type EditPartner = z.infer<typeof editPartnerSchema>;
