import type { InterventionType } from "@/types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

export const getInterventionTypes = async (): Promise<
  ServiceResponse<InterventionType[]>
> => {
  const response = await privateAPI.get<ServiceResponse<InterventionType[]>>(
    "/intervention-types",
  );
  return response.data;
};
