import { useState } from "react";
import { Headphones, Image, X } from "@phosphor-icons/react";
import { t } from "i18next";
import { useLocation } from "react-router-dom";

import type { ModalProps } from "@/shared.types";
import { Circle, Heading, Loader, Modal } from "@/ui";
import {
  ACCEPTED_AUDIO_TYPES_FORMAT,
  ACCEPTED_IMAGE_TYPES_FORMAT,
  ACCEPTED_VIDEO_TYPES_FORMAT,
  getFileExtensionFromUrl,
  tw,
} from "@/utils";

interface LocationState {
  preview: string;
  caption?: string;
}

export const PreviewFileModal = ({ show, onClose }: ModalProps) => {
  const location = useLocation();
  const { preview, caption } = location.state as LocationState;
  const [isLoading, setIsLoading] = useState(true);

  const fileExtension = getFileExtensionFromUrl(preview);
  const isImage = ACCEPTED_IMAGE_TYPES_FORMAT.includes(fileExtension);
  const isAudio = ACCEPTED_AUDIO_TYPES_FORMAT.includes(fileExtension);
  const isVideo = ACCEPTED_VIDEO_TYPES_FORMAT.includes(fileExtension);

  const renderPreview = () => {
    if (isImage) {
      return (
        <img
          src={preview}
          alt=""
          className={tw(
            "max-h-[500px] max-w-full object-contain",
            isLoading ? "hidden" : "block",
          )}
          onLoad={() => setIsLoading(false)}
        />
      );
    } else if (isVideo) {
      return (
        <video controls width="600" onLoadedData={() => setIsLoading(false)}>
          <source src={preview} type="video/mp4" />
          <track
            kind="captions"
            src={caption}
            label={t("general.english_captions")}
          />
        </video>
      );
    } else if (isAudio) {
      return (
        <figure className={tw(isLoading ? "hidden" : "block")}>
          <audio
            controls
            src={preview}
            className="w-full"
            onLoadedData={() => setIsLoading(false)}
          >
            <track
              kind="captions"
              src={caption}
              label={t("general.english_captions")}
            />
            {t("general.audio_support_error")}
          </audio>
          {!caption && (
            <figcaption className="pt-10">
              {t("general.captions_not_available")}
            </figcaption>
          )}
        </figure>
      );
    } else {
      setIsLoading(false);
      return <p>{t("general.file_type_not_supported")}</p>;
    }
  };

  return (
    <Modal
      show={show}
      title={
        <div className="flex flex-col gap-y-2">
          <Heading
            containerClassName="items-center"
            heading={t("general.file_preview")}
            left={
              <Circle>
                {isImage ? (
                  <Image size={24} className="text-tertiary-800" />
                ) : (
                  <Headphones size={24} className="text-tertiary-800" />
                )}
              </Circle>
            }
          />
          <button
            onClick={onClose}
            aria-label="Close"
            className="absolute right-0 top-0 p-5"
          >
            <Circle className="bg-tertiary-50">
              <X size={24} />
            </Circle>
          </button>
        </div>
      }
      onClose={onClose}
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-row justify-center gap-x-2">
          {isLoading && <Loader />}
          {renderPreview()}
        </div>
      </div>
    </Modal>
  );
};
