import { t } from "i18next";

import { usePartners } from "@/hooks";
import type { Course } from "@/types";
import { Heading } from "@/ui";
import { PreCourseSession } from "./PreCourseSession";

interface SelfPacedCourseSessionsProps {
  partnerId?: string;
  course?: Course;
}

export const SelfPacedCourseSessions = ({
  partnerId,
}: SelfPacedCourseSessionsProps) => {
  const { useSelfPacedCourseModules } = usePartners();
  const { data: modules } = useSelfPacedCourseModules(partnerId);
  return (
    <div className="px-6 py-4">
      <div className="flex flex-row items-start justify-between">
        <div className="flex flex-col gap-y-2">
          <Heading heading={t("course.add_your_self_paced_course_session")} />
          <p className="pb-6">
            {t("course.make_sure_is_final_content_before")}
            <br />
            {t("course.make_sure_it_correlates")}
          </p>
        </div>
      </div>
      <div>
        {partnerId && modules && (
          <div className="flex flex-col gap-5">
            {modules.map((courseSession) => {
              if (courseSession.modules.length === 0) {
                return null;
              }
              return (
                <PreCourseSession
                  key={courseSession.id}
                  courseSession={courseSession}
                  partnerId={partnerId}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
