import { tw } from "@/utils";

interface InfoCardProps {
  label: string;
  value: string;
  className?: string;
}

export const InfoCard = ({ label, value, className }: InfoCardProps) => {
  return (
    <div className={tw("rounded-2xl bg-tertiary-50 p-4", className)}>
      <h1 className="">{label}</h1>
      <p className="font-bold">{value}</p>
    </div>
  );
};
