import { useMutation, useQuery } from "@tanstack/react-query";

import {
  addMeditation,
  getMeditation,
  getMeditations,
  updateMeditation,
} from "@/api";
import type { Meditation } from "@/types";
import { useDebouncedQuery } from "./useDebouncedQuery";

export const meditationsQueryKeys = {
  useMeditation: (meditationId?: Meditation["id"]) => [
    "meditations",
    "getMeditation",
    meditationId,
  ],
  useManagedPractice: () => ["practices", "getPractices"],
  useMeditations: () => ["useMeditations", "getMeditation"],
  useMeditationsPage: (page: number, searchQuery: string) => [
    "useMeditations",
    "getMeditation",
    page,
    searchQuery,
  ],
};

export const useMeditations = () => {
  const useAllMeditations = (page: number, searchQuery: string) =>
    useDebouncedQuery({
      queryFn: () => getMeditations(page, searchQuery),
      queryKey: meditationsQueryKeys.useMeditationsPage(page, searchQuery),
    });
  const useMeditation = (meditationId?: Meditation["id"]) =>
    useQuery({
      queryFn: () => getMeditation(meditationId),
      queryKey: meditationsQueryKeys.useMeditation(meditationId),
      enabled: !!meditationId,
    });
  const useAddMeditation = () => useMutation({ mutationFn: addMeditation });
  const useUpdateMeditation = () =>
    useMutation({ mutationFn: updateMeditation });

  return {
    useAllMeditations,
    useMeditation,
    useAddMeditation,
    useUpdateMeditation,
  };
};
