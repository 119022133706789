import { useRef } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Transition, TransitionGroup } from "react-transition-group";

import {
  AddBatchModal,
  AddCollaboratorModal,
  AddPartnerModal,
  AddPracticeTypeModal,
  AddTeacherModal,
  CourseSectionExampleModal,
  EditBatchModal,
  EditCollaboratorModal,
  EditPartnerModal,
  EditProfileModal,
  ExampleModal,
  LockUserModal,
  ManageAccessLevelModal,
  ManageUserBundleAccessModal,
  PreviewFileModal,
  RemoveCollaboratorModal,
} from "@/modals";
import { MODAL_ROUTES } from "./routes";

export const ModalRouter = ({ showModal }: { showModal: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const nodeRef = useRef(null);

  return (
    <TransitionGroup>
      <Transition key={location.pathname} timeout={600} nodeRef={nodeRef}>
        {(state) => {
          const show = state !== "exited" && state !== "exiting";

          if (!showModal) {
            return null;
          }

          const goBack = () => state !== "exiting" && navigate(-1);

          return (
            <div ref={nodeRef}>
              <Routes location={location}>
                <Route
                  path={`${MODAL_ROUTES.exampleModal}`}
                  element={<ExampleModal show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.editProfile}`}
                  element={<EditProfileModal show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.addPartner}`}
                  element={<AddPartnerModal show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.editPartner}`}
                  element={<EditPartnerModal show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.addBatch}`}
                  element={<AddBatchModal show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.editBatch}`}
                  element={<EditBatchModal show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.courseSectionExamples}`}
                  element={
                    <CourseSectionExampleModal show={show} onClose={goBack} />
                  }
                />
                <Route
                  path={`${MODAL_ROUTES.manageUserAccessLevel}`}
                  element={
                    <ManageAccessLevelModal show={show} onClose={goBack} />
                  }
                />
                <Route
                  path={`${MODAL_ROUTES.lockUser}`}
                  element={<LockUserModal show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.manageUserBundleAccess}`}
                  element={
                    <ManageUserBundleAccessModal show={show} onClose={goBack} />
                  }
                />
                <Route
                  path={`${MODAL_ROUTES.previewFile}`}
                  element={<PreviewFileModal show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.addCollaborator}`}
                  element={
                    <AddCollaboratorModal show={show} onClose={goBack} />
                  }
                />
                <Route
                  path={`${MODAL_ROUTES.editCollaborator}`}
                  element={
                    <EditCollaboratorModal show={show} onClose={goBack} />
                  }
                />
                <Route
                  path={`${MODAL_ROUTES.removeCollaborator}`}
                  element={
                    <RemoveCollaboratorModal show={show} onClose={goBack} />
                  }
                />
                <Route
                  path={`${MODAL_ROUTES.addTeacher}`}
                  element={<AddTeacherModal show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.addPracticeType}`}
                  element={
                    <AddPracticeTypeModal show={show} onClose={goBack} />
                  }
                />
              </Routes>
            </div>
          );
        }}
      </Transition>
    </TransitionGroup>
  );
};
