import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import type { Path } from "react-hook-form";
import { useForm } from "react-hook-form";

import { partnersQueryKeys, useCourses, useMedia } from "@/hooks";
import { courseSchema } from "@/types";
import type { AddEditCourse, Course } from "@/types";
import {
  ControlledDropzone,
  errorToast,
  Input,
  Loader,
  TextArea,
  useToastStore,
} from "@/ui";
import {
  bytesToMegabytes,
  getFileExtension,
  MAX_UPLOAD_SIZE_FOR,
} from "@/utils";
import { FormCard } from "./FormCard";

interface BasicInfoProps {
  partnerId: string;
  initialCourse: Course;
}

export const BasicInfo = ({ partnerId, initialCourse }: BasicInfoProps) => {
  const { useUpdateCourse } = useCourses();
  const { mutateAsync: updateCourse } = useUpdateCourse();
  const { useUpload } = useMedia();
  const {
    upload: uploadImage,
    isUploading: isUploadingImage,
    fileHasUploaded: imageHasUploaded,
  } = useUpload();
  const pushToast = useToastStore((state) => state.pushToast);

  const defaultInitialValues = {
    name: initialCourse.name,
    description: initialCourse.description,
    additional_resources: initialCourse.additionalResources,
    related_resources: initialCourse.relatedResources,
    image: initialCourse.image ?? undefined,
  };

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    control,
    watch,
    setValue,
  } = useForm<AddEditCourse>({
    resolver: zodResolver(courseSchema),
    mode: "all",
    defaultValues: defaultInitialValues,
    shouldFocusError: false,
  });

  const queryClient = useQueryClient();

  const invalidate = () => {
    void queryClient.invalidateQueries({
      queryKey: partnersQueryKeys.useSelfPacedCourse(partnerId),
      exact: false,
    });
    void pushToast({
      type: "success",
      title: t("course.updated_successfully_title"),
      message: t("course.updated_successfully_message"),
    });
  };

  const onSubmit = async (course: AddEditCourse) => {
    const { image, ...courseData } = course;

    const imageFile = image instanceof File ? image : null;

    if (imageFile) {
      courseData.image_extension = getFileExtension(imageFile.name);
    }

    try {
      await updateCourse({
        courseId: initialCourse.id,
        course: {
          ...courseData,
          additional_resources: initialCourse.additionalResources,
          related_resources: initialCourse.relatedResources,
          image: imageFile ?? image,
        },
      });

      if (imageFile) {
        await uploadImage(
          imageFile,
          `/courses/${initialCourse.id}/image/upload-url`,
        );
      }

      invalidate();
    } catch (error) {
      errorToast(error);
    }
  };

  const getDropzoneState = () => {
    if (imageHasUploaded) return "filled";
    if (isUploadingImage) return "loading";
    return "initial";
  };

  useEffect(() => {
    const userChanges = watch() as Partial<AddEditCourse>;

    Object.entries(userChanges).forEach(([key, value]) => {
      setValue(key as Path<AddEditCourse>, value);
    });
  }, [initialCourse, setValue, watch]);

  if (!initialCourse) {
    return <Loader />;
  }

  return (
    <FormCard
      title={t("course.basic_info")}
      label={t("course.section_required")}
      onSubmit={handleSubmit(onSubmit)}
      submitButtonText={t("course.save_this_block")}
      isDirty={isDirty}
    >
      <form className="flex flex-col gap-y-6">
        <ControlledDropzone
          name="image"
          control={control}
          loadedLabel={t("general.image_uploaded")}
          label={t("course.image_here")}
          placeholder={`${t("general.maximum_size")}: ${bytesToMegabytes(
            MAX_UPLOAD_SIZE_FOR.LOGO,
          )} ${t("general.mb")}`}
          error={errors.image?.message}
          state={getDropzoneState()}
          size="sm"
          compact={true}
          fileUrl={initialCourse.image ?? undefined}
          containerClassName="w-1/3 h-1/2"
        />
        <Input
          id="title"
          label={t("course.title")}
          placeholder={t("course.course_title")}
          {...register("name")}
          error={errors.name?.message}
          required
        />
        <TextArea
          id="description"
          label={t("course.description")}
          placeholder={t("course.course_description")}
          {...register("description")}
          error={errors.description?.message}
          required
          characterLimit={800}
          value={initialCourse.description}
          className="min-h-32"
        />
      </form>
    </FormCard>
  );
};
