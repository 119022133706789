import { Eye } from "@phosphor-icons/react";
import { t } from "i18next";
import { useLocation } from "react-router-dom";

import type { ModalProps } from "@/shared.types";
import { Button, Circle, Heading, Modal } from "@/ui";
import { tw } from "@/utils";
import About from "../../../assets/images/sectionExamples/about.png";
import Basic from "../../../assets/images/sectionExamples/basic.png";
import Help from "../../../assets/images/sectionExamples/help.png";
import Info from "../../../assets/images/sectionExamples/info.png";
import Related from "../../../assets/images/sectionExamples/related.png";
import Sessions from "../../../assets/images/sectionExamples/sessions.png";
import type { ViewAppSectionVariant } from "../../ui/ViewAppSectionButton";

interface LocationState {
  variant?: ViewAppSectionVariant;
}

const variantImageMap: Record<ViewAppSectionVariant, string> = {
  basic: Basic,
  help: Help,
  info: Info,
  related: Related,
  about: About,
  sessions: Sessions,
};

export const CourseSectionExampleModal = ({ show, onClose }: ModalProps) => {
  const location = useLocation();
  const state = location.state as LocationState;
  const variant: ViewAppSectionVariant | undefined = state?.variant;

  const imageSrc = variant ? variantImageMap[variant] : Basic;

  return (
    <Modal
      show={show}
      title={
        <div className="flex flex-col items-center gap-y-2 text-primary-950">
          <Heading
            containerClassName="items-center"
            heading={t(
              variant
                ? `course_section_examples.${variant}`
                : "course_section_examples.basic",
            )}
            left={
              <Circle>
                <Eye size={24} className="text-tertiary-800" />
              </Circle>
            }
          />
          <span className="text-center text-base text-neutral-700">
            {t("course_section_examples.please_note_this_is_just_reference")}
          </span>
        </div>
      }
      onClose={onClose}
      className="py-8"
    >
      <div className="flex flex-col items-center gap-y-6">
        <img
          src={imageSrc}
          alt="Section Example"
          className={tw(
            "w-[364px]",
            imageSrc === variantImageMap.sessions && "w-[732px]",
          )}
        />
        <Button onClick={() => onClose()} variant="outlined" className="w-60">
          {t("course_section_examples.close_example")}
        </Button>
      </div>
    </Modal>
  );
};
