import { House } from "@phosphor-icons/react";
import { createColumnHelper } from "@tanstack/react-table";

import i18n from "@/i18n";
import type { Meditation } from "@/types";
import Badge from "@/ui/Badge";
import { getRoundedMinutes } from "../../utils/dates";

const columnHelper = createColumnHelper<Meditation>();

export function getTableColumns() {
  const columns = [
    columnHelper.display({
      id: "meditationName",
      header: i18n.t("meditations.title"),
      cell: (info) => {
        const { name, featured } = info.row.original;
        return (
          <div className="flex items-center gap-x-2">
            {featured && (
              <House size={18} className="flex-none text-tertiary-600" />
            )}
            <span title={name} className="max-w-44 truncate">
              {name}
            </span>
          </div>
        );
      },
    }),
    columnHelper.accessor("interventionType.name", {
      header: i18n.t("meditations.intervention_type"),
      cell: (info) => <span>{info.getValue()} </span>,
    }),
    columnHelper.accessor("practiceType.name", {
      header: i18n.t("meditations.practice_type"),
      cell: (info) => <span>{info.getValue()} </span>,
    }),
    columnHelper.display({
      id: "themesAndBundles",
      header: i18n.t("meditations.themes_and_course"),
      cell: (info) => {
        const { themes, bundles } = info.row.original;
        const themeNames = themes.map((theme) => theme.name);
        const bundleNames = bundles.map((bundle) => bundle.name);
        const allNames = [...themeNames, ...bundleNames];
        return allNames.length > 0 ? <span>{allNames.join(" | ")}</span> : "-";
      },
    }),
    columnHelper.accessor("duration", {
      header: i18n.t("meditations.duration"),
      cell: (info) => <span>{getRoundedMinutes(info.getValue())} </span>,
    }),
    columnHelper.display({
      id: "published",
      header: i18n.t("meditations.status"),
      cell: (info) => {
        const published = info.row.original.published;
        return (
          <Badge
            text={
              published
                ? i18n.t("meditations.published")
                : i18n.t("meditations.draft")
            }
            className={
              published
                ? "bg-primary-800 text-primary-50"
                : "bg-neutral-300 text-neutral-950"
            }
          />
        );
      },
    }),
  ];

  return columns;
}
