import { CenteredContainerLayout } from "@/layout";
import { Heading, StickyHeader } from "@/ui";
import OMFLogo from "../../assets/images/OMFLogo.svg";

export const PrivacyPolicy = () => {
  return (
    <div className="">
      <StickyHeader className="bg-primary-600">
        <div className="flex justify-center px-28 py-6 md:justify-start">
          <img src={OMFLogo} alt="OMF Logo" />
        </div>
      </StickyHeader>
      <CenteredContainerLayout containerClassName="md:items-start md:py-10">
        <div className="flex w-full flex-col gap-y-4 px-6 py-7 md:w-[672px] md:px-12 md:py-9">
          <div>
            <Heading
              className="text-2xl"
              level={1}
              heading="Oxford Mindfulness App Privacy Policy"
            />
            <Heading
              className="font-sans text-xl font-medium"
              heading="Last Revised 5th August 2024"
            />
          </div>

          <div className="flex flex-col gap-y-2">
            <br />
            <Heading
              className="text-lg font-medium text-primary-600"
              heading="1. Introduction"
            />
            <p>
              This Privacy Policy describes how Oxford Mindfulness Foundation (
              <b>“Oxford Mindfulness,” “we,” “our,”</b> or <b>“us”</b>) collects
              and uses Personal Information about you through the use of our App
              between you and Oxford Mindfulness Foundation. We respect your
              privacy, and we are committed to protecting it through our
              compliance with this Privacy Policy. This Privacy Policy (our
              <b>“Privacy Policy”</b>) describes the types of information we may
              collect from you or that you may provide when you visit our App
              and use our services available through the platform (the{" "}
              <b>“Services”</b>) and our practices for collecting, using,
              maintaining, protecting, and disclosing that information. It does
              not apply to information collected by us offline or through any
              other means, including on any other website operated by Oxford
              Mindfulness Foundation or any third party; any third party,
              including through any application or content (including
              advertising) that may link to or be accessible from or on the Site
              or Services.
            </p>
            <p>
              In order to understand how we use your personal information, we
              need to explain Oxford Mindfulness Foundation’s legal organization
              as it affects how we use your personal information. For purposes
              of clarification, Oxford Mindfulness Foundation does not provide
              any medical or clinical services. As further described in our
              Policies and Procedures (available on our website
              oxfordmindfulness.org), Oxford Mindfulness Foundation operates as
              an information, teaching and training not for profit organization
              based in the UK, working internationally. We may use and disclose
              your personal information collected through the website and app,
              but how we use and disclose that information depends on whether it
              is health information or another type of personal information. For
              the purposes of the app, the only information we gather is your
              name, email address and country location. This information is only
              shared with the events team if you request materials and with the
              communications team to share information (which you can opt out of
              at any time). If you opt to be supported by one of the partners,
              we may share your name, email address and country location with
              them, permission for this will be sought in the app. Proceeding
              with the app confirmed you agree to be bound by those terms.
            </p>
            <p>
              Please read this policy carefully to understand our policies and
              practices regarding your information and how we will treat it. If
              you do not agree with our policies and practices, you should elect
              not to use our app and services. By accessing or using our app,
              you agree to this Privacy Policy. This Privacy Policy may change
              from time to time. Your continued use of our Site or Services
              after we make changes is deemed to be acceptance of those changes,
              so please check this Privacy Policy periodically for updates.
            </p>
            <br />
            <Heading
              className="text-lg font-medium text-primary-600"
              heading="2. Information We Collect Through Automatic Data Collection Technologies"
            />
            <p>
              ‍As you navigate through and interact with our app, we may use
              automatic data collection technologies to collect certain
              information about your equipment, browsing actions, and patterns,
              specifically: details of your access to and use of the app,
              including network traffic data, location data, logs, and other
              communication data and the resources that the end user accesses
              and uses on or through the app; information about your mobile
              device and internet connection, including the device’s unique
              device identifier, IP address, operating system, browser type,
              mobile network information, and the device’s telephone number;
              information stored on your mobile device, including in other
              applications. The information we collect automatically will
              include Personal Information (your email). This data will be used
              only to provide and improve our services, and will not be used or
              shared with third parties for marketing purposes; or real-time
              information about the location of your device. It helps us to
              improve our app and Services and to deliver a better and more
              personalised service by enabling us to: estimate our number of
              users and usage patterns; forecast future needs, functions, and
              uses of our app; better understand user satisfaction levels and
              experiences; store information about your preferences, allowing us
              to customise our app according to your individual interests;
              recognise you when you return to our app; in other ways to improve
              your experience and the quality of our app and Services.
            </p>
            <br />
            <Heading
              className="text-lg font-medium text-primary-600"
              heading="3. How We Use Your Information"
            />
            <p>
              We use your Personal Information for various purposes described
              below, including to provide our app services to you; provide
              services to you; provide you with information you request from us;
              enforce our rights arising from contracts; notify you about
              changes; and provide you with notices about your account. We use
              information that we collect about you or that you provide to us,
              including any Personal Information: to provide and improve our app
              services and administer transactions and orders for products and
              services ordered by you, to provide you with notices about your
              Oxford Mindfulness Foundation account; to contact you in response
              to a request; to administer surveys; to fulfil any other purpose
              for which you provide it; to carry out our obligations and enforce
              our rights arising from any contracts entered into between you and
              us, including for billing and collection; to notify you about
              changes to our app or Services; in any other way we may describe
              when you provide the information; and for any other purpose with
              your consent. We may also use your information to contact you
              about goods and services that may be of interest to you, including
              through newsletters. If you wish to opt-out of receiving such
              communications, you may do so at any time by contacting us at{" "}
              <b>admin@oxfordmindfulness.org</b>
            </p>
            <br />
            <Heading
              className="text-lg font-medium text-primary-600"
              heading="4. Disclosure of Your Information"
            />
            <p>
              We do not share, sell, or otherwise disclose your Personal
              Information for purposes other than those outlined in this Privacy
              Policy. We may disclose Personal Information that we collect, or
              you provide as described in this privacy policy: to affiliates,
              contractors, service providers, and other third parties we use to
              support our business. The services provided by these organisations
              include providing IT and infrastructure support services, and
              payment processing services; to a buyer or other successor in the
              event of a merger, divestiture, restructuring, reorganisation,
              dissolution, or other sale or transfer of some or all of our
              assets, whether as a going concern or as part of bankruptcy,
              liquidation, or similar proceeding, in which Personal Information
              held by Oxford Mindfulness Foundation about our app users are
              among the assets transferred; to fulfil the purpose for which you
              provide it. We may also disclose your Personal Information: to
              comply with any court order, law, or legal process, including to
              respond to any government or regulatory request; to enforce or
              apply our Terms of Use and other agreements, including for billing
              and collection purposes; and if we believe disclosure is necessary
              or appropriate to protect the rights, property, or safety of
              Oxford Mindfulness Foundation, our customers, or others. This
              includes exchanging information with other companies and
              organizations for the purposes of fraud protection and credit risk
              reduction.
            </p>
            <br />
            <Heading
              className="text-lg font-medium text-primary-600"
              heading="5. Choices About How We Use and Disclose Your Information"
            />
            <p>
              We offer you choices on how you can opt out of our use of tracking
              technology, disclosure of your Personal Information for our
              advertising to you, and other targeted advertising. We do not
              control the collection and use of your information collected by
              third parties. We strive to provide you with choices regarding the
              Personal Information you provide to us. We have created mechanisms
              to provide you with control over your Personal Information:
            </p>
            <br />
            <Heading
              className="text-lg font-medium text-primary-500"
              heading="Tracking Technologies and Advertising"
            />
            <p>
              You refuse all or some cookies, or to alert you when cookies are
              being sent. If you disable or refuse cookies, please note that
              some parts of our app or Services may then be inaccessible or not
              function properly.{" "}
            </p>
            <br />
            <Heading
              className="text-lg font-medium text-primary-600"
              heading="6. Your Rights Regarding Your Information and Accessing and Correcting Your Information"
            />
            <p>
              You may review and change your Personal Information by logging
              into the app and visiting your setting page. You may also notify
              us through the Contact Information below of any changes or errors
              in any Personal Information we have about you to ensure that it is
              complete, accurate, and as current as possible or to delete your
              account. We cannot delete your personal information except by also
              deleting your account with us. We may also not be able to
              accommodate your request if we believe it would violate any law or
              legal requirement or cause the information to be incorrect.
            </p>
            <br />
            <Heading
              className="text-lg font-medium text-primary-600"
              heading="7. Data Security"
            />
            <p>
              Information transmitted is not completely secure, but we do our
              best to protect your Personal Information (name, email address and
              country location). You are required to help protect your Personal
              Information and other information by keeping your password to our
              app confidential. We have implemented measures designed to secure
              your Personal Information from accidental loss and from
              unauthorized access, use, alteration, and disclosure. We use
              application timeouts and encryption technology for information
              sent and received by us. The safety and security of your
              information also depends on you. Where you have chosen a password
              for the use of our app, you are responsible for keeping this
              password confidential. We ask you not to share your password with
              anyone, nor share your account. Although we work diligently to try
              and protect your Personal Information, we cannot guarantee the
              security of your Personal Information transmitted to our app. Any
              transmission of Personal Information is at your own risk. We are
              not responsible for circumvention of any privacy settings or
              security measures contained on the Site or Services.
            </p>
            <br />
            <Heading
              className="text-base font-medium text-primary-500"
              heading="Categories of Personal Information Oxford Mindfulness collects"
            />
            <p>
              As described in more detail in other areas of our Privacy Policy,
              we collect and/or disclose Personal Information about you when you
              visit use our Site or Services, including information about you
              that you provide to us, and information that we automatically
              collect from you or your computer or device as you use our app.
              Personal information does not include information that is publicly
              available information.
            </p>
            <br />
            <Heading
              className="text-base font-medium text-primary-500"
              heading="Categories of Sources from which Oxford Mindfulness has collected Personal Information"
            />
            <p>
              We collect Personal Information directly from you, for example
              when you provide it to us to when you contact us to our Site or
              Services, for create an Oxford Mindfulness Foundation account; and
              indirectly from you automatically through your computer or device
              as you use our Site or Services.
            </p>
            <br />
            <Heading
              className="text-base font-medium text-primary-500"
              heading="Use of Personal Information"
            />
            <p>We do not sell your Personal Information.</p>
            <br />
            <Heading
              className="text-base font-medium text-primary-500"
              heading="Sharing Personal Information"
            />
            <p>
              Oxford Mindfulness Foundation may disclose your Personal
              Information to a third party for one or more business purposes.
              When we disclose Personal Information for a business purpose, such
              as to service providers (IT services etc), we enter a contract
              that describes the purpose and requires the recipient to both keep
              that Personal Information confidential and not use it for any
              purpose except performing the contract.
            </p>
            <br />
            <Heading
              className="text-base font-medium text-primary-500"
              heading="Deletion Request Rights"
            />
            <p>
              You have the right to request that Oxford Mindfulness Foundation
              delete your Personal Information that we collected from you and
              retained, subject to certain exceptions. Once we receive and
              confirm your verifiable consumer request, we will delete (and
              direct our service providers to delete) your Personal Information
              from our records, unless certain exceptions apply.
            </p>
            <br />
            <Heading
              className="text-base font-medium text-primary-600"
              heading="Exercising Access and Deletion Rights"
            />
            <p>
              To exercise the access and deletion rights described above, please
              submit a request to us by emailing us at
              <b>app@oxfordmindfulness.org</b>. Only you may make a request
              related to your Personal Information.
            </p>
            <br />
          </div>
        </div>
      </CenteredContainerLayout>
    </div>
  );
};
