import { Spinner, TrashSimple } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useLocation } from "react-router-dom";

import { partnersQueryKeys, useCourses } from "@/hooks";
import i18n from "@/i18n";
import type { ModalProps } from "@/shared.types";
import type { Collaborator } from "@/types";
import { Button, Circle, errorToast, Heading, Modal } from "@/ui";

interface LocationState {
  collaborator: Collaborator;
  partnerId: string;
}

export const RemoveCollaboratorModal = ({ show, onClose }: ModalProps) => {
  const location = useLocation();
  const state = location.state as LocationState;
  const collaborator = state.collaborator;
  const partnerId = state.partnerId;

  const { useRemoveCollaboratorFromCourse } = useCourses();
  const { mutateAsync: removeCollaboratorFromCourse, isPending } =
    useRemoveCollaboratorFromCourse();

  if (!state?.collaborator) {
    errorToast(i18n.t("error.general"));
  }

  const queryClient = useQueryClient();

  const invalidateAndClose = () => {
    void queryClient.invalidateQueries({
      queryKey: partnersQueryKeys.useSelfPacedCourse(partnerId),
    });
    onClose();
  };

  const handleRemoveButton = async () => {
    await removeCollaboratorFromCourse({
      collaboratorId: collaborator.id,
    });
    invalidateAndClose();
  };

  return (
    <Modal
      show={show}
      title={
        <div className="flex w-[492px] flex-col gap-y-7">
          <div className="flex flex-col gap-y-2">
            <Heading
              containerClassName="items-center"
              heading={t("collaborators.delete_collaborator")}
              left={
                <Circle>
                  <TrashSimple size={24} className="text-tertiary-800" />
                </Circle>
              }
            />
            <p className="text-base text-neutral-700">
              {t("collaborators.do_you_want_to_delete")}
              <span className="font-bold"> {collaborator.name}</span>?
              <br />
              {t("collaborators.if_you_do")}
            </p>
          </div>
        </div>
      }
      onClose={onClose}
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-row justify-center gap-x-2">
          <Button
            onClick={() => onClose()}
            className="w-1/2 flex-grow"
            variant="outlined"
          >
            {t("general.cancel")}
          </Button>
          <Button
            onClick={() => handleRemoveButton()}
            className="w-1/2 flex-grow"
            variant="filled"
          >
            {isPending ? (
              <Spinner className="h-5 w-5" />
            ) : (
              t("collaborators.yes_delete")
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
