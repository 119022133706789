import type { CommonTypes } from "@/types";
import { tw } from "../utils/tw";

export const ContentLayout = ({ children, className }: CommonTypes) => {
  return (
    <div className="p-8">
      <div className={tw("h-full w-full md:min-h-0", className)}>
        {children}
      </div>
    </div>
  );
};
