import { useEffect } from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";

import { useMeditations } from "@/hooks";
import { usePagination } from "@/hooks/usePagination";
import type { Meditation } from "@/types";
import { Pagination, Table } from "@/ui";
import { getTableColumns } from "./MeditationsTableColumns";

interface MeditationsTableProps {
  searchQuery: string;
  selectedBundles: string[];
  approvedTab?: boolean;
  selectedRadioValue: string[];
}

export const MeditationsTable = ({ searchQuery }: MeditationsTableProps) => {
  const navigate = useNavigate();
  const { pagination, setPageIndex, onPaginationChange } = usePagination();
  const { useAllMeditations } = useMeditations();
  const { data: meditationsData, isLoading } = useAllMeditations(
    pagination.pageIndex + 1,
    searchQuery,
  );

  useEffect(() => {
    setPageIndex(0);
  }, [searchQuery]);

  const table = useReactTable({
    data: meditationsData?.data ?? [],
    columns: getTableColumns(),
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: meditationsData?.pagination?.totalPages ?? 1,
    state: {
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: meditationsData?.pagination?.perPage ?? 10,
      },
    },
    onPaginationChange,
  });

  const handleRowClick = (row: Meditation) => {
    navigate(`/meditations/${row.id}/edit`);
  };

  return (
    <div>
      <Table
        table={table}
        handleRowClick={handleRowClick}
        isLoading={isLoading}
      />
      <div className="flex justify-end rounded-b-2xl border border-neutral-400">
        <Pagination
          currentPage={pagination.pageIndex + 1}
          totalPages={meditationsData?.pagination?.totalPages ?? 1}
          onPageChange={(page) => setPageIndex(page - 1)}
        />
      </div>
    </div>
  );
};
