import { useState } from "react";
import { Headphones, Plus } from "@phosphor-icons/react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import {
  LiveSessions,
  Meditations as MeditationsComponent,
} from "@/components/Meditations";
import { ContentLayout } from "@/layout";
import { MODAL_ROUTES, ROUTES } from "@/router";
import { useNavigateModal } from "@/router/useNavigateModal";
import { useUserStore } from "@/stores";
import type { Tab } from "@/ui";
import { Breadcrumb, Button, Heading, SectionHeader, Tabs } from "@/ui";

export const Meditations = () => {
  const navigate = useNavigate();
  const navigateModal = useNavigateModal();
  const { user } = useUserStore();

  const tabs: [Tab, ...Tab[]] = [
    {
      component: <MeditationsComponent />,
      label: t("general.meditations"),
      roles: ["admin"],
    },
    {
      component: <LiveSessions />,
      label: t("meditations.live_sessions"),
      roles: ["admin"],
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const breadcrumbItems = [
    {
      label: t("general.meditations"),
      href: ROUTES.meditations,
      icon: <Headphones />,
    },
  ];

  return (
    <ContentLayout className="flex flex-col gap-y-6">
      <Breadcrumb
        items={breadcrumbItems}
        current={t("general.meditations")}
        onItemClick={(item) => {
          if (item.href) {
            navigate(item.href);
          }
        }}
      />
      <SectionHeader
        left={
          <Heading
            level={2}
            heading={t("general.meditations")}
            className="text-3xl"
          />
        }
        right={
          <div className="flex space-x-2">
            <Button
              variant="outlined"
              size="md"
              className="min-w-36"
              onClick={() => navigateModal(MODAL_ROUTES.addPracticeType)}
            >
              <Plus size={20} />
              {t("meditations.new_practice_type")}
            </Button>
            <Button
              variant="outlined"
              size="md"
              onClick={() => navigateModal(MODAL_ROUTES.addTeacher)}
              className="min-w-36"
            >
              <Plus size={20} />
              {t("meditations.new_teacher")}
            </Button>
            <Button
              size="md"
              onClick={() => navigate(ROUTES.addMeditation)}
              className="min-w-36"
            >
              <Plus size={20} />
              {t("meditations.new_meditation")}
            </Button>
          </div>
        }
      />
      <Tabs
        tabs={tabs}
        userRole={user?.role ?? "loggedOut"}
        currentLabel={currentTab?.label ?? ""}
        setCurrentTab={setCurrentTab}
      />
    </ContentLayout>
  );
};
