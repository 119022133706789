import { PencilSimple } from "@phosphor-icons/react";
import { t } from "i18next";

import { useUsers } from "@/hooks";
import { ContentLayout } from "@/layout";
import { MODAL_ROUTES } from "@/router";
import { useNavigateModal } from "@/router/useNavigateModal";
import { useUserStore } from "@/stores";
import { Button, Heading, SectionHeader } from "@/ui";

export const MyProfile = () => {
  const navigateModal = useNavigateModal();
  const { useUser } = useUsers();
  const loggedUser = useUserStore((state) => state.user);
  const { data: user } = useUser(loggedUser?.id);

  const profileFields = [
    { label: t("profile.first_name"), value: user?.first_name },
    { label: t("profile.last_name"), value: user?.last_name },
    { label: t("auth.email"), value: user?.email },
    { label: t("profile.location"), value: user?.country },
  ];

  return (
    <ContentLayout className="flex flex-col gap-y-5">
      <SectionHeader
        left={<Heading level={3} heading={t("profile.profile")} />}
        right={
          <Button
            size="md"
            onClick={() => navigateModal(MODAL_ROUTES.editProfile)}
          >
            <PencilSimple />
            {t("profile.edit_profile")}
          </Button>
        }
      />
      <div className="grid grid-cols-2 gap-5">
        {profileFields.map((field, index) => (
          <div
            key={index}
            className="flex flex-col items-start justify-center gap-y-2 rounded-2xl bg-primary-100 p-4 text-neutral-900"
          >
            <span className="font-normal">{field.label}</span>
            <span className="text-xl font-bold">{field.value}</span>
          </div>
        ))}
      </div>
    </ContentLayout>
  );
};
