import { useMutation } from "@tanstack/react-query";
import type { AxiosError } from "axios";

import type { ErrorResponse } from "./api.types";
import { publicAPI } from "./axios";

interface ForgotPasswordMailRequest {
  email: string;
}

interface ResetPasswordRequest {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

export function useForgotPasswordMail() {
  return useMutation<void, AxiosError, ForgotPasswordMailRequest>({
    mutationFn: async (
      forgotPasswordData: ForgotPasswordMailRequest,
    ): Promise<void> => {
      await publicAPI.post<void>("/passwords/reset/request", {
        ...forgotPasswordData,
      });
    },
  });
}

export function useResetPassword() {
  return useMutation<void, ErrorResponse, ResetPasswordRequest>({
    mutationFn: async (
      resetPasswordData: ResetPasswordRequest,
    ): Promise<void> => {
      await publicAPI.post<void>("/passwords/reset", resetPasswordData);
    },
  });
}
