import { Check, Spinner, User } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useLocation } from "react-router-dom";

import { userBundleAccessQueryKeys, useUserBundleAccess } from "@/hooks";
import i18n from "@/i18n";
import type { ModalProps } from "@/shared.types";
import type { Status, UserBundleAccess } from "@/types";
import {
  Button,
  Circle,
  errorToast,
  Heading,
  Modal,
  useToastStore,
} from "@/ui";

interface LocationState {
  userBundleAccess: UserBundleAccess;
}

export const ManageUserBundleAccessModal = ({ show, onClose }: ModalProps) => {
  const pushToast = useToastStore.getState().pushToast;
  const location = useLocation();
  const state = location.state as LocationState;

  if (!state?.userBundleAccess) {
    errorToast(i18n.t("error.general"));
  }

  const { id: userBundleAccessId } = state.userBundleAccess;
  const { first_name } = state.userBundleAccess.user;
  const { name } = state.userBundleAccess.bundle;

  const queryClient = useQueryClient();
  const { useUpdateUserBundleAccess } = useUserBundleAccess();
  const { mutate: updateUserBundleAccess, isPending } =
    useUpdateUserBundleAccess();

  const handleManageButton = (status: Status) => {
    updateUserBundleAccess(
      { userBundleAccessId, status },
      {
        onSuccess: () => {
          const declined = status === "declined";
          void queryClient.invalidateQueries({
            queryKey: userBundleAccessQueryKeys.useUserBundleAccess(),
            exact: false,
          });
          void pushToast({
            type: declined ? "warning" : "success",
            title: declined
              ? i18n.t("materials.request_successfully_declined")
              : i18n.t("materials.request_successfully_approved"),
            icon: <Check size={18} />,
          });
          onClose();
        },
        onError: (err) => {
          errorToast(err);
        },
      },
    );
  };

  return (
    <Modal
      show={show}
      title={
        <div className="flex w-[492px] flex-col gap-y-7">
          <Heading
            containerClassName="items-center"
            heading={`${t("materials.manage")} ${first_name}’s ${t("materials.access")}`}
            left={
              <Circle>
                <Check size={24} className="text-tertiary-800" />
              </Circle>
            }
          />
          <p className="text-lg font-normal text-primary-950">
            {`${t("materials.do_you_want_to_approve")} ${first_name}’s ${t("materials.request_to")} `}
            <b>{name}</b>
            <span>?</span>
          </p>
          <div className="absolute right-0 top-0 px-6 py-4">
            <User size={24} className="text-tertiary-950" />
          </div>
        </div>
      }
      onClose={onClose}
    >
      <div className="flex flex-row justify-center gap-x-2">
        <Button
          onClick={() => handleManageButton("declined")}
          className="flex-grow"
          variant="outlined"
        >
          {isPending ? <Spinner className="h-5 w-5" /> : t("materials.decline")}
        </Button>
        <Button
          onClick={() => handleManageButton("pending payment")}
          className="flex-grow"
          variant="filled"
        >
          {isPending ? <Spinner className="h-5 w-5" /> : t("materials.approve")}
        </Button>
      </div>
    </Modal>
  );
};
