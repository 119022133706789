import type { ForwardedRef } from "react";

import { forwardRef, tw } from "@/utils";

interface RadioButtonProps {
  id: string;
  label: string;
  value: string | number | boolean | string[];
  checked: boolean;
  onChange: () => void;
  error?: string;
}

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    { id, label, value, checked, onChange, error, ...rest },
    ref: ForwardedRef<HTMLInputElement>,
  ) => (
    <div>
      <input
        ref={ref}
        type="radio"
        id={id}
        value={value.toString()}
        checked={checked}
        onChange={onChange}
        className="absolute h-0 w-0 opacity-0"
        {...rest}
      />

      <label htmlFor={id} className="flex items-center gap-x-2">
        <span
          className={tw(
            "h-4 w-4 rounded-full border border-primary-700 bg-white",
            !!checked && "border-4",
            !!error && "bg-error-500",
          )}
        ></span>
        <span>{label}</span>
      </label>
    </div>
  ),
);
