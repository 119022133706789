import { useState } from "react";
import type { PaginationState, Updater } from "@tanstack/react-table";

type PaginationUpdater = Updater<PaginationState>;

export const usePagination = (initialPageSize = 10) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: initialPageSize,
  });

  const setPageIndex = (pageIndex: number) => {
    setPagination((prev) => ({ ...prev, pageIndex }));
  };

  const setPageSize = (pageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize }));
  };

  const onPaginationChange = (updater: PaginationUpdater) => {
    const newPageIndex =
      typeof updater === "function"
        ? updater(pagination).pageIndex
        : updater.pageIndex;
    setPageIndex(newPageIndex);
  };

  return {
    pagination,
    setPageIndex,
    setPageSize,
    onPaginationChange,
  };
};
