import { tw } from "@/utils";
import type { SVGProps } from "../../shared.types";
import type { IconProps } from "../../types/icon";

export const icons = {
  SpinnerIcon: ({ className, ...props }: SVGProps) => (
    <div role="status">
      <svg
        aria-hidden="true"
        className={tw(
          "fill-blue-600 text-gray-200 dark:text-gray-600 mr-2 h-8 w-8 animate-spin",
          className,
        )}
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  ),
  PracticesIcon: ({ ...props }: IconProps) => (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5548 14.0436C17.5548 17.1114 15.0678 19.5984 11.9999 19.5984C8.93211 19.5984 6.44513 17.1114 6.44513 14.0436C6.44513 10.9757 8.93211 8.48877 11.9999 8.48877C15.0678 8.48877 17.5548 10.9757 17.5548 14.0436Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.61471 13.2C2.66169 8.05675 6.84562 3.90186 12 3.90186C17.1444 3.90186 21.3221 8.04072 21.3849 13.1702C21.5683 13.2127 21.7481 13.2705 21.9226 13.3433C22.3303 13.5135 22.7004 13.7624 23.012 14.0756C23.3235 14.3889 23.5703 14.7605 23.7382 15.1691C23.9062 15.5778 23.992 16.0155 23.9907 16.4573C23.9895 16.8991 23.9013 17.3364 23.731 17.7441C23.5608 18.1518 23.312 18.522 22.9987 18.8335C22.6854 19.145 22.3139 19.3918 21.9052 19.5598C21.7364 19.6291 21.5626 19.6845 21.3857 19.7255V19.8129H19.8857V13.2875C19.8857 8.93239 16.3551 5.40186 12 5.40186C7.64485 5.40186 4.11431 8.93239 4.11431 13.2875V19.8419L3.38318 19.8419C3.3764 19.8419 3.36961 19.8419 3.36283 19.8419H2.61431V19.7551C2.43742 19.7141 2.26373 19.6588 2.095 19.5894C1.68635 19.4215 1.31478 19.1747 1.0015 18.8632C0.688221 18.5516 0.439372 18.1815 0.26916 17.7737C0.0989479 17.366 0.0107064 16.9288 0.00947355 16.487C0.00824069 16.0452 0.0940409 15.6075 0.261975 15.1988C0.429909 14.7902 0.676688 14.4186 0.988222 14.1053C1.29976 13.792 1.66995 13.5432 2.07765 13.373C2.25193 13.3002 2.43159 13.2424 2.61471 13.2Z"
        fill="currentColor"
      />
    </svg>
  ),
  MultimediaIcon: ({ ...props }: IconProps) => (
    <svg
      width="70"
      height="52"
      viewBox="0 0 70 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.05172 8.74548L17.2131 6.88525V40.7377L12.3018 41.7717C9.01306 42.464 5.79705 40.3203 5.17081 37.0184L1.14319 15.7818C0.515988 12.4748 2.73148 9.29885 6.05172 8.74548Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M63.9483 8.74547L52.7869 6.88524V40.7377L57.6982 41.7717C60.9869 42.464 64.203 40.3203 64.8292 37.0184L68.8568 15.7818C69.484 12.4748 67.2685 9.29884 63.9483 8.74547Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <g filter="url(#filter0_dd_562_1805)">
        <rect
          x="17.0656"
          y="1"
          width="35.8689"
          height="42.7541"
          rx="5"
          stroke="currentColor"
          strokeWidth="2"
          shapeRendering="crispEdges"
        />
      </g>
      <path
        d="M47.9344 43.7541H22.0655C19.3041 43.7541 17.0656 41.5155 17.0656 38.7541L17.0656 35.293L29.4724 22.1452L38.9825 33.0891C39.7832 34.0105 41.2154 34.0059 42.0102 33.0794L47.2456 26.9765L52.9344 33.0978V38.7541C52.9344 41.5155 50.6958 43.7541 47.9344 43.7541Z"
        fill="currentFill"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle
        cx="39.5901"
        cy="14.3443"
        r="4.16393"
        fill="currentFill"
        stroke="currentColor"
        strokeWidth="2"
      />
      <defs>
        <filter
          id="filter0_dd_562_1805"
          x="13.0656"
          y="0"
          width="43.8688"
          height="51.7541"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_562_1805"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_562_1805"
            result="effect2_dropShadow_562_1805"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_562_1805"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
};

export const IconWrapper = ({
  size = "md",
  className,
  style,
  children,
}: {
  size?: "sm" | "md" | "lg" | "xl";
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) => (
  <div
    className={tw(
      "item-center flex flex-row",
      size === "sm" && "h-5 w-5",
      size === "md" && "h-6 w-6",
      size === "lg" && "h-7 w-7",
      size === "xl" && "h-10 w-10",
      className,
    )}
    style={style}
  >
    {children}
  </div>
);
