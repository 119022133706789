export const bytesToMegabytes = (bytes: number) => {
  const megabytes = bytes / (1024 * 1024);

  return megabytes.toFixed(2);
};

export const getFileExtension = (fileName: string): string | null => {
  const parts = fileName.split(".");
  return parts.pop() ?? null;
};

export const getFileExtensionFromUrl = (url: string): string => {
  const urlObject = new URL(url);
  const filePath = urlObject.pathname;
  const fileName = filePath.split("/").pop() ?? "";
  const extension = fileName.split(".").pop()?.toLowerCase() ?? "";

  return extension;
};
