import { Question } from "@phosphor-icons/react";
import { t } from "i18next";

import { PartnerInfo as PartnerInfoComponent } from "@/components";
import { usePartners } from "@/hooks";
import { ContentLayout } from "@/layout";
import { useUserStore } from "@/stores";
import { Circle, Heading, SectionHeader } from "@/ui";

export const PartnerInfo = () => {
  const { user } = useUserStore();
  const { useManagedPartner } = usePartners();
  const { data: partner } = useManagedPartner(user?.id);

  return (
    <ContentLayout className="flex flex-col gap-y-6">
      <SectionHeader
        left={
          <Heading
            left={
              partner?.logo ? (
                <img
                  className="size-9"
                  src={partner.logo}
                  alt={t("partner.partner")}
                />
              ) : (
                <Circle className="bg-white">
                  <Question size={24} className="text-primary-600" />
                </Circle>
              )
            }
            level={2}
            heading={partner?.name}
            className="text-3xl"
          />
        }
      />
      <PartnerInfoComponent partner={partner} />
    </ContentLayout>
  );
};
