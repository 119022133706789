import { useEffect, useState } from "react";

interface UseCountdownProps {
  initialTime: number;
  onCountdownEnd?: () => void;
}

export const useCountdown = ({
  initialTime,
  onCountdownEnd,
}: UseCountdownProps) => {
  const [countdown, setCountdown] = useState(initialTime);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let timer: number;
    if (isActive && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsActive(false);
      if (onCountdownEnd) {
        onCountdownEnd();
      }
    }

    return () => clearInterval(timer);
  }, [countdown, isActive, onCountdownEnd]);

  const resetCountdown = (newTime: number) => {
    setCountdown(newTime);
    setIsActive(true);
  };

  return { countdown, isActive, resetCountdown };
};
