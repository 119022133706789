import type { DisplayColor } from "@/types";

export type BatchDisplayStatus = "Pending" | "Active" | "Expired";

export function mapBatchStatusToDisplayStatus(
  status: string,
): BatchDisplayStatus {
  switch (status) {
    case "pending":
      return "Pending";
    case "active":
      return "Active";
    default:
      return "Expired";
  }
}

export function mapBatchStatusToColor(status: string): DisplayColor {
  switch (status) {
    case "pending":
      return "warning";
    case "active":
      return "success";
    default:
      return "error";
  }
}
