import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import "intl-pluralrules";

import { en } from "./locales";

const resources = {
  en: { translation: en },
};

void i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  debug: true,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
