import React from "react";

import type { DisplayColor } from "@/types";
import { tw } from "@/utils";

interface BadgeProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
  text: string;
  color?: DisplayColor;
  className?: string;
}

const Badge: React.FC<BadgeProps> = ({
  left,
  right,
  text,
  color = "success",
  className,
}) => {
  return (
    <span
      className={tw(
        "flex items-center justify-center rounded-full px-3 py-0.5 text-center text-xs font-medium text-white",
        color === "success" && "bg-success-200 text-success-950",
        color === "error" && "bg-error-200 text-error-950",
        color === "warning" && "bg-warning-200 text-warning-950",
        className,
      )}
    >
      {!!left && <div>{left}</div>}
      {text}
      {!!right && <div>{right}</div>}
    </span>
  );
};

export default Badge;
