import type { ComponentPropsWithoutRef, ForwardedRef, ReactNode } from "react";

import { forwardRef, tw } from "@/utils";
import { IconWrapper } from "../common";
import { Label } from "./Label";
import { Message } from "./Message";

export interface InputProps extends ComponentPropsWithoutRef<"input"> {
  compact?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  error?: string | boolean;
  iconClassName?: string;
  id: string;
  label?: ReactNode;
  left?: ReactNode;
  message?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Input = forwardRef(
  (
    {
      className,
      compact,
      containerClassName,
      error,
      id,
      label,
      left,
      message,
      required,
      value,
      labelClassName,
      ...rest
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <div
        className={tw(
          "relative flex flex-col gap-y-1 p-0.5",
          containerClassName,
        )}
      >
        {!!label && (
          <div className="flex flex-row gap-x-1">
            <Label
              htmlFor={id}
              label={label}
              className={tw(
                "text-lg font-semibold text-neutral-900",
                labelClassName,
              )}
            />
            {!!required && (
              <span className="text-lg font-semibold text-error-500">*</span>
            )}
          </div>
        )}
        <div
          className={tw(
            "flex flex-row items-center",
            !!rest.disabled && "opacity-30",
          )}
        >
          {!!left && (
            <div className="pointer-events-none absolute pl-3">
              <IconWrapper
                size="sm"
                className={tw(
                  value ? "text-neutral-950" : "text-neutral-500",
                  !!error && "text-error-400",
                )}
              >
                {left}
              </IconWrapper>
            </div>
          )}
          <input
            ref={ref}
            type="text"
            id={id}
            {...rest}
            className={tw(
              "block w-full rounded-md border border-neutral-400 p-4 text-sm text-neutral-950 placeholder:text-neutral-400 hover:ring-1 hover:ring-tertiary-600 focus:shadow-input focus:outline-none focus:ring-1 focus:ring-tertiary-600",
              !!error &&
                "border-error-400 text-error-400 focus:border-error-400 focus:ring-error-50",
              !!left && "pl-10",
              !!rest.disabled && "border-neutral-500 bg-neutral-100",
              className,
            )}
          />
        </div>
        {(!!compact || !!message || !!error) && (
          <Message message={message} error={error} />
        )}
      </div>
    );
  },
);
