import { useEffect } from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { useBatches } from "@/hooks";
import { usePagination } from "@/hooks/usePagination";
import { Pagination, Table } from "@/ui";
import { getTableColumns } from "./BatchUsersTableColumns";

interface BatchUsersTableProps {
  batchId: string;
  searchQuery: string;
}

export const BatchUsersTable = ({
  batchId,
  searchQuery,
}: BatchUsersTableProps) => {
  const { pagination, setPageIndex, onPaginationChange } = usePagination();
  const { useBatchUsers } = useBatches();
  const { data: batchUsers, isLoading } = useBatchUsers(
    batchId,
    pagination.pageIndex + 1,
    searchQuery,
  );

  useEffect(() => {
    setPageIndex(0);
  }, [searchQuery]);

  const table = useReactTable({
    data: batchUsers?.data ?? [],
    columns: getTableColumns(),
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: batchUsers?.pagination?.totalPages ?? 1,
    state: {
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: batchUsers?.pagination?.perPage ?? 10,
      },
    },
    onPaginationChange,
  });

  return (
    <div className="pb-6">
      <Table table={table} isLoading={isLoading} />
      <div className="flex justify-end rounded-b-2xl border border-neutral-400">
        <Pagination
          currentPage={pagination.pageIndex + 1}
          totalPages={batchUsers?.pagination?.totalPages ?? 1}
          onPageChange={(page) => setPageIndex(page - 1)}
        />
      </div>
    </div>
  );
};
