import { useEffect, useState } from "react";
import {
  HandsClapping,
  Handshake,
  Lightbulb,
  PencilLine,
  QuestionMark,
  UsersThree,
} from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { coursesQueryKeys, useCourses } from "@/hooks";
import { ContentLayout } from "@/layout";
import { ROUTES } from "@/router";
import { useUserStore } from "@/stores";
import type { Course, Partner } from "@/types";
import type { Tab } from "@/ui";
import {
  Breadcrumb,
  Circle,
  errorToast,
  Heading,
  Loader,
  SectionHeader,
  Tabs,
  Toggle,
  useToastStore,
} from "@/ui";
import { PartnerInfo } from "../Partner";
import { BatchesAndUsers } from "../User";
import { Collaborators } from "./Collaborators";
import { SelfPacedCourseLandingPage } from "./SelfPacedCourseLandingPage";
import { SelfPacedCourseSessions } from "./SelfPacedCourseSessions";

interface SelfPacedCourseProps {
  partner?: Partner;
  course?: Course;
  isLoading: boolean;
}

const PUBLISH_OPTIONS: [string, string] = ["Draft", "Published"];

export const SelfPacedCourse = ({
  partner,
  course,
  isLoading,
}: SelfPacedCourseProps) => {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  const queryClient = useQueryClient();
  const pushToast = useToastStore((state) => state.pushToast);
  const { usePublishCourse } = useCourses();
  const { mutateAsync: updatePublishStatus } = usePublishCourse();
  const [selectedOption, setSelectedOption] = useState(PUBLISH_OPTIONS[0]);

  useEffect(() => {
    if (course?.published === true) {
      setSelectedOption(PUBLISH_OPTIONS[1]);
    }
  }, [course]);

  const invalidate = (optionSelected: string) => {
    void queryClient.invalidateQueries({
      queryKey: coursesQueryKeys.useCourse(course?.id),
      exact: true,
    });
    if (optionSelected === "Published") {
      void pushToast({
        type: "success",
        title: t("course.published"),
        message: t("course.published_successfully_message"),
      });
    } else {
      void pushToast({
        type: "success",
        title: t("course.draft"),
        message: t("course.draft_successfully_message"),
      });
    }
  };

  const handleToggle = async (option: string) => {
    if (option === selectedOption) {
      return;
    }
    setSelectedOption(option);

    try {
      await updatePublishStatus(course?.id);
      invalidate(option);
    } catch (error) {
      errorToast(error);
    }
  };

  const tabs: [Tab, ...Tab[]] = [
    {
      component: <BatchesAndUsers />,
      label: t("course.batches_and_users"),
      roles: ["admin"],
      icon: <UsersThree />,
    },
    {
      component: (
        <SelfPacedCourseLandingPage partnerId={partner?.id} course={course} />
      ),
      label: t("course.self_paces_course_landing_page"),
      roles: ["admin", "partner"],
      icon: <PencilLine />,
    },
    {
      component: (
        <SelfPacedCourseSessions partnerId={partner?.id} course={course} />
      ),
      label: t("course.self_paces_course_sessions"),
      roles: ["admin", "partner"],
      icon: <PencilLine />,
    },
    {
      component: <Collaborators partnerId={partner?.id} course={course} />,
      label: t("course.collaborators"),
      roles: ["admin", "partner"],
      icon: <PencilLine />,
    },
    {
      component: <PartnerInfo partner={partner} />,
      label: t("course.info"),
      roles: ["admin"],
      icon: <Lightbulb />,
    },
  ];

  const availableTabs = tabs.filter((tab) =>
    tab.roles.includes(user?.role ?? "loggedOut"),
  );
  const [currentTab, setCurrentTab] = useState(availableTabs[0]);

  const breadcrumbItems = [
    { label: "Partner", href: ROUTES.partners, icon: <Handshake /> },
    { label: partner?.name ?? "", icon: <HandsClapping /> },
  ];

  return (
    <ContentLayout className="flex flex-col gap-y-6">
      <Breadcrumb
        items={breadcrumbItems}
        current={partner?.name ?? ""}
        onItemClick={(item) => {
          if (item.href) {
            navigate(item.href);
          }
        }}
      />
      <SectionHeader
        right={
          course &&
          user?.role == "admin" && (
            <Toggle
              options={PUBLISH_OPTIONS}
              selectedOption={selectedOption}
              onToggle={handleToggle}
            />
          )
        }
        left={
          <Heading
            left={
              partner?.logo ? (
                <img
                  className="size-9"
                  src={partner.logo}
                  alt={t("partner.partner")}
                />
              ) : (
                <Circle className="bg-white">
                  <QuestionMark size={24} className="text-primary-600" />
                </Circle>
              )
            }
            level={2}
            heading={partner?.name}
            className="text-3xl"
          />
        }
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Tabs
          containerClassName="gap-y-6"
          tabs={availableTabs}
          userRole={user?.role ?? "loggedOut"}
          currentLabel={currentTab?.label ?? ""}
          setCurrentTab={setCurrentTab}
        />
      )}
    </ContentLayout>
  );
};
