import type { AddEditMeditation, Batch, Meditation, User } from "@/types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

export const getMeditations = async (
  page: number,
  searchQuery = "",
): Promise<ServiceResponse<Meditation[]>> => {
  const params: Record<string, string | number> = {
    include: "practiceType,interventionType,teacher,themes,bundles",
    page,
    paginate: "true",
  };

  if (searchQuery) {
    params["filter[search]"] = searchQuery;
  }

  const response = await privateAPI.get<ServiceResponse<Meditation[]>>(
    "/meditations",
    { params },
  );

  return response.data;
};

export const getMeditation = async (
  meditationId?: Meditation["id"],
): Promise<Meditation> => {
  const params: Record<string, string | number> = {
    include: "practiceType,interventionType,teacher,themes,bundles",
  };
  const response = await privateAPI.get<ServiceResponse<Meditation>>(
    `/meditations/${meditationId}`,
    { params },
  );
  return response.data.data;
};

export const getManagedMeditation = async (
  userId: User["id"] | undefined,
): Promise<Meditation> => {
  const response = await privateAPI.get<ServiceResponse<Meditation>>(
    `users/${userId}/meditations/managed`,
  );
  return response.data.data;
};

export const addMeditation = async (
  meditation: AddEditMeditation,
): Promise<Meditation> => {
  const response = await privateAPI.post<ServiceResponse<Meditation>>(
    `/meditations`,
    {
      ...meditation,
    },
  );

  return response.data.data;
};

export const updateMeditation = async ({
  meditationId,
  meditation,
}: {
  meditationId: Meditation["id"];
  meditation: AddEditMeditation;
}): Promise<Meditation> => {
  const response = await privateAPI.put<ServiceResponse<Meditation>>(
    `/meditations/${meditationId}`,
    {
      ...meditation,
    },
  );
  return response.data.data;
};

export const getMeditationBatches = async (
  meditationId: Meditation["id"],
  page: number,
): Promise<ServiceResponse<Batch[]>> => {
  const params: Record<string, string | number> = {
    include: "createdBy",
    page,
  };

  const response = await privateAPI.get<ServiceResponse<Batch[]>>(
    `/meditations/${meditationId}/batches`,
    {
      params,
    },
  );

  return response.data;
};
