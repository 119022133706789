import type { DisplayColor } from "../commonTypes";
import type { Bundle } from "./bundles";
import type { User } from "./user";

export interface UserBundleAccess {
  id?: string;
  approved_on: Date | null;
  created_at: Date | null;
  status: Status;
  bundle: Bundle;
  user: User;
}

export type Status =
  | "access requested"
  | "declined"
  | "paid"
  | "pending payment";

export type DisplayStatus = "Requested" | "Declined" | "Paid" | "Pending";

export function mapBackendStatusToDisplayStatus(status: Status): DisplayStatus {
  switch (status) {
    case "access requested":
      return "Requested";
    case "declined":
      return "Declined";
    case "paid":
      return "Paid";
    case "pending payment":
      return "Pending";
  }
}

export function mapDisplayStatusToColor(status: Status): DisplayColor {
  switch (status) {
    case "access requested":
      return "success";
    case "declined":
      return "error";
    case "paid":
      return "success";
    case "pending payment":
      return "warning";
  }
}
