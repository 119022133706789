import type { CommonTypes } from "@/types";
import { tw } from "@/utils";

export const StickyHeader = ({ className, children }: CommonTypes) => {
  return (
    <header className={tw("sticky top-0 z-50 bg-white shadow-md", className)}>
      {children}
    </header>
  );
};
