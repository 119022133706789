import type { Status, UserBundleAccess } from "@/types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

export const getUserBundleAccesses = async (
  page: number,
  searchQuery = "",
  bundleIds: string[] = [],
  statuses: Status[] = [],
): Promise<ServiceResponse<UserBundleAccess[]>> => {
  const params: Record<string, string | number> = {
    include: "bundle,user",
    page,
  };
  if (bundleIds.length > 0) {
    bundleIds.forEach((bundle, index) => {
      params[`filter[bundle][${index}]`] = bundle;
    });
  }

  if (statuses.length > 0) {
    statuses.forEach((status, index) => {
      params[`filter[status][${index}]`] = status;
    });
  }

  if (searchQuery) {
    params["filter[userSearch]"] = searchQuery;
  }

  const response = await privateAPI.get<ServiceResponse<UserBundleAccess[]>>(
    "/user-bundle-access",
    { params },
  );

  return response.data;
};

export const updateUserBundleAccess = async ({
  userBundleAccessId,
  status,
}: {
  userBundleAccessId: UserBundleAccess["id"];
  status: Status;
}) => {
  await privateAPI.put<void>(`/user-bundle-access/${userBundleAccessId}`, {
    status: status,
  });
};
