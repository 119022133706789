import { CircleNotch } from "@phosphor-icons/react";

export const Loader = () => {
  return (
    <div className="flex items-center justify-center">
      <CircleNotch
        className="animate-spin text-primary-800"
        size={48}
        weight="bold"
      />
    </div>
  );
};
