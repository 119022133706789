import type { IconProps } from "@/types";

export const SuccessIcon = (props: IconProps) => {
  return (
    <svg
      width="218"
      height="221"
      viewBox="0 0 218 221"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx="91.558"
        cy="91.3223"
        rx="91.558"
        ry="91.3223"
        transform="matrix(0.995188 0.0979818 -0.072108 0.997397 31.1533 16.1508)"
        fill="#BEB1F3"
        fillOpacity="0.4"
      />
      <ellipse
        cx="91.3385"
        cy="91.1034"
        rx="91.3385"
        ry="91.1034"
        transform="matrix(0.995188 0.0979818 -0.072108 0.997397 13.9111 19.6096)"
        fill="#BEB1F3"
        fillOpacity="0.4"
      />
      <ellipse
        cx="94.5203"
        cy="94.277"
        rx="94.5203"
        ry="94.277"
        transform="matrix(0.995188 0.0979818 -0.072108 0.997397 19.4385 0.423126)"
        fill="#BEB1F3"
        fillOpacity="0.4"
      />
      <path
        d="M134.685 50.2483L106.982 131.189L186.238 101.563L134.685 50.2483Z"
        fill="#3B2F6A"
      />
      <path
        d="M106.747 126.341L131.082 54.5115C131.289 53.8978 131.648 53.3464 132.125 52.9082C132.601 52.4701 133.181 52.1595 133.81 52.0052C134.439 51.8509 135.097 51.8579 135.722 52.0256C136.348 52.1933 136.921 52.5163 137.388 52.9645L183.738 97.3044C184.205 97.7519 184.552 98.3101 184.746 98.9272C184.94 99.5444 184.976 100.201 184.849 100.835C184.722 101.469 184.438 102.062 184.021 102.557C183.605 103.052 183.07 103.434 182.467 103.668L111.786 131.162C111.104 131.428 110.359 131.494 109.64 131.352C108.922 131.21 108.258 130.865 107.729 130.359C107.199 129.852 106.826 129.205 106.652 128.493C106.478 127.781 106.511 127.034 106.747 126.341Z"
        stroke="#EAE7F3"
        strokeWidth="7.89831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M168.214 59.0505C168.214 59.0505 167.952 47.2059 179.797 46.9434C191.641 46.6809 191.379 34.8364 191.379 34.8364"
        stroke="#EAE7F3"
        strokeWidth="7.89831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.758 31.6754L156.02 43.52"
        stroke="#EAE7F3"
        strokeWidth="7.89831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M192.341 78.2669L200.412 85.9883"
        stroke="#EAE7F3"
        strokeWidth="7.89831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M191.992 62.4742L203.749 58.2635"
        stroke="#EAE7F3"
        strokeWidth="7.89831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.171 74.8837L162.419 111.473"
        stroke="#EAE7F3"
        strokeWidth="7.89831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.571 121.527L115.272 101.152"
        stroke="#EAE7F3"
        strokeWidth="7.89831"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.6942 121.135C59.6942 121.135 79.4236 99.1519 88.7987 89.8591C105.865 97.7953 93.9095 118.339 93.9095 118.339L127.041 130.538L104.925 180.459L24.382 157.097L36.9731 113.924L59.6942 121.135Z"
        fill="#4A3B84"
      />
      <path
        d="M40.3348 114.325L60.7014 120.166L48.0462 164.293L27.6797 158.452C26.7795 158.194 26.0187 157.589 25.5646 156.77C25.1106 155.951 25.0006 154.985 25.2588 154.085L35.967 116.746C36.2251 115.846 36.8304 115.085 37.6495 114.631C38.4686 114.177 39.4346 114.067 40.3348 114.325Z"
        stroke="#F1EFF8"
        strokeWidth="7.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.7008 120.166L87.4076 91.0889C91.0087 92.1216 94.052 94.5426 95.868 97.8191C97.6841 101.096 98.1242 104.959 97.0914 108.56L94.171 118.744L121.326 126.531C122.289 126.808 123.182 127.286 123.946 127.934C124.71 128.582 125.328 129.386 125.757 130.29C126.187 131.195 126.419 132.182 126.439 133.183C126.458 134.185 126.264 135.179 125.87 136.1L109.096 175.373C108.419 176.954 107.189 178.233 105.635 178.972C104.082 179.71 102.313 179.856 100.659 179.382L48.0457 164.293"
        stroke="#F1EFF8"
        strokeWidth="7.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
