import { zodResolver } from "@hookform/resolvers/zod";
import { At, Lightbulb, MapPin, Spinner } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import { partnersQueryKeys, useMedia, usePartners } from "@/hooks";
import type { EditPartner, Partner } from "@/types";
import { editPartnerSchema } from "@/types";
import { Button, ControlledDropzone, errorToast, Input, TextArea } from "@/ui";
import {
  bytesToMegabytes,
  getFileExtension,
  MAX_UPLOAD_SIZE_FOR,
} from "@/utils";

export const EditPartnerForm = ({
  partner,
  onClose,
}: {
  partner: Partner | undefined;
  onClose: () => void;
}) => {
  const { useUpdatePartner } = usePartners();
  const { useUpload } = useMedia();
  const { mutateAsync: updatePartner, isPending: isPendingAddPartnerMutation } =
    useUpdatePartner();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    control,
  } = useForm<EditPartner>({
    resolver: zodResolver(editPartnerSchema),
    mode: "all",
    defaultValues: {
      name: partner?.name,
      description: partner?.description,
      domain: partner?.domain,
      country: partner?.country ?? "",
      contact: partner?.contact,
    },
  });

  const queryClient = useQueryClient();

  const invalidateAndClose = () => {
    void queryClient.invalidateQueries({
      queryKey: partnersQueryKeys.usePartners(),
      exact: false,
    });
    onClose();
  };

  const { upload, isUploading, fileHasUploaded } = useUpload();

  const onSubmit: SubmitHandler<EditPartner> = async (partnerData) => {
    const { logo, ...partnerToUpdate } = partnerData;
    if (logo) {
      partnerToUpdate.extension = getFileExtension(logo.name);
    }
    try {
      const updatedPartner = await updatePartner({
        partnerId: partner?.id,
        partner: partnerToUpdate,
      });
      if (logo) {
        void upload(logo, `/partners/${updatedPartner.id}/logo/upload-url`);
      }
      invalidateAndClose();
    } catch (error) {
      errorToast(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-[540px] flex-col gap-y-8"
    >
      <div className="flex flex-col gap-y-8">
        <TextArea
          id="description"
          label={t("partner.description")}
          placeholder={t("partner.description")}
          left={<Lightbulb size={20} />}
          {...register("description")}
          error={errors.description?.message}
          required
        />

        <div className="flex flex-col gap-y-2">
          <Input
            id="domain"
            label={t("partner.domain")}
            placeholder={t("partner.omf_domain")}
            left={<At size={20} />}
            {...register("domain")}
            error={errors.domain?.message}
            disabled
          />
          <div className="rounded-2xl border border-tertiary-400 bg-tertiary-50 p-3.5">
            <p className="text-primary-950">
              {t("partner.email_domain_connected_to_OMF")}
            </p>
          </div>
        </div>

        <Input
          id="country"
          label={t("partner.country")}
          placeholder={t("partner.country")}
          left={<MapPin size={20} />}
          {...register("country")}
          error={errors.country?.message}
          required
        />
        <ControlledDropzone
          name="logo"
          control={control}
          loadedLabel={t("general.logo_uploaded")}
          label={t("partner.logo_here")}
          placeholder={`${t("general.maximum_size")}: ${bytesToMegabytes(
            MAX_UPLOAD_SIZE_FOR.LOGO,
          )} ${t("general.mb")}`}
          error={errors.logo?.message}
          state={
            fileHasUploaded ? "filled" : isUploading ? "loading" : "initial"
          }
          size="sm"
          fileUrl={partner?.logo ?? undefined}
        />
      </div>

      <div className="flex justify-center gap-2">
        <Button
          onClick={() => onClose()}
          variant="outlined"
          className="flex-grow"
        >
          {t("general.cancel")}
        </Button>

        <Button type="submit" className="flex-grow" disabled={!isValid}>
          {isPendingAddPartnerMutation ? (
            <Spinner className="h-5 w-5" />
          ) : (
            t("general.save_changes")
          )}
        </Button>
      </div>
    </form>
  );
};
