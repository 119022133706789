import { useMutation, useQuery } from "@tanstack/react-query";

import {
  addPartner,
  deleteCourseModuleVideo,
  getBatches,
  getManagedPartner,
  getPartner,
  getPartners,
  getSelfPacedCourse,
  getSelfPacedCourseModules,
  updateCourseModule,
  updatePartner,
} from "@/api";
import type { Partner, User } from "@/types";
import { useDebouncedQuery } from "./useDebouncedQuery";

export const partnersQueryKeys = {
  usePartner: (partnerId?: Partner["id"]) => [
    "partners",
    "getPartner",
    partnerId,
  ],
  useManagedPartner: () => ["partners", "getPartners"],
  usePartners: () => ["partners", "getPartners"],
  usePartnersPage: (page: number, searchQuery: string) => [
    "partners",
    "getPartners",
    page,
    searchQuery,
  ],
  useBatchesPage: (page: number) => ["batches", "getBatch", page],
  useSelfPacedCourse: (partnerId?: Partner["id"]) => [
    "courses",
    "getSelfPacedCourse",
    partnerId,
  ],
  useSelfPacedCourseModules: (partnerId?: Partner["id"]) => [
    "courses",
    "getSelfPacedCourseModules",
    partnerId,
  ],
};

export const usePartners = () => {
  const useAllPartners = (page: number, searchQuery: string) =>
    useDebouncedQuery({
      queryFn: () => getPartners(page, searchQuery),
      queryKey: partnersQueryKeys.usePartnersPage(page, searchQuery),
    });

  const usePartner = (partnerId: Partner["id"] | undefined) =>
    useQuery({
      queryFn: () => getPartner(partnerId),
      queryKey: partnersQueryKeys.usePartner(partnerId),
      enabled: !!partnerId,
    });

  const useManagedPartner = (userId: User["id"] | undefined) =>
    useQuery({
      queryFn: () => getManagedPartner(userId),
      queryKey: partnersQueryKeys.useManagedPartner(),
      enabled: !!userId,
    });

  const useAddPartner = () =>
    useMutation({
      mutationFn: addPartner,
    });

  const useUpdatePartner = () => useMutation({ mutationFn: updatePartner });

  const useAllBatches = (partnerId: Partner["id"], page: number) =>
    useDebouncedQuery({
      queryFn: () => getBatches(partnerId, page),
      queryKey: partnersQueryKeys.useBatchesPage(page),
    });

  const useSelfPacedCourse = (partnerId: Partner["id"], options = {}) =>
    useQuery({
      queryFn: () => getSelfPacedCourse(partnerId),
      queryKey: partnersQueryKeys.useSelfPacedCourse(partnerId),
      enabled: !!partnerId,
      ...options,
    });

  const useSelfPacedCourseModules = (partnerId: Partner["id"], options = {}) =>
    useQuery({
      queryFn: () => getSelfPacedCourseModules(partnerId),
      queryKey: partnersQueryKeys.useSelfPacedCourseModules(partnerId),
      enabled: !!partnerId,
      ...options,
    });

  const useUpdateCourseModule = () =>
    useMutation({ mutationFn: updateCourseModule });

  const useDeleteCourseModuleVideo = () =>
    useMutation({ mutationFn: deleteCourseModuleVideo });

  return {
    useAllPartners,
    useAddPartner,
    usePartner,
    useManagedPartner,
    useUpdatePartner,
    useAllBatches,
    useSelfPacedCourse,
    useSelfPacedCourseModules,
    useUpdateCourseModule,
    useDeleteCourseModuleVideo,
  };
};
