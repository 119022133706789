import type { ComponentPropsWithoutRef, FC, ReactNode } from "react";

import { tw } from "@/utils";

const SIZE = {
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
} as const;

type Size = (typeof SIZE)[keyof typeof SIZE];

export interface LabelProps extends ComponentPropsWithoutRef<"label"> {
  label: ReactNode;
  containerClassName?: string;
  size?: Size;
}

export const Label: FC<LabelProps> = ({
  label,
  containerClassName,
  className,
  size = "md",
  ...props
}) => (
  <div className={tw("flex", containerClassName)}>
    {typeof label !== "string" ? (
      label
    ) : (
      <label
        {...props}
        className={tw(
          "m-0 font-normal leading-6 text-neutral-900",
          size === SIZE.SMALL && "text-sm leading-none",
          size === SIZE.LARGE && "px-6 py-4 font-semibold leading-[24px]",
          className,
        )}
      >
        {label}
      </label>
    )}
  </div>
);
