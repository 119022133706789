import type { AxiosError } from "axios";

import { ROUTES } from "@/router";
import { useUserStore } from "@/stores";
import type { ErrorResponse } from "../api.types";

export const errorResponse = async (error: AxiosError<ErrorResponse>) => {
  if (error?.response?.status === 401) {
    useUserStore.getState().clearUser();
    window.location.href = ROUTES.login;
  }
  if ([404, 405].includes(error?.response?.status ?? 404)) {
    window.location.href = ROUTES.notFound;
  }
  if ([500, 502, 503, 504].includes(error?.response?.status ?? 500)) {
    window.location.href = ROUTES.serverError;
  }
  return Promise.reject(error.response?.data);
};
