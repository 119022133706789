import type { AddEditCourse, Collaborator, Course } from "@/types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

export const getCourse = async (courseId?: Course["id"]): Promise<Course> => {
  const params: Record<string, string | number> = {
    include: "practiceType,interventionType,teacher,themes,bundles",
  };
  const response = await privateAPI.get<ServiceResponse<Course>>(
    `/courses/${courseId}`,
    { params },
  );
  return response.data.data;
};

export const updateCourse = async ({
  courseId,
  course,
}: {
  courseId: Course["id"];
  course: AddEditCourse;
}): Promise<Course> => {
  const response = await privateAPI.put<ServiceResponse<Course>>(
    `/courses/${courseId}`,
    {
      ...course,
    },
  );
  return response.data.data;
};

export const addCollaboratorToCourse = async ({
  courseId,
  collaboratorId,
}: {
  courseId: Course["id"];
  collaboratorId: Collaborator["id"];
}): Promise<Course> => {
  const response = await privateAPI.post<ServiceResponse<Course>>(
    `/courses/${courseId}/collaborators/${collaboratorId}`,
    {},
  );
  return response.data.data;
};

export const removeCollaboratorFromCourse = async ({
  collaboratorId,
}: {
  collaboratorId: Collaborator["id"];
}): Promise<Course> => {
  const response = await privateAPI.delete<ServiceResponse<Course>>(
    `/collaborators/${collaboratorId}`,
    {},
  );
  return response.data.data;
};

export const getCourseModules = async (
  courseId?: Course["id"],
): Promise<Course> => {
  const params: Record<string, string | number> = {
    include: "sessions.modules",
  };
  const response = await privateAPI.get<ServiceResponse<Course>>(
    `/courses/${courseId}`,
    { params },
  );
  return response.data.data;
};

export const publishCourse = async (courseId?: Course["id"]) => {
  await privateAPI.post(`/courses/${courseId}/toggle-published`);
};
