import { t } from "i18next";

import { Logo } from "@/components";

export const ErrorBoundaryFallback = () => {
  return (
    <main className="bg-slate-800 flex h-screen flex-col items-center justify-center">
      <div className="mb-20">
        <Logo />
      </div>
      <h2 className="mb-10 text-2xl">{t("error.general")}</h2>
    </main>
  );
};
