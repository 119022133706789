import { Route, Routes, useLocation } from "react-router-dom";
import type { Location } from "react-router-dom";

import { SidebarLayout } from "@/layout";
import * as Screen from "@/screens";
import { ModalRouter } from "./ModalRouter";
import { ProtectedRoute } from "./ProtectedRoute";
import { ROUTES } from "./routes";

export const Router = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };

  return (
    <>
      {/* PUBLIC ONLY ROUTES */}
      <Routes location={previousLocation ?? location}>
        <Route element={<Screen.Login />} path={ROUTES.login} />
        <Route element={<Screen.NotFound />} path={"/*"} />
        <Route
          element={<Screen.PasswordForgot />}
          path={ROUTES.passwordForgot}
        />
        <Route
          element={<Screen.ResetEmailSent />}
          path={ROUTES.passwordResetEmailSent}
        />
        <Route element={<Screen.PasswordReset />} path={ROUTES.passwordReset} />
        <Route
          element={<Screen.PasswordCreate />}
          path={ROUTES.passwordCreate}
        />
        <Route
          element={<Screen.PasswordResetSuccess />}
          path={ROUTES.passwordResetSuccess}
        />
        <Route
          element={<Screen.PasswordCreatedSuccess />}
          path={ROUTES.passwordCreatedSuccess}
        />
        <Route
          element={<Screen.VerificationSuccess />}
          path={ROUTES.verificationSuccess}
        />
        <Route path={ROUTES.notFound} element={<Screen.NotFound />} />
        <Route path={ROUTES.serverError} element={<Screen.ServerError />} />
        <Route path={ROUTES.privacyPolicy} element={<Screen.PrivacyPolicy />} />
        <Route path={ROUTES.termsOfUse} element={<Screen.TermsOfUse />} />

        {/* PRIVATE ONLY ROUTES */}

        <Route element={<ProtectedRoute expected={["admin", "partner"]} />}>
          <Route element={<SidebarLayout />}>
            <Route element={<Screen.MyProfile />} path={ROUTES.myProfile} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute expected="partner" />}>
          <Route element={<SidebarLayout />}>
            <Route element={<Screen.PartnerInfo />} path={ROUTES.home} />
            <Route element={<Screen.PartnerInfo />} path={ROUTES.partnerInfo} />
            <Route element={<Screen.PartnerInfo />} path={ROUTES.base} />
            <Route
              element={<Screen.SelfPacedCourse />}
              path={ROUTES.selfPacedCourse}
            />
          </Route>
        </Route>

        <Route element={<ProtectedRoute expected="admin" />}>
          <Route element={<SidebarLayout />}>
            <Route element={<Screen.Partners />} path={ROUTES.home} />
            <Route element={<Screen.Users />} path={ROUTES.users} />
            <Route element={<Screen.Partners />} path={ROUTES.partners} />
            <Route
              element={<Screen.CoursesAndMaterials />}
              path={ROUTES.coursesAndMaterials}
            />
            <Route
              element={<Screen.PartnerDetail />}
              path={ROUTES.partnerDetail}
            />
            <Route element={<Screen.BatchDetail />} path={ROUTES.batchDetail} />
            <Route element={<Screen.Meditations />} path={ROUTES.meditations} />
            <Route
              element={<Screen.AddMeditation />}
              path={ROUTES.addMeditation}
            />
            <Route
              element={<Screen.EditMeditation />}
              path={ROUTES.editMeditation}
            />
            <Route element={<Screen.Codes />} path={ROUTES.codes} />
            <Route element={<Screen.Partners />} path={ROUTES.base} />
          </Route>
        </Route>
      </Routes>

      {/* MODALS ROUTES */}
      <Routes>
        <Route
          path="*"
          element={<ModalRouter showModal={!!previousLocation} />}
        />
      </Routes>
    </>
  );
};
