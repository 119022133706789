export function arraysHaveSameElements<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) return false;

  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  if (set1.size !== set2.size) return false;

  for (const item of set1) {
    if (!set2.has(item)) return false;
  }

  return true;
}

export function formatArrayToString(
  array: string[],
  separator = " | ",
): string {
  if (array.length === 0) {
    return "";
  } else if (array.length === 1) {
    return array[0]!;
  } else {
    return Object.values(array).join(separator);
  }
}
