import { useEffect } from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { useUsers } from "@/hooks";
import { usePagination } from "@/hooks/usePagination";
import { useNavigateModal } from "@/router/useNavigateModal";
import { Pagination, Table } from "@/ui";
import { getTableColumns } from "./UsersTableColumns";

interface UsersTableProps {
  searchQuery: string;
  lockedQuery?: string | number;
  setUsersCount: (totalUsers: number) => void;
}

export const UsersTable = ({
  searchQuery,
  lockedQuery = "",
  setUsersCount,
}: UsersTableProps) => {
  const navigateModal = useNavigateModal();
  const { pagination, setPageIndex, onPaginationChange } = usePagination();
  const { useAllUsers } = useUsers();
  const { data: users, isLoading } = useAllUsers(
    pagination.pageIndex + 1,
    searchQuery,
    lockedQuery,
  );

  useEffect(() => {
    if (users?.pagination?.total) {
      setUsersCount(users.pagination.total);
    }
  }, [users?.pagination?.total]);

  useEffect(() => {
    setPageIndex(0);
  }, [searchQuery]);

  const table = useReactTable({
    data: users?.data ?? [],
    columns: getTableColumns(navigateModal),
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: users?.pagination?.totalPages ?? 1,
    state: {
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: users?.pagination?.perPage ?? 10,
      },
    },
    onPaginationChange,
  });

  return (
    <div>
      <Table table={table} isLoading={isLoading} />
      <div className="flex justify-end rounded-b-2xl border border-neutral-400">
        <Pagination
          currentPage={pagination.pageIndex + 1}
          totalPages={users?.pagination?.totalPages ?? 1}
          onPageChange={(page) => setPageIndex(page - 1)}
        />
      </div>
    </div>
  );
};
